import React from "react";
import { connect } from "react-redux";
import ManagerTableBodyRow from "../ManagerTableBodyRow/ManagerTableBodyRow";

const ManagerTableBody = ({ managers }) => {
  return (
    <div>
      {!!managers.length &&
        managers.map((manager, index) => (
          <ManagerTableBodyRow manager={manager} key={index} />
        ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    managers: state.managers,
  };
};
export default connect(mapStateToProps)(ManagerTableBody);
