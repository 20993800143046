import axios from "axios";
import { toast } from "react-toastify";
import { ROUTES } from "./constant";
import { store } from "../store";
import { logOut } from "../routines";

export const emailValidation = (email) => {
  if (
    email.match(
      /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const regularCharValidation = (name) => {
  return name.match(/^[A-Za-z]+$/);
};

export const convertToTitleCase = (inputString) => {
  return inputString
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getParseDataFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};
export const setDataInLocalStorage = (key, data) => {
  if (typeof data === "string") {
    localStorage.setItem(key, data);
  } else {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

export const handleLogOut = () => {
  localStorage.clear()
  window.location.replace(ROUTES.LOG_IN);
  store.dispatch(logOut.trigger());
}
export const sendRequest = async ({
  url,
  method = "GET",
  body,
  params = {},
  headers = {},
}) => {
  // const currentDate = new Date();
  // const time_zone = (currentDate.getTimezoneOffset() / 60).toString();

  try {
    const response = await axios({
      url: process.env.REACT_APP_BACKEND_URL + url,
      method,
      data: body,
      params: {
        ...params,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      handleLogOut()
    }
    throw ({
      message: error?.response?.data?.message || 'Something went wrong, Please try again!',
      status: error?.response?.status || null,
    });
  }
};

export const copyContent = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied", { autoClose: 10 });
  } catch (err) {
    toast.error("Failed to copy: ");
    console.log("Copy Error", err);
  }
};

export const validateFlowData = ({ nodes, edges }) => {
  if (
    !edges.length || nodes.length < 2
  ) {
    toast.error("Please add at least 2 connected nodes!");
    return false;
  }

  let edgesSourceSet = new Map();
  for (let edge of edges) {
    if (!edgesSourceSet.has(edge.source)) {
      edgesSourceSet.set(edge.source, { valid: !!edge.data.transition });
    } else {
      if (!edgesSourceSet.get(edge.source).valid || !edge.data.transition) {
        toast.error(
          'Please add a text using the "+" icon in case a node has more than 1 child'
        );
        return false;
      }
    }
  }
  const invalidContextNode = nodes.find((node) => {
    return (
      !node.data.stateName || !node.data.type || !node.data.message.trim(" ")
    );
  });
  if (
    !!invalidContextNode ||
    !nodes.length
    // || reactFlowInstance.toObject().nodes.length !== flowContext.flowData.nodes.length
  ) {
    toast.error("Please fill in all information on each node!!!!");
    return false;
  }
  const invalidTextNode = nodes.find((node) => {
    return node.data.stateName === "Email" && node.data.type !== "Email";
  });
  if (invalidTextNode) {
    toast.error(
      'The node name "Email" is only valid for nodes of type email!'
    );
    return false;
  }

  const edgesObj = {};

  for (const edge of edges) {
    const invalidEdge = edges.find(
      (ed) =>
        ed.source === edge.source &&
        ed.target !== edge.target &&
        ((ed.transition && !edge.transition) ||
          (edge.transition && !ed.transition))
    );

    if (invalidEdge) {
      toast.error(
        "Make sure all options are provided for a state, where options are given"
      );
      return false;
    }
  }

  for (const node of nodes) {
    const duplicateNodes = nodes.find(
      (nde) =>
        nde.id !== node.id && nde.data.stateName === node.data.stateName
    );
    if (duplicateNodes) {
      toast.error(
        `Each node should have unique name! Name: "${node.data.stateName}" is used more than once`
      );
      return false;
    } else if (edgesObj[node.id]) {
      delete edgesObj[node.id];
    } else {
      edgesObj[node.id] = node;
    }
  }

  const duplicateTransitions = edges.find((edge) => {
    return edge.data.isDuplicate === true;
  });

  if (!!duplicateTransitions) {
    toast.error(
      'Please modify the duplicate event "' +
      duplicateTransitions.data.transition +
      '"'
    );
    return false;
  }

  //To check disconnected node
  const adjacencyList = {};
  for (const edge of edges) {
    if (!adjacencyList[edge.source]) {
      adjacencyList[edge.source] = [];
    }
    adjacencyList[edge.source].push(edge.target);
  }
  const visited = {};
  const stack = [nodes[0].id];
  while (stack.length > 0) {
    const node = stack.pop();
    visited[node] = true;
    if (adjacencyList[node]) {
      for (const neighbor of adjacencyList[node]) {
        if (!visited[neighbor]) {
          stack.push(neighbor);
        }
      }
    }
  }
  for (const node of nodes) {
    if (!visited[node.id]) {
      toast.error(`Can't have disconnected nodes!`);
      return false;
    }
  }

  return true;
};

export const getFromDate = (date, value) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (value === "DDMMM") {
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];

    return `${day}${month}`;
  } else if (value === "month") {
    return months[date.getUTCMonth()];
  } else if (value === "Month") {
    return Months[date.getUTCMonth()];
  } else if (value === "year") {
    return date.getFullYear();
  } else if (value === "DDMMMYYYY") {
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  } else {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
};
