import React from "react";
import "./styles.scss";

const ManagerTableHeaderRow = () => {
  return (
    <div component="div" className="tableHeaderRow">
      <div component="div" className="email">
        Email
      </div>
      <div component="div" className="firstName">
        First name
      </div>
      <div component="div" className="lastName">
        Last name
      </div>
      {/* <div component="div" className='roles'>
        Assign roles
      </div> */}
      <span component="span" className="status">
        Status
      </span>
      <span component="span" className="settings"></span>
    </div>
  );
};

export default ManagerTableHeaderRow;
