import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/constant";
import styles from "./Thanks.module.scss";
import { API_URLS } from "../../utils/constant";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

export const Thanks = () => {
  const [token, setToken] = useState();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setToken(params.get("token"));
    console.log(params.get("token"));
  }, [token]);

  useEffect(() => {
    if (token) {
      axios
        .get(`${API_URLS.VERIFY_EMAIL}/${token}`)
        .then((res) => {
          console.log(res);
          toast.success(res.data);
          // setVerificationPage(true);
        })
        .catch((err) => {
          console.log("errr", err);
          toast.error(err?.response?.data);
        });
    }
  }, [token]);

  return (
    <div className={styles.thanks}>
      <h2>Thank You</h2>
      You have successfully activated the user{" "}
      <Link to={ROUTES.LOG_IN} replace>
        Login
      </Link>
    </div>
  );
};
