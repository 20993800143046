import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/largeLogoIcon.png";
import "./animate.css";
import "./namari-color.css";
import "./font-awesome.min.css";
import "./style.css";
import bot from "../../Assets/images/bot.png";
import { ROUTES } from "../../utils/constant";

export default function Home() {
  return (
    <div className="homepage">
      {/* <div id="preloader">
                <div id="status" className="la-ball-triangle-path">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div> */}

      {/* <div className="page-border" data-wow-duration="0.7s" data-wow-delay="0.2s">
                <div className="top-border wow fadeInDown animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}></div>
                <div className="right-border wow fadeInRight animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}></div>
                <div className="bottom-border wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}></div>
                <div className="left-border wow fadeInLeft animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}></div>
            </div> */}

      <div id="wrapper">
        <header
          id="banner"
          className="scrollto clearfix"
          data-enllax-ratio=".5"
        >
          <div id="header" className="nav-collapse">
            <div className="row clearfix">
              <div className="col-1">
                <div id="logo">
                  <img src={logo} id="banner-logo" alt="Botifier" />
                  <p>Botifier</p>
                  <img src={logo} id="navigation-logo" alt="Landing Page" />
                </div>
                <aside>
                  <ul className="social-icons">
                    <li>
                      <a
                        target="_blank"
                        title="Facebook"
                        href="https://www.facebook.com/tftus"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook fa-1x"></i>
                        <span>Facebook</span>
                      </a>
                    </li>
                    {/* <li>
                                            <a target="_blank" title="Google+" href="http://google.com/tftus" rel="noreferrer">
                                                <i className="fa fa-google-plus fa-1x"></i><span>Google+</span>
                                            </a>
                                        </li> */}
                  </ul>
                </aside>
                <nav id="nav-main">
                  <ul>
                    <li>
                      <a href="#about">About</a>
                    </li>
                    <li>
                      <a href="#services">Services</a>
                    </li>
                    {/* <li>
                                            <a href="#clients">Clients</a>
                                        </li> */}
                    {/* <li>
                                            <a href="#pricing">Pricing</a>
                                        </li> */}
                  </ul>
                </nav>

                <div id="nav-trigger">
                  <span></span>
                </div>
                <nav id="nav-mobile"></nav>
              </div>
            </div>
          </div>

          <div id="banner-content" className="row clearfix">
            <div className="col-38">
              <div className="section-heading">
                <h1>Great customer Relationship Starts Here</h1>
                <h2>
                  Discover the future of customer interaction with our
                  innovative chatbot solutions. Boost efficiency, engagement,
                  and satisfaction today
                </h2>
              </div>
              <Link to={ROUTES.DASHBOARD}>
                <p className="button">TRY TODAY</p>
              </Link>
            </div>
          </div>
        </header>

        <main id="content">
          <section id="about" className="introduction scrollto">
            <div className="row clearfix">
              <div className="col-3">
                <div className="section-heading">
                  <h3>FEATURE</h3>
                  <h2 className="section-title">
                    How We Help You To make you bot
                  </h2>
                  <p className="section-subtitle">
                    We empower you to create your customized chatbot
                    effortlessly. Our platform offers intuitive tools and expert
                    guidance to streamline bot development, ensuring exceptional
                    user experiences and results
                  </p>
                </div>
              </div>

              <div className="col-2-3">
                <div
                  className="col-2 icon-block icon-top wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="icon">
                    <i className="fa fa-desktop fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                    <h4>Bot &amp; Management</h4>
                    <p>
                      Displays all your running or saved bot flows. Option to
                      edit , test, create and delete the bots.
                    </p>
                  </div>
                </div>

                <div
                  className="col-2 icon-block icon-top wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div className="icon">
                    <i className="fa fa-bolt fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                    <h4>Bot Publishing</h4>
                    <p>
                      Publish your bot and share with end users to gather the
                      required information. Web bot and Facebook integrations
                      available.
                    </p>
                  </div>
                </div>

                <div
                  className="col-2 icon-block icon-top wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div className="icon">
                    <i className="fa fa-tablet fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                    <h4>Bot Analytics</h4>
                    <p>
                      See the number of live users & completed chats. Page also
                      displays information gathered state wise.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <aside id="gallery" className="row text-center scrollto clearfix" data-featherlight-gallery
                 data-featherlight-filter="a">

                <a href="images/gallery-images/gallery-image-1.jpg" data-featherlight="image" className="col-3 wow fadeIn"
                   data-wow-delay="0.1s"><img src="images/gallery-images/gallery-image-1.jpg" alt="Landing Page"/></a>
                <a href="images/gallery-images/gallery-image-2.jpg" data-featherlight="image" className="col-3 wow fadeIn"
                   data-wow-delay="0.3s"><img src="images/gallery-images/gallery-image-2.jpg" alt="Landing Page"/></a>
                <a href="images/gallery-images/gallery-image-3.jpg" data-featherlight="image" className="col-3 wow fadeIn"
                   data-wow-delay="0.5s"><img src="images/gallery-images/gallery-image-3.jpg" alt="Landing Page"/></a>
                <a href="images/gallery-images/gallery-image-4.jpg" data-featherlight="image" className="col-3 wow fadeIn"
                   data-wow-delay="1.1s"><img src="images/gallery-images/gallery-image-4.jpg" alt="Landing Page"/></a>
                <a href="images/gallery-images/gallery-image-5.jpg" data-featherlight="image" className="col-3 wow fadeIn"
                   data-wow-delay="0.9s"><img src="images/gallery-images/gallery-image-5.jpg" alt="Landing Page"/></a>
                <a href="images/gallery-images/gallery-image-6.jpg" data-featherlight="image" className="col-3 wow fadeIn"
                   data-wow-delay="0.7s"><img src="images/gallery-images/gallery-image-6.jpg" alt="Landing Page"/></a>

        </aside> */}

          <div id="services" className="scrollto clearfix">
            <div className="row no-padding-bottom clearfix">
              <div className="col-3">
                <div className="section-heading">
                  <h3>STATES</h3>
                  <h2 className="section-title">Managing States</h2>
                  <p className="section-subtitle">
                    Botifier harnesses the power of dynamic state machines for
                    seamless chat management. With XState, we navigate finite
                    states through actions and events, elevating user
                    interactions to new heights.
                  </p>
                </div>
                <p>
                  For Botifier we are using dynamic machines to manage the chat
                  states. State machines contain finite set of states that can
                  transition to each other deterministically through actions and
                  events. XState is a library for creating, interpreting, and
                  executing finite state machines and statecharts, as well as
                  managing invocations of those machines as actors.
                </p>
              </div>
              <div className="col-3">
                <img src={bot} alt="Bot" />
              </div>
            </div>
          </div>
          <section id="about" className="introduction scrollto">
            <div className="row clearfix">
              <div className="col-3">
                <div className="section-heading">
                  <h3>BOTS</h3>
                  <h2 className="section-title">How you can manage your bot</h2>
                  <p className="section-subtitle">
                    Effortlessly manage your bot with our intuitive tools. Edit,
                    test, create, and delete bot flows with ease, ensuring
                    smooth and efficient interactions with your audience
                  </p>
                </div>
              </div>

              <div className="col-2-3">
                <div
                  className="col-2 icon-block icon-top wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="icon">
                    <i className="fa fa-hashtag fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                    <h4>Integration With Other Social Platforms</h4>
                    <p>
                      Chatbots can be deployed on popular platforms such as
                      WhatsApp, Telegram, and Instagram.
                    </p>
                  </div>
                </div>

                <div
                  className="col-2 icon-block icon-top wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div className="icon">
                    <i className="fa fa-link fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                    <h4>Integration With Third Party Apps & APIs</h4>
                    <p>
                      Option to integrate with third-party apps like Calendly,
                      Slack, ChatGPT, CRMs and connecting to databases
                    </p>
                  </div>
                </div>

                <div
                  className="col-2 icon-block icon-top wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div className="icon">
                    <i className="fa fa-language fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                    <h4>Multi-Language Support</h4>
                    <p>
                      Option for multi-language bot - German , Spanish, Hindi
                      etc.
                    </p>
                  </div>
                </div>
                <div
                  className="col-2 icon-block icon-top wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div className="icon">
                    <i className="fa fa-wifi fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                    <h4>Webhook Support For Applications</h4>
                    <p>
                      Option to integrate with other applications through
                      webhook services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <aside id="testimonials" className="scrollto text-center" data-enllax-ratio=".2">

            <div className="row clearfix">

                <div className="section-heading">
                    <h3>FEEDBACK</h3>
                    <h2 className="section-title">What our customers are saying</h2>
                </div>

                <blockquote className="col-3 testimonial classic">
                    <img src="images/user-images/user-1.jpg" alt="User"/>
                    <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et dolore magna aliqua</q>
                    <footer>John Doe - Happy Customer</footer>
                </blockquote>
                <blockquote className="col-3 testimonial classic">
                    <img src="images/user-images/user-2.jpg" alt="User"/>
                    <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et dolore magna aliqua</q>
                    <footer>Roslyn Doe - Happy Customer</footer>
                </blockquote>
                

                <blockquote className="col-3 testimonial classic">
                    <img src="images/user-images/user-3.jpg" alt="User"/>
                    <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et dolore magna aliqua</q>
                    <footer>Thomas Doe - Happy Customer</footer>
                </blockquote>

            </div>

        </aside> */}

          {/* <section id="clients" className="scrollto clearfix">
            <div className="row clearfix">

                <div className="col-3">

                    <div className="section-heading">
                        <h3>TRUST</h3>
                        <h2 className="section-title">Companies who use our services</h2>
                        <p className="section-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam!</p>
                    </div>

                </div>

                <div className="col-2-3">

                    <a href="#" className="col-3">
                        <img src="images/company-images/company-logo1.png" alt="Company"/>
                        <div className="client-overlay"><span>Tree</span></div>
                    </a>
                    <a href="#" className="col-3">
                        <img src="images/company-images/company-logo2.png" alt="Company"/>
                        <div className="client-overlay"><span>Fingerprint</span></div>
                    </a>
                    <a href="#" className="col-3">
                        <img src="images/company-images/company-logo3.png" alt="Company"/>
                        <div className="client-overlay"><span>The Man</span></div>
                    </a>
                    <a href="#" className="col-3">
                        <img src="images/company-images/company-logo4.png" alt="Company"/>
                        <div className="client-overlay"><span>Mustache</span></div>
                    </a>
                    <a href="#" className="col-3">
                        <img src="images/company-images/company-logo5.png" alt="Company"/>
                        <div className="client-overlay"><span>Goat</span></div>
                    </a>
                    <a href="#" className="col-3">
                        <img src="images/company-images/company-logo6.png" alt="Company"/>
                        <div className="client-overlay"><span>Justice</span></div>
                    </a>
                    <a href="#" className="col-3">
                        <img src="images/company-images/company-logo7.png" alt="Company"/>
                        <div className="client-overlay"><span>Ball</span></div>
                    </a>
                    <a href="#" className="col-3">
                        <img src="images/company-images/company-logo8.png" alt="Company"/>
                        <div className="client-overlay"><span>Cold</span></div>
                    </a>

                    <a href="#" className="col-3">
                        <img src="images/company-images/company-logo9.png" alt="Company"/>
                        <div className="client-overlay"><span>Cold</span></div>
                    </a>

                </div>

            </div>
        </section> */}

          {/* <section id="pricing" className="secondary-color text-center scrollto clearfix ">
                        <div className="row clearfix">
                            <div className="section-heading">
                                <h3>YOUR CHOICE</h3>
                                <h2 className="section-title">We have the right package for you</h2>
                            </div>
                            <div className="pricing-block col-3 wow fadeInUp" data-wow-delay="0.4s">
                                <div className="pricing-block-content">
                                    <h3>Personal</h3>
                                    <p className="pricing-sub">The standard version</p>
                                    <div className="pricing">
                                        <div className="price"><span>$</span>19</div>
                                        <p>Unlock advanced bot features with our Standard Plan, elevating user engagement and streamlining automated interactions</p>
                                    </div>
                                    <ul>
                                        <li>5 Bots</li>
                                        <li>2 Intregation</li>
                                        <li>Tutorials</li>
                                        <li>Forum Support</li>
                                    </ul>
                                    <p className="button">BUY TODAY</p>
                                </div>
                            </div>

                            <div className="pricing-block featured col-3 wow fadeInUp" data-wow-delay="0.6s">
                                <div className="pricing-block-content">
                                    <h3>Student</h3>
                                    <p className="pricing-sub">Most popular choice</p>
                                    <div className="pricing">
                                        <div className="price"><span>$</span>29</div>
                                        <p>Elevate user engagement with advanced bot features through our Recomanded Plan</p>
                                    </div>
                                    <ul>
                                        <li>15 Bots</li>
                                        <li>5 Intregation</li>
                                        <li>Tutorials with Files</li>
                                        <li>Forum Support</li>
                                    </ul>
                                    <p className="button">BUY TODAY</p>
                                </div>
                            </div>

                            <div className="pricing-block col-3 wow fadeInUp" data-wow-delay="0.8s">
                                <div className="pricing-block-content">
                                    <h3>Business</h3>
                                    <p className="pricing-sub">For the whole team</p>
                                    <div className="pricing">
                                        <div className="price"><span>$</span>49</div>
                                        <p>Empower your organization with premium bot capabilities, driving growth and exceptional user engagement</p>
                                    </div>
                                    <ul>
                                        <li>Unlimited Bots</li>
                                        <li>Unlimited Intregation</li>
                                        <li>Chat Support</li>
                                        <li>Lifetime free updates</li>
                                    </ul>
                                    <p className="button">BUY TODAY</p>
                                </div>
                            </div>

                        </div>
                    </section> */}
        </main>
        <footer id="landing-footer" className="clearfix">
          <div className="row clearfix">
            <p id="copyright" className="col-2">
              All rights reserved{" "}
              <a href="https://www.tftus.com">Think Future Technology</a>
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}
