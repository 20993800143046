import React from "react";
import "./styles.scss";
import { Card, TextField } from "@mui/material";
import ManagerTableHeaderRow from "../ManagerTableHeaderRow/ManagerTableHeaderRow";
import ManagerTableBody from "../ManagerTableBody/ManagerTableBody";

const ManagerTable = () => {
  return (
    <div className="managerTable">
      <div className="managerTableTop">
        <h3>List of Users</h3>
        <TextField
          select
          size="small"
          sx={{
            width: "140px",
            color: "#ECECEC",
            paddingX: 0,
          }}
        />
      </div>
      <Card sx={{ marginTop: "1rem" }}>
        <div className="managerTableContainer">
          <ManagerTableHeaderRow />
          <ManagerTableBody />
          {/* <ManagerTablePagination /> */}
        </div>
      </Card>
    </div>
  );
};

export default ManagerTable;
