import { useLocation } from "react-router-dom";
import SetDetailsForm from "../../components/SetDetaisForm";
import { AVATAR_URLS } from "../../utils/constant";
import { Typography } from "@mui/material";

const SetManagerDetails = () => {
  const location = useLocation();
  const email = new URLSearchParams(location.search).get("email");
  const code = new URLSearchParams(location.search).get("code");
  const logos = [
    {
      image: AVATAR_URLS.DEFAULT_AVATAR_ONE,
      isUpload: false,
      selected: false,
    },
    {
      image: AVATAR_URLS.DEFAULT_AVATAR_TWO,
      isUpload: false,
      selected: false,
    },
    {
      image: AVATAR_URLS.DEFAULT_AVATAR_THREE,
      isUpload: false,
      selected: false,
    },
    {
      image: AVATAR_URLS.DEFAULT_AVATAR_FOUR,
      isUpload: false,
      selected: false,
    },
  ];
  return (
    <Typography
      component={"div"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <Typography
        component={"div"}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "700px",
          height: "fit-content",
        }}
      >
        <SetDetailsForm email={email} code={code} logos={logos} />
      </Typography>
    </Typography>
  );
};

export default SetManagerDetails;
