import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./style.module.scss";
import { DialogModal } from "../Modal/DialogModal";
import { AVATAR_URLS, characterLength, ROUTES } from "../../utils/constant";
import { Apis } from "../../api";
import BotIcon from "../../pages/Images/bot_image.png";
import Button from "@mui/material/Button";

const logos = [
    {
        image: AVATAR_URLS.DEFAULT_AVATAR_ONE,
        isUpload: false,
        selected: false,
    },
    {
        image: AVATAR_URLS.DEFAULT_AVATAR_TWO,
        isUpload: false,
        selected: false,
    },
    {
        image: AVATAR_URLS.DEFAULT_AVATAR_THREE,
        isUpload: false,
        selected: false,
    },
    {
        image: AVATAR_URLS.DEFAULT_AVATAR_FOUR,
        isUpload: false,
        selected: false,
    },
];

export const BotInfoModal = ({
    onCancel,
    smdName,
    smdDescription,
    avatar,
    isEdit,
    onSave,
}) => {
    const plusIcon = "/icons-plus.svg";
    const fileInputRef = useRef(null);
    const [avatars, setAvatars] = useState([]);
    const [selectedLogo, setSelectedLogo] = useState(avatar || null);
    const [name, setName] = useState(smdName || "");
    const [description, setDescription] = useState(smdDescription || "");
    const [uploadedLogo, setUplodedLogo] = useState(null);

    useEffect(() => {
        if (!!avatar) {
            setSelectedLogo(avatar);
            if (logos.find((logo) => logo.image === avatar)) {
                setAvatars(
                    logos.map((logo) => {
                        return logo.image === selectedLogo
                            ? { ...logo, selected: true }
                            : logo;
                    })
                );
            } else {
                setAvatars(logos);
                setUplodedLogo({
                    image: avatar,
                    selected: true,
                });
            }
        } else {
            setSelectedLogo(logos[0].image);
            const initialAvatar = logos[0];
            setAvatars([{ ...initialAvatar, selected: true }, ...logos.slice(1)]);
        }
    }, []);

    const handleClickOnUploadedImage = () => {
        setSelectedLogo(uploadedLogo.image);
        setUplodedLogo((prev) => ({
            ...prev,
            selected: true,
        }));
        setAvatars(logos);
    };

    const selectAvatarHandler = (index) => {
        setSelectedLogo(avatars[index].image);
        setAvatars([
            ...logos.slice(0, index),
            { ...logos[index], selected: true },
            ...logos.slice(index + 1),
        ]);
        if (uploadedLogo?.selected) {
            setUplodedLogo((prev) => ({ ...prev, selected: false }));
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ["image/png", "image/jpeg"];
            if (!allowedTypes.includes(file.type)) {
                toast.error("Only PNG and JPEG files are allowed.");
                return;
            }
            if (file.size > 1048576) {
                toast.error("File size should be less than or equal to 1 MB.");
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setUplodedLogo({
                    image: reader.result,
                    selected: true,
                });
                setSelectedLogo(reader.result);
                setAvatars((prevState) => {
                    return prevState.map((avatar) => ({
                        ...avatar,
                        selected: false,
                    }));
                });
            };
        }
    };

    return (
        <div className={styles.initContainer}>
            <div className={styles.headerContent}>Enter Basic details</div>
            <div className={styles.avatarContainer}>
                <div className={styles.selectedImg}>
                    <img className={styles.SelectedAvatar} src={selectedLogo} />
                </div>
                <div className={styles.avatarsOptions}>
                    {avatars.map((avatar, index) => {
                        return (
                            <img
                                key={index}
                                className={`${styles.avatarButton} ${avatar.selected ? styles.selected : ""
                                    }`}
                                src={avatar.image}
                                alt="Avatar"
                                onClick={() => selectAvatarHandler(index)}
                            />
                        );
                    })}
                    {!!uploadedLogo && (
                        <img
                            key={avatars.length}
                            className={`${styles.avatarButton} ${uploadedLogo.selected ? styles.selected : ""
                                }`}
                            src={uploadedLogo.image}
                            alt="Avatar"
                            onClick={handleClickOnUploadedImage}
                        />
                    )}
                    <>
                        <input
                            type="file"
                            onChange={handleFileUpload}
                            style={{ display: "none" }}
                            ref={fileInputRef}
                        />
                        <img
                            className={styles.avatarButton}
                            onClick={() => fileInputRef.current.click()}
                            src={plusIcon}
                            alt="Upload"
                        />
                    </>
                </div>
            </div>
            <div className={styles.inputField}>
                <div className={styles.lable}>Bot name</div>
                <input
                    className={styles.inputSection}
                    placeholder="Type here"
                    value={name}
                    name="name"
                    maxLength={characterLength.botNameLength}
                    onChange={(event) => {
                        if (event.target.length > characterLength.botNameLength) {
                            return toast.warning(
                                `Bot name length should not exceed ${characterLength.botNameLength}`
                            );
                        }
                        setName(event.target.value);
                    }}
                    onBlur={() => {
                        setName(name.trim());
                    }}
                />
            </div>
            <div className={styles.descriptionField}>
                <div className={styles.lable}>Bot Description</div>
                <textarea
                    className={styles.textArea}
                    placeholder="Type here"
                    value={description}
                    name="description"
                    maxLength={characterLength.botDescriptionLength}
                    onChange={(event) => setDescription(event.target.value)}
                    onBlur={() => {
                        setDescription(description.trim());
                    }}
                />
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: "none",
                        width: "fit-content",
                        pl: 4,
                        pr: 4,
                        borderRadius: 1,
                        background: "#ECECEC",
                        color: "#3E3E3E",
                        "&:hover": {
                            background: "#b4b5b5",
                        },
                    }}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: "none",
                        width: "fit-content",
                        pl: 4,
                        pr: 4,
                        borderRadius: 1,
                        background: "#089BAE",
                        "&:hover": {
                            background: "#148695",
                        },
                    }}
                    onClick={() => {
                        onSave({ smdName: name, description, avatars: selectedLogo })
                    }}
                >
                    Proceed
                </Button>
            </div>
        </div>
    );
};
