import { handleActions } from "redux-actions";
import { getUser } from "../routines";

const defaultState = null;

export const userDetailsReducer = handleActions(
  {
    [getUser.SUCCESS]: (state, { payload }) => {
      return !!payload ? { ...payload } : defaultState;
    },
  },
  defaultState
);
