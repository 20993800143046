import { handleActions } from "redux-actions";
import { getSmdfromPrompt } from "../routines";

const defaultState = [];

export const botPromptSmdReducer = handleActions(
  {
    [getSmdfromPrompt.SUCCESS]: (state, { payload }) => {
      return [...payload];
    },
  },
  defaultState
);
