import { useContext, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import AddNodeInfo from "./AddNodeInfo";
import styles from "./CustomNode.module.scss";
import { FlowContext } from "../../store/flow-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { BootstrapTooltip } from "../UI/Tooltip";
import { guards } from "../../utils/constant";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";

function CustomNode(customNodeData) {

  const { activatedAddon } = useSelector((state) => state?.addonsList || []);
  const { data } = customNodeData;
  const [isConnectable, setIsConnectable] = useState(true);
  const [editing, setEditing] = useState(false);
  const [stateName, setStateName] = useState(data.stateName ?? "");
  const [nodeInfo, setNodeInfo] = useState({
    type: data.type ?? "Text",
    min: "",
    max: "",
    message: data.message ?? "",
    defaultMessage: data.defaultMessage,
    isInitialNode: data.isInitialNode,
  });

  const flowContext = useContext(FlowContext);

  const getPreviousNode = (selectedNodeId, elements) => {
    const edge = elements.find((element) => element.target === selectedNodeId);

    if (edge) {
      return flowContext.flowData.nodes.find(
        (element) => element.id === edge.source
      );
    }

    return null;
  };

  const editingCancelHandler = () => {
    setEditing(false);
    flowContext.setIsKeyboardDisabled(false);
  };

  const editNodeHandler = () => {
    setEditing(true);
    flowContext.setIsKeyboardDisabled(true);
  };

  useEffect(() => {
    const nextNode = flowContext.flowData.edges.find(
      (elelment) => elelment.source === data.id
    );
    const previousNode = getPreviousNode(
      customNodeData.id,
      flowContext.flowData.edges
    );

    setNodeInfo((nodeInfo) => {
      if (data.apiDetails) {
        nodeInfo.apiDetails = data.apiDetails;
      }

      if (
        (nodeInfo.type !== guards.isText || nodeInfo.defaultMessage) &&
        !!nextNode
      ) {
        setIsConnectable(false);
      } else {
        setIsConnectable(true);
      }

      return { ...nodeInfo, isConnected: previousNode ? true : false };
    });
  }, [flowContext]);

  const stateNameChangeHandler = (event) => {
    const value = event.target.value;
    const hasOnlySpace = !/\S/.test(value);
    if (hasOnlySpace && value) return;
    setStateName(value);
    flowContext.addOrUpdateNode({
      ...customNodeData,
      data: {
        id: data.id,
        stateName: value !== "" ? event.target.value : undefined,
        message: nodeInfo.message,
        isInitialNode: nodeInfo.isInitialNode,
        defaultMessage: nodeInfo.defaultMessage,
        type: nodeInfo.type,
      },
    });
  };

  const handleNodeDelete = () => {
    if (customNodeData.data.isInitialNode) {
      return toast.error("Can't delete the first node");
    }
    const previousNode = getPreviousNode(
      customNodeData.id,
      flowContext.flowData.edges
    );
    if (previousNode?.data?.defaultMessage) {
      return toast.error(
        `Please remove the external API from ${previousNode.data.stateName}`
      );
    }

    flowContext.deleteNodeById(customNodeData);
  };
  const saveNodeInfoHandler = (info) => {
    setNodeInfo(info);
    flowContext.addOrUpdateNode({
      ...customNodeData,
      data: {
        id: data.id,
        stateName,
        message: info.message,
        type: info.type,
        isInitialNode: info.isInitialNode,
        defaultMessage: info.defaultMessage,
      },
    });
    setEditing(false);
    flowContext.setIsKeyboardDisabled(false);
  };

  return (
    <div className={styles.customNode}>
      {editing && (
        <AddNodeInfo
          activatedAddon={activatedAddon}
          nodeInfo={nodeInfo}
          cancelNodeInfo={editingCancelHandler}
          saveNodeInfo={saveNodeInfoHandler}
          nodeId={data.id}
        />
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div
        className={`${styles.nodeContainer} ${!!nodeInfo.message &&
          nodeInfo.message.trim(" ").length > 0 &&
          !!stateName &&
          (nodeInfo.isConnected || nodeInfo.isInitialNode)
          ? styles.nodeContainerValid
          : styles.nodeContainerInvalid
          }`}
      >
        {/* <div className={styles.nodeHeader}> */}
        <input
          className={styles.stateName}
          placeholder="Add a Title"
          value={stateName}
          onChange={stateNameChangeHandler}
          maxLength={11}
        />
        <BootstrapTooltip title="Add Details" arrow placement="top">
          <FontAwesomeIcon
            icon={faGear}
            className={styles.nodeOptionsIcon}
            onClick={editNodeHandler}
          />
        </BootstrapTooltip>
        {!customNodeData.data.isInitialNode && (
          <BootstrapTooltip title="Delete Node" arrow placement="top">
            {/* <FontAwesomeIcon
            icon={faTrash}
            className={styles.nodeOptionsIcon}
            onClick={handleNodeDelete}
          /> */}
            <DeleteOutlinedIcon
              sx={{
                color:
                  !!nodeInfo.message && !!stateName ? "#089BAE" : "#9b0c0cc9",
                fontSize: 20,
                cursor: "pointer",
              }}
              onClick={handleNodeDelete}
            />
          </BootstrapTooltip>
        )}
      </div>
      <BootstrapTooltip
        title={isConnectable ? "New Node" : "Cannot Create New Node"}
        arrow
        placement="right"
      >
        <Handle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />
      </BootstrapTooltip>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    addons: state.addonsList
  }
}

export default connect(mapStateToProps)(CustomNode);
