import styles from "./Conversations.module.scss";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BackToTopButton } from "../BackToTop/BackToTopButton";

export const Conversations = (props) => {
  const [hideChat, setHideChat] = useState(false);
  const chatContainerRef = useRef(null);

  const getMainContainerClasses = () => {
    const classes = [styles.testFlow];
    if (!props.isTest) {
      classes.push(styles.publishedBot);
    }
    if (props.plugin) {
      classes.push(styles.pluginBot);
      if (hideChat) {
        classes.push(styles.headerOnly);
      }
    }
    return classes.join(" ");
  };

  const checkMsgType = (url) => {
    const urlRegex =
      /^(https?:\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    if (!urlRegex.test(url)) {
      return "text";
    } else {
      if (/.(jpg|jpeg|png|gif|bmp|svg)$/i.test(url)) {
        return "image";
      } else if (
        /.(pdf|doc|docx|xls|xlsx|ppt|pptx|zip|rar|tar|gz)$/i.test(url)
      ) {
        return "attachment";
      } else {
        return "url";
      }
    }
  };

  return (
    <div className={getMainContainerClasses()}>
      <header className={styles.header}>
        <div className={styles.headerAvatar}>
          <img
            src={props.avatar}
            alt=""
            width={30}
            height={30}
            className={styles.headerMessageIcon}
          />
          <h5>{props.runBotTitle}</h5>
        </div>
        <FontAwesomeIcon
          icon={faXmark}
          size="lg"
          className={styles.closeTestFlow}
          onClick={() => {
            props.handleCloseTestFlow();
          }}
        />
      </header>
      <div
        id={"chatContainer"}
        ref={chatContainerRef}
        className={`${styles.chatCtr} ${hideChat ? styles.hideChat : ""}`}
      >
        {props.chats.map((message, messageIndex) => {
          if ("botReply" in message) {
            return (
              <div key={messageIndex} className={styles.genericMsg}>
                <img
                  src={props.avatar}
                  alt=""
                  width={30}
                  height={30}
                  className={styles.messageIcon}
                />
                <div className={styles.botFirstMessageIcon}>
                  <p className={` ${styles.botChatMsg}`}>{message.botReply}</p>
                  <div className={styles.eventContainer}>
                    {message.buttons && (
                      <div className={styles.chatMsgEvents}>
                        {message.buttons.map((option, optionIndex) => {
                          return (
                            <button
                              className={styles.conversationsButton}
                              key={optionIndex}
                              disabled={true}
                            >
                              {option}
                            </button>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          } else {
            const type = checkMsgType(message.userReply);
            if (type === "image") {
              return (
                <div key={messageIndex} className={styles.userMsg}>
                  <div className={styles.userChatAttachment}>
                    <img src={message.userReply} />
                  </div>
                  <img
                    src="/user-img.png"
                    alt=""
                    width={30}
                    height={30}
                    className={styles.messageIcon}
                  />
                </div>
              );
            } else if (type == "attachment") {
              return (
                <div key={messageIndex} className={styles.userMsg}>
                  <p className={` ${styles.userChatMsg}`}>
                    <a target="_blank" href={message.userReply}>
                      {message.userReply}
                    </a>
                  </p>
                  <img
                    src="/user-img.png"
                    alt=""
                    width={30}
                    height={30}
                    className={styles.messageIcon}
                  />
                </div>
              );
            } else if (type == "text") {
              return (
                <div key={messageIndex} className={styles.userMsg}>
                  <p className={` ${styles.userChatMsg}`}>
                    {message.userReply}
                  </p>
                  <img
                    src="/user-img.png"
                    alt=""
                    width={30}
                    height={30}
                    className={styles.messageIcon}
                  />
                </div>
              );
            } else if (type == "url") {
              return (
                <div key={messageIndex} className={styles.userMsg}>
                  <p className={` ${styles.userChatMsg}`}>
                    <a target="_blank" href={message.userReply}>
                      {message.userReply}
                    </a>
                  </p>
                  <img
                    src="/user-img.png"
                    alt=""
                    width={30}
                    height={30}
                    className={styles.messageIcon}
                  />
                </div>
              );
            }
          }
        })}
        <div>
          <BackToTopButton chatContainerRef={chatContainerRef} />
        </div>
      </div>
    </div>
  );
};
