// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Card_card__EdDrk {\n  background: white;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);\n  border-radius: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/Card.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,yCAAA;EACA,mBAAA;AACF","sourcesContent":[".card {\n  background: white;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Card_card__EdDrk"
};
export default ___CSS_LOADER_EXPORT___;
