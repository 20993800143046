import { Routes, Route, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import {
  addonRoutes,
  analyticsRoutes,
  authRoutes,
  botFlowCreateRoute,
  chatsRoutes,
  commonRoutesWithoutAccess,
  dashboardRoutes,
  generateApiKeysRoutes,
  managerRoutes,
  profileRoutes,
  publicRoutes,
  usersRoutes,
} from "./routes";
import { Authenticated } from "./components/Authenticated/Authenticated";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./fonts.scss";
import AppNavigation from "./components/AppNavigation/AppNavigation";
import { useEffect, useState } from "react";
import { ROUTES, USER_ROLE } from "./utils/constant";
import NotFound from "./components/NotFound/NotFound";
import AdminRoutes from "./routes/AdminRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import Home from "./pages/Home/Home";
import { connect, useDispatch } from "react-redux";
import {
  getActivatedApis,
  getAddons,
  getAPIKeys,
  getBots,
  getBotTemplates,
  getManagers,
  getUser,
} from "./routines";

function App({ user, permissions }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!localStorage.getItem("authToken")) {
      dispatch(getUser.trigger());
    }
  }, []);
  useEffect(() => {
    if (!!user) {
      dispatch(getBots.trigger());
      dispatch(getAddons.trigger());
      dispatch(getActivatedApis.trigger());
      dispatch(getAPIKeys.trigger());
      dispatch(getBotTemplates.trigger());
      if (user?.role === USER_ROLE.ADMIN) {
        dispatch(getManagers.trigger());
      }
    }
  }, [user]);

  return (
    <div className="App">
      <ToastContainer position="top-right" newestOnTop autoClose={2000} />
      <AppNavigation>
        <>
          <Link path={ROUTES.LOG_IN} relative="/" id="2" />
          <Routes>
            <Route path={ROUTES.ROOT} element={<Home />} />
            <Route path={ROUTES.AUTH} element={<AuthRoutes />}>
              {authRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route element={<Authenticated />}>
              <Route path={ROUTES.ADMIN} element={<AdminRoutes />}>
                {commonRoutesWithoutAccess.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
                {/* {(user?.role === USER_ROLE.ADMIN || user?.access?.dashboard?.read) && dashboardRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))} */}
                {(user?.role === USER_ROLE.ADMIN || user?.access?.users?.read) && usersRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
                {(user?.role === USER_ROLE.ADMIN || user?.access?.addon?.read) && addonRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
                {(user?.role === USER_ROLE.ADMIN || user?.access?.analytics?.read) && analyticsRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
                {(user?.role === USER_ROLE.ADMIN || user?.access?.chat?.read) && chatsRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
                {(user?.role === USER_ROLE.ADMIN || user?.access?.dashboard?.add) && botFlowCreateRoute.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
                {user?.role === USER_ROLE.ADMIN && managerRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
                {profileRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
                {generateApiKeysRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Route>
            </Route>
            <Route path={ROUTES.PUBLIC} element={<AuthRoutes />}>
              {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      </AppNavigation>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(App);
