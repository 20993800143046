import { Outlet } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Sidebar from "../pages/Outlets/Sidebar/Sidebar";
import { connect } from "react-redux";
import { useEffect, useState } from "react";

function AdminRoutes({ user }) {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (!!user) {
      setIsLoading(false);
    }
  }, [user,])
  return (
    <>{isLoading ? <Typography component={'div'}
      sx={{
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        height: '100vh',
        width: '100%',
      }}><CircularProgress className="loader" /></Typography> : <>
      <Sidebar />
      <Typography
        component="main"
        sx={{
          flexGrow: 1,
          pl: 2,
          pr: 2,
          pt: 12,
          minHeight: "100vh",
          overflowY: "hidden",
          overflowX: "hidden",
          background: "transparent",
        }}
      >
        <Outlet />
      </Typography>
    </>}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}
export default connect(mapStateToProps)(AdminRoutes);
