import { Button, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../utils/theme";
import { DeleteDialogBox } from "../DeleteDialogBox/DeleteDialogBox";

const AddonDetailsActivationPanel = ({
  addonData,
  handleDeactivateAllConfirmation,
  handleOpenDetailForm,
  isConfigure,
  deactivateAllAddon,
  isDeactivateAllConfirmation,
}) => {
  return (
    <div>
      <Typography
        component="div"
        sx={{
          mb: 5,
          pb: 3,
          borderBottom: "1px solid ",
          borderColor: theme.palette.primary.main,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              width: "45%",
            }}
          >
            {addonData.smd.length ? (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    font: "normal normal 600 14px/19px 'Segoe UI'",
                    color: "#FFFFFF",
                    background: "#CC0000",
                    borderRadius: "5px",
                    py: "9px",
                    ":hover": {
                      backgroundColor: "#920505",
                    },
                  }}
                  onClick={handleDeactivateAllConfirmation}
                >
                  Deactivate
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    font: "normal normal 600 14px/19px 'Segoe UI'",
                    color: "#FFFFFF",
                    background: "#089BAE",
                    borderRadius: "5px",
                    py: "9px",
                    "&:hover": {
                      background: "#148695",
                    },
                  }}
                  onClick={handleOpenDetailForm}
                  disabled={!isConfigure}
                >
                  {isConfigure ? "Configure" : "No More Bot Left"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    font: "normal normal 600 14px/19px 'Segoe UI'",
                    color: "#FFFFFF",
                    background: "#089BAE",
                    borderRadius: "5px",
                    "&:hover": {
                      background: "#148695",
                    },
                  }}
                  onClick={handleOpenDetailForm}
                >
                  Activate
                </Button>
              </>
            )}
          </Typography>
        </Typography>
      </Typography>
      <DeleteDialogBox
        open={isDeactivateAllConfirmation}
        onClose={handleDeactivateAllConfirmation}
        onDelete={deactivateAllAddon}
        message={
          <span>
            Are you sure, <br /> do you want to deactivate all ?
          </span>
        }
      />
    </div>
  );
};

export default AddonDetailsActivationPanel;
