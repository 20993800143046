import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "./UsersList.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControlLabel, Switch, TableRow } from "@mui/material";
import { getAllChats, getChatById } from "../../api";
import { ROUTES } from "../../utils/constant";
import { Conversations } from "../Conversations/Conversations";
import { NO_TRANSITION_EVENT_NAME } from "../../utils/jsonUtils";
import { socketEvents } from "../Flow/TestFlow";
import { Slide, Dialog, DialogContent } from "@mui/material";
import { ChatWindow } from "../ChatWindow/ChatWindow";
import TableCell from "@mui/material/TableCell";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" {...props} />;
});

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#121858",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const getMuiTheme = () =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            top: "400px",
            height: "60%",
            overflow: "scroll",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            marginRight: "20px",
            marginLeft: "-7px",
            fontSize: "1rem",
            marginTop: "-4px",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            marginRight: "12px",
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            verticalAlign: "top",
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#089BAE",
            color: "white",
            fontSize: "30px",
            position: "sticky",
            top: "0",
            padding: "8px 16px",
          },
        },
      },
    },
  });

export const UsersList = ({
  conversation,
  fetchSmdChats,
  rowCount,
  isCompleted,
  setIsCompleted,
}) => {
  const [smdStates, setSmdStates] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [userChats, setUserChats] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [botName, setBotName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [botChats, setBotChats] = useState([]);
  const [IsTestClicked, setIsTestClicked] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const CustomCell = ({ content }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleContent = () => {
      setExpanded(!expanded);
    };

    return (
      <TableCell
        style={{ borderBottom: "none", paddingLeft: "0" }}
        className={`${styles.genericCellStyle} ${
          expanded ? styles.expandedCellStyle : styles.closedCellStyle
        }`}
      >
        {content ? (
          content.length > 45 ? (
            <div className={styles.cellExpandContainer}>
              {expanded ? content : `${content.substring(0, 34)}...`}
              <span onClick={toggleContent} className={styles.cellExpandBtn}>
                {expanded ? "Show Less" : "Show More..."}
              </span>
            </div>
          ) : (
            content
          )
        ) : (
          ""
        )}
      </TableCell>
    );
  };

  const handleIsCompleted = (event) => {
    fetchSmdChats(event.target.checked);
    setIsCompleted(event.target.checked);
    setCurrentPage(0);
  };

  const getUserChats = async () => {
    const filteredChats = conversation.map((chat) => {
      return chat.data;
    });
    setUserChats(filteredChats);
  };

  useEffect(() => {
    getUserChats();
  }, [isCompleted, conversation]);

  useEffect(() => {
    try {
      if (userChats.length > 0) {
        const nodeStates = userChats[0];
        let columns = [];
        for (let key in nodeStates) {
          columns.push({
            name: key,
            label: key,
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value) => <CustomCell content={value} />,
            },
          });
        }
        setSmdStates([...columns]);
      } else {
        setSmdStates([]);
      }
    } catch (error) {
      console.log("Error occurred while finding states for the bot");
    }
  }, [userChats]);

  const handleCloseTestFlow = () => {
    setIsTestClicked(false);
  };

  const CustomToolbar = ({ value, onChange }) => {
    return (
      <>
        <FormControlLabel
          control={<Switch checked={value} onChange={onChange} />}
          label="Completed Interactions"
          labelPlacement="start"
        />
      </>
    );
  };

  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
    fetchSmdChats(isCompleted, currentPage);
  };

  const options = {
    filterType: "multiselect",
    onChangePage: handlePageChange,
    customToolbar: () => {
      return <CustomToolbar onChange={handleIsCompleted} value={isCompleted} />;
    },
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    selectableRows: "none",
    count: rowCount,
    print: false,
    download: false,
    searchPlaceholder: "Search",
    fixedHeader: true,
    sort: true,
    filter: false,
    responsive: "standard",
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"User Interactions"}
            data={userChats}
            columns={smdStates}
            options={options}
          />
        </ThemeProvider>
        <Dialog
          classes={{
            root: styles.testContainer,
          }}
          PaperProps={{
            style: {
              background: "transparent",
              border: "2px solid #303783",
              margin: "0px",
              borderRadius: "10px",
            },
          }}
          BackdropProps={{ style: { background: "transparent" } }}
          keepMounted
          open={IsTestClicked}
          TransitionComponent={Transition}
          sx={{
            height: 800,
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent style={{ overflow: "hidden", padding: "0" }}>
            {IsTestClicked && (
              <Conversations
                isTest
                runBotTitle={botName}
                avatar={avatar}
                chats={botChats}
                handleCloseTestFlow={handleCloseTestFlow}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
