export const endpoints = {
  bot: {
    test: "/initiate_instance",
    submit: "/save_smd",
    initiate: "/initiate_instance_by_SMD",
    login: "/login",
    signup: "/register",
    proceed: "/smd/check-name",
  },
};
