import React, { createRef, useEffect, useState } from "react";
import { useScreenshot, createFileName } from "use-react-screenshot";
import { ADDON_URLS, chatPlatforms } from "../../../utils/constant";
import { Button, Container, Typography } from "@mui/material";
import { theme } from "../../../utils/theme";
import { AddonDetailsForm } from "../../AddonDetailsForm/AddonDetailsForm";
import { BasicSelect } from "../../Select/Select";
import { QRCode } from "react-qrcode-logo";
import { platform_images } from "../../../utils/constant";
import largeLogoIcon from "./../../../Assets/largeLogoIcon.png";

const QrCodeAddonDetails = ({
  inputList,
  smdList,
  selectedSmd,
  commonOnSelectSmd,
  inputs,
  setInputs,
  isBotSelect,
  openDetailForm,
  addonData,
  commonHandleOpenDetailForm,
  isValid,
  setIsValid,
  commonValidate,
  errorMessage,
  setErrorMessage,
  allSmd,
}) => {
  const [platformLink, setPlatformLink] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [smdActiveAddons, setSmdActiveAddons] = useState([]);
  const elementRef = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  });

  const validate = () => {
    if (commonValidate()) {
      if (smdActiveAddons.length > 0 && !selectedPlatform) {
        setErrorMessage("Please select a platform");
        setIsValid(false);
        return;
      }
      downloadQrCode();
    }
  };

  const getSmdActiveAddons = async () => {
    const currentSmd = allSmd.find((smd) => smd.id == selectedSmd);
    const activePlatforms = [];
    if (currentSmd) {
      Object.keys(currentSmd.platform).forEach((key) => {
        const platform = currentSmd.platform[key];
        if (
          platform.hasOwnProperty("isActive") &&
          platform.isActive !== false
        ) {
          platform.id = key;
          platform.name = chatPlatforms[key];
          activePlatforms.push(platform);
        }
      });
      activePlatforms.push({ id: "web", name: "Web" });
      setSmdActiveAddons(activePlatforms);
      setSelectedPlatform("web");
    }
  };

  const handleOpenDetailForm = (apiIndex) => {
    commonHandleOpenDetailForm();
    if (openDetailForm) {
      setSelectedPlatform("");
      setPlatformLink("");
    }
  };

  useEffect(() => {
    setSmdActiveAddons([]);
    setSelectedPlatform("");
    getSmdActiveAddons();
  }, [selectedSmd]);

  const getImageName = () => {
    const currentSmdName = smdList.find((smd) => smd.id === selectedSmd).name;
    const imgName = `${currentSmdName}_${selectedPlatform}_qrCode`;
    return imgName;
  };

  const downloadImg = (
    image,
    { name = getImageName(), extension = "jpg" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
    handleOpenDetailForm();
  };

  const downloadQrCode = () =>
    takeScreenShot(elementRef.current).then(downloadImg);

  const updatePlatformLink = () => {
    if (selectedPlatform && smdActiveAddons.length > 0) {
      const platform = smdActiveAddons.find(
        (platform) => platform.id === selectedPlatform
      );
      switch (platform.id) {
        case "wp":
          setPlatformLink(`${ADDON_URLS.wp}${platform.phone_number}`);
          break;
        case "tg":
          setPlatformLink(`${ADDON_URLS.tg}${platform.bot_username}`);
          break;
        case "fb":
          setPlatformLink(`${ADDON_URLS.fb}${platform.page_id}`);
          break;
        default:
          setPlatformLink(`${ADDON_URLS.web}${selectedSmd}`);
      }
    }
  };

  const onPlatformSelect = async (value) => {
    setSelectedPlatform(value);
  };

  useEffect(() => {
    updatePlatformLink();
  }, [selectedPlatform, smdActiveAddons]);

  return (
    <div>
      <Typography
        component="div"
        sx={{
          mb: 5,
          pb: 3,
          borderBottom: "1px solid ",
          borderColor: theme.palette.primary.main,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              width: "45%",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              sx={{
                textTransform: "capitalize",
                font: "normal normal 600 14px/19px 'Segoe UI'",
                color: "#FFFFFF",
                background: "#089BAE",
                borderRadius: "5px",
                "&:hover": {
                  background: "#148695",
                },
              }}
              onClick={handleOpenDetailForm}
            >
              Activate
            </Button>
            {openDetailForm && !addonData?.editOnFrontend && (
              <AddonDetailsForm
                open={openDetailForm}
                smdList={smdList}
                isBotSelect={isBotSelect}
                selectedSmd={selectedSmd}
                onClose={handleOpenDetailForm}
                inputList={inputList}
                onSelectSmd={commonOnSelectSmd}
                selectedPlatform={selectedPlatform}
                onPlatformSelect={onPlatformSelect}
                inputs={inputs}
                setInputs={setInputs}
                onSubmit={downloadQrCode}
                smdActiveAddons={smdActiveAddons}
                isValid={isValid}
                setIsValid={setIsValid}
                validate={validate}
                errorMessage={errorMessage}
                platformLink={platformLink}
                elementRef={elementRef}
              >
                <Typography
                  component={"div"}
                  sx={{ width: "100%", mb: "15px" }}
                >
                  <BasicSelect
                    width={"100%"}
                    size="small"
                    label={"Bots"}
                    title="Bots"
                    items={smdList}
                    selected={selectedSmd}
                    onSelect={commonOnSelectSmd}
                    isDisabled={isBotSelect}
                  />
                </Typography>
                <Typography
                  component={"div"}
                  sx={{ width: "100%", mb: "15px" }}
                >
                  <BasicSelect
                    width={"100%"}
                    size="small"
                    label={"Platform"}
                    title="Platform"
                    items={smdActiveAddons}
                    selected={selectedPlatform}
                    onSelect={onPlatformSelect}
                    isDisabled={isBotSelect}
                  />
                </Typography>
                {!!platformLink && (
                  <Container
                    ref={elementRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "#089BAE",
                      borderRadius: "5px",
                      width: "200px",
                      height: "250px",
                      boxShadow: "0px 6px 8px #3E3E3E4D",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "5px",
                        overflow: "hidden",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "170px", height: "170px" }}>
                        <QRCode
                          id="qrCodeImg"
                          className={platformLink}
                          size={150}
                          eyeColor="#089BAE"
                          value={platformLink}
                          logoImage={platform_images[selectedPlatform]}
                          ecLevel={"H"}
                          removeQrCodeBehindLogo={true}
                          eyeRadius={3}
                          logoPaddingStyle="circle"
                        />
                      </div>
                    </div>

                    <Typography
                      component={"div"}
                      variant="h4"
                      style={{
                        font: "normal normal 600 28px/24px 'Graphie, SemiBold'",
                        letterSpacing: "0px",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={largeLogoIcon}
                        alt="logo"
                        style={{ height: "50px" }}
                      />
                      {smdList.find((smd) => smd.id === selectedSmd).name}
                    </Typography>
                  </Container>
                )}
              </AddonDetailsForm>
            )}
          </Typography>
        </Typography>
      </Typography>
    </div>
  );
};

export default QrCodeAddonDetails;
