import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Typography } from "@mui/material";

export default function AppNavigation({ children }) {
  return (
    <>
      <Typography
        component={"div"}
        sx={{ display: "flex", height: "100%", overflowY: "hidden" }}
      >
        <CssBaseline />
        {children}
      </Typography>
    </>
  );
}
