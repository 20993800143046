// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddOnPage_container__Dw5q4 {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/AddOnList/AddOnPage.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ","sourcesContent":[".container {\n    width: 100%;\n    // padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AddOnPage_container__Dw5q4"
};
export default ___CSS_LOADER_EXPORT___;
