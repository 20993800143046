import { MarkerType } from "reactflow";
import ButtonEdge from "./ButtonEdge";
import CustomNode from "./CustomNode";

export const nodeTypes = {
  custom: CustomNode,
};

export const edgeTypes = {
  buttonedge: ButtonEdge,
};
export const edgeOptions = {
  //   animated: true,
  style: {
    stroke: "black",
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: "25px",
    height: "25px",
  },
  type: "buttonedge",
};

export const initialNodes = [
  {
    width: 274,
    height: 98,
    id: "0",
    type: "custom",
    data: { id: "0", isInitialNode: true },
    position: {
      x: -350,
      y: -200,
    },
    positionAbsolute: {
      x: -350,
      y: -200,
    },
    selected: false,
    dragging: false,
  },
];
