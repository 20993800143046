import { fork } from "redux-saga/effects";
import { routinePromiseWatcherSaga } from "redux-saga-routines";

import { watchAddonsSaga } from "./addonsSaga";
import { watchBotSaga } from "./botSaga";
import { watchAdminSaga } from "./adminSaga";
import { watchManagerSaga } from "./managerSaga";

export default function* rootSaga() {
  yield fork(watchAddonsSaga);
  yield fork(routinePromiseWatcherSaga);
  yield fork(watchBotSaga);
  yield fork(watchAdminSaga);
  yield fork(watchManagerSaga);
}
