import React, { useState } from "react";
import { DialogModal } from "../Modal/DialogModal";
import { Grid, Typography } from "@mui/material";
import "./style.scss";
import { connect } from "react-redux";
import { Init } from "../Init/Init";
import AiCard from "../AiCard/AiCard";
import AddIcon from "@mui/icons-material/Add";

const BotTemplates = ({ open, onClose }) => {
  const [openInit, setOpenInit] = useState(false);
  const [isAiModalOpen, setIsAiModalOpen] = useState(false);

  const handleOpenInit = () => {
    localStorage.removeItem("flowData");
    setOpenInit(true);
  };

  const handleCloseInit = () => {
    setOpenInit(false);
  };

  const openAiModal = () => {
    !isAiModalOpen && setIsAiModalOpen(true);
  };

  const closeAiModal = () => {
    setIsAiModalOpen(false);
  };

  const closeModalAndInit = () => {
    handleCloseInit();
    onClose();
  };

  return (
    <DialogModal open={open} onClose={closeModalAndInit} visibleBackdrop>
      {!openInit && (
        <div className={"botTemplatesContainer"}>
          <Typography component={"div"} className="innerContainer">
            <Grid
              container
              item
              lg={12}
              rowSpacing={3}
              columnSpacing={{ xs: 12, sm: 4, md: 4 }}
              justifyItems={"stretch"}
              sx={{
                alignContent: "center",
                padding: 2,
                justifyContent: "space-around",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  display: "flex",
                  padding: 3,
                  alignContent: "center",
                  justifyContent: "center",
                }}
                onClick={handleOpenInit}
              >
                <Typography
                  component={"div"}
                  className="card"
                  onClick={handleOpenInit}
                >
                  Create New
                  <AddIcon />
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  display: "flex",
                  padding: 3,
                  alignContent: "center",
                  justifyContent: "center",
                }}
                onClick={openAiModal}
              >
                <AiCard
                  isModalOpen={isAiModalOpen}
                  onClose={closeAiModal}
                  onClick={onClose}
                />
              </Grid>
              {/* {templates.map((template, index) => (
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex', padding: 3, alignContent: "center", justifyContent: 'center' }} onClick={() => onClose()}>
                                <BotTemplateCard data={template} onClick={onClose} />
                            </Grid>
                        ))} */}
            </Grid>
          </Typography>
        </div>
      )}
      {openInit && <Init onClose={handleCloseInit} isEdit={false} />}
    </DialogModal>
  );
};

const mapStateToProps = (state) => {
  return {
    templates: state.botTemplates,
  };
};

export default connect(mapStateToProps)(BotTemplates);
