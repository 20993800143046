import { toast } from "react-toastify";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  getManagers,
  updateManager,
  deleteManager,
  registerManager,
  setManagerDetails,
} from "../routines";
import { Apis } from "./../api"; // Replace with your actual API function
import { ROUTES } from "../utils/constant";

function* getManagersSaga() {
  try {
    yield put(getManagers.request());
    const response = yield call(Apis.getManagers);
    yield put(getManagers.success(response));
  } catch (error) {
    toast.error(error.message);
    yield put(getManagers.failure(error.message));
  } finally {
    yield put(getManagers.fulfill());
  }
}

function* updateManagerSaga({ payload }) {
  try {
    yield put(updateManager.request());
    const response = yield call(Apis.updateManger, payload);
    yield put(updateManager.success());
    toast.success(response.message);
    yield put(getManagers.trigger());
  } catch (error) {
    toast.error(error.message);
    yield put(updateManager.failure(error.message));
  } finally {
    yield put(updateManager.fulfill());
  }
}

function* deleteManagerSaga({ payload }) {
  try {
    yield put(deleteManager.request());
    const response = yield call(Apis.deleteManager, payload);
    yield put(deleteManager.success());
    yield put(getManagers.trigger());
    toast.success(response.message);
  } catch (error) {
    toast.error(error.message);
    yield put(deleteManager.failure(error.message));
  } finally {
    yield put(deleteManager.fulfill());
  }
}

function* registerManagerSaga({ payload }) {
  try {
    yield put(registerManager.request());
    const response = yield call(Apis.registerManager, payload);
    yield put(registerManager.success());
    yield put(getManagers.trigger());
    toast.success(response.message);
  } catch (error) {
    toast.error(error.message);
    yield put(registerManager.failure(error.message));
  } finally {
    yield put(registerManager.fulfill());
  }
}

function* setManagerDetailsSaga({ payload }) {
  try {
    yield put(setManagerDetails.request());
    const response = yield call(Apis.setManagerDetails, payload);
    yield put(setManagerDetails.success());
    window.location.replace(ROUTES.LOG_IN);
    toast.success(response.message);
  } catch (error) {
    toast.error(error.message);
    yield put(setManagerDetails.failure(error.message));
  } finally {
    yield put(setManagerDetails.fulfill());
  }
}

export function* watchManagerSaga() {
  yield takeLatest(getManagers.TRIGGER, getManagersSaga);
  yield takeLatest(updateManager.TRIGGER, updateManagerSaga);
  yield takeLatest(deleteManager.TRIGGER, deleteManagerSaga);
  yield takeLatest(registerManager.TRIGGER, registerManagerSaga);
  yield takeLatest(setManagerDetails.TRIGGER, setManagerDetailsSaga);
}
