import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import moment from "moment";
import { Typography } from "@mui/material";

const DatesRangePicker = ({
  startDate,
  endDate,
  onDateChange,
  maxDate,
  isOutsideRange,
  readOnly,
}) => {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="start_date_id"
      endDate={endDate}
      endDateId="end_date_id"
      onDatesChange={onDateChange}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      displayFormat="DD/MM/YYYY"
      minimumNights={0}
      hideKeyboardShortcutsPanel
      openDirection="down"
      orientation="horizontal"
      horizontalMargin={20}
      disableScroll
      readOnly={readOnly}
      isOutsideRange={isOutsideRange}
    />
  );
};

export default DatesRangePicker;
