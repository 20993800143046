// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfilePage_Container__9p7ud {\n  margin-top: 50px;\n  width: 100%;\n}\n\n.ProfilePage_bottonContainer__SXWDN {\n  width: 100%;\n}\n\n.ProfilePage_content__-ZRQq {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/Profile/ProfilePage.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,WAAA;AACJ;;AAGA;EACI,WAAA;AAAJ;;AAGA;EACI,WAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;AADJ","sourcesContent":[".Container {\n    margin-top: 50px;\n    width: 100%;\n    //  height: fit-content;\n}\n\n.bottonContainer {\n    width: 100%;\n}\n\n.content {\n    width: 100%;\n    //height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "ProfilePage_Container__9p7ud",
	"bottonContainer": "ProfilePage_bottonContainer__SXWDN",
	"content": "ProfilePage_content__-ZRQq"
};
export default ___CSS_LOADER_EXPORT___;
