import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { theme } from "../../utils/theme";
import { BootstrapTooltip } from "../UI/Tooltip";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    paddingLeft: '15px',
    paddingTop: '5px',
    paddingBottom: '5px',
    gap: '25px',
    '&:hover': {
      backgroundColor: '#a8afafb5',
      '& $isNotActive': {
        color: "black",
        opacity: 1,
      },
    },
  },

  listItemIcon: {
    minWidth: 'fit-content',
    marginRight: '0px',
  },
  listItemText: {
    fontSize: "16px",
    fontFamily: "Arial, sans-serif",
    fontWeight: "normal",
  },

  isActive: {
    color: "#089BAE",
    opacity: 1,
  },

  isNotActive: {
    opacity: 0.5,
    color: "#121858",
  },
}));

export const NavigationList = ({ items, isDivider, isLink, isOpen }) => {
  const classes = useStyles()
  const [navigationListItem, setNavigationsListItem] = useState([]);
  const location = useLocation();
  useEffect(() => {
    if (items.length) {
      setNavigationsListItem([...items]);
    }
  }, [items]);

  const isActive = (pathName) => {
    return pathName === location.pathname
  }
  return (
    <>
      {isDivider && (
        <Divider sx={{ background: theme.palette.primary.background }} />
      )}
      <List sx={{ p: 0 }}>
        {navigationListItem.map((item, index) => (
          <React.Fragment key={index}>
            {isLink ? (
              <Link
                to={item.path}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <ListItem disablePadding
                  className={`${isOpen ? classes.listItem : classes.listItem}`}
                >
                  {!isOpen ? (
                    <BootstrapTooltip
                      title={item.title}
                      arrow
                      placement="right"
                    >
                      <ListItemIcon
                        className={`${isActive(item.path) ? classes.isActive : classes.isNotActive} ${classes.listItemIcon}`}
                      >
                        {item.icon}
                      </ListItemIcon>
                    </BootstrapTooltip>
                  ) : (
                    <>
                      <ListItemIcon
                        className={`${isActive(item.path) ? classes.isActive : classes.isNotActive} ${classes.listItemIcon}`}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        className={`${isActive(item.path) ? classes.isActive : classes.isNotActive} ${classes.listItemText}`}
                      >{item.name}</ListItemText>
                    </>
                  )}
                </ListItem>
              </Link>
            ) : (
              <ListItem key={index} disablePadding sx={{ display: "block" }}>
                <BootstrapTooltip title={item.title} arrow placement="right">
                  <ListItemIcon
                    className={`${isActive(item.path) ? classes.isActive : classes.isNotActive} ${classes.listItemIcon}`}
                  >
                    {item.icon}
                  </ListItemIcon>
                </BootstrapTooltip>
                <ListItemText
                  className={`${isActive(item.path) ? classes.isActive : classes.isNotActive} ${classes.listItemText}`}
                >{item.name}</ListItemText>
              </ListItem>
            )}
            {isDivider && (
              <Divider sx={{ background: theme.palette.primary.background }} />
            )}
          </React.Fragment>
        ))}
      </List>
    </>
  );
};
