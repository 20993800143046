import React, { useState, useEffect } from "react";
import { AddonDetailsForm } from "../../AddonDetailsForm/AddonDetailsForm";
import AddonDetailsActivatedBots from "../../AddonDetailsActivatedBots/AddonDetailsActivatedBots";
import AddonDetailsActivationPanel from "../../AddonDetailsActivationPanel/AddonDetailsActivationPanel";
import NodeMapper from "../../NodeMapper/NodeMapper";
import { toast } from "react-toastify";
import { mailchimpObject } from "../../../utils/constant";
import { Typography } from "@mui/material";
import { BasicSelect } from "../../Select/Select";

const MailchimpAddonDetails = ({
  deactivateAddon,
  isConfigure,
  inputList,
  smdList,
  selectedSmd,
  commonOnSelectSmd,
  inputs,
  setInputs,
  isBotSelect,
  setIsBotSelect,
  openDetailForm,
  setSelectedSmd,
  addonData,
  handleDeactivateAllConfirmation,
  activatedSmd,
  setSmdList,
  commonHandleOpenDetailForm,
  commonOnSubmit,
  isValid,
  setIsValid,
  commonValidate,
  errorMessage,
  commonDeactivateAllAddon,
  isDeactivateAllConfirmation,
  commonHandleEdit,
  allSmd,
  setErrorMessage,
}) => {
  const [selectedBotNodes, setSelectedBotNodes] = useState([]);
  const [mappings, setMappings] = useState([{ node: "", objectKey: "" }]);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

  const onSubmit = async () => {
    try {
      await commonOnSubmit();
      handleOpenDetailForm();
    } catch (error) {
      toast.error("Error occured while trying to add salesforce object");
    }
  };

  const validate = () => {
    if (commonValidate()) {
      if (!validateMappingAddon()) {
        return;
      } else {
        addMappingDataToInputs();
        setIsReadyToSubmit(true);
      }
    }
  };

  const handleOpenDetailForm = () => {
    if (openDetailForm) {
      setSelectedBotNodes([]);
      setMappings([{ node: "", objectKey: "" }]);
    }
    commonHandleOpenDetailForm();
  };

  const addMappingDataToInputs = () => {
    const mappingObj = mappings.map((item) => {
      const objectValue = mailchimpObject.find(
        (obj) => obj.name === item.objectKey
      ).value;
      return { [objectValue]: item.node };
    }); // map object to fit backend demands
    setInputs({
      ...inputs,
      mapping: { in: "body", visible: false, value: mappingObj },
    });
  };

  const isMappingsValid = () => {
    if (mappings.length === 0) {
      return false;
    }

    for (const mapping of mappings) {
      if (!mapping.node || !mapping.objectKey) {
        return false;
      }
    }

    return true;
  };

  const validateMappingAddon = () => {
    if (!isMappingsValid()) {
      setErrorMessage("Please fill in all mapping inputs");
      setIsValid(false);
      return false;
    }

    return true;
  };

  const handleEdit = (smd) => {
    const mcAddonData = smd.platform.mailchimp;
    const selectBotMappingObj = JSON.parse(mcAddonData.mapping);
    const selectBotMapping = selectBotMappingObj.map((obj) => {
      const objectKey = mailchimpObject.find(
        (mapObj) => mapObj.value === Object.keys(obj)[0]
      ).name;
      return { node: obj[Object.keys(obj)[0]], objectKey: objectKey };
    });
    setMappings(selectBotMapping);
    commonHandleEdit(smd);
  };

  useEffect(() => {
    if (!!selectedSmd) {
      const selectedBotSmd = allSmd.find((item) => item.id === selectedSmd);
      if (selectedBotSmd) {
        const smdConfig = JSON.parse(selectedBotSmd.smd);
        const nodes = Object.getOwnPropertyNames(smdConfig?.messages);
        setSelectedBotNodes(nodes);
      }
    }
  }, [selectedSmd]);

  useEffect(() => {
    if (isReadyToSubmit) {
      onSubmit();
      setIsReadyToSubmit(false);
    }
  }, [isReadyToSubmit]);

  return (
    <div>
      <AddonDetailsActivationPanel
        addonData={addonData}
        handleDeactivateAllConfirmation={handleDeactivateAllConfirmation}
        handleOpenDetailForm={handleOpenDetailForm}
        isConfigure={isConfigure}
        deactivateAllAddon={commonDeactivateAllAddon}
        isDeactivateAllConfirmation={isDeactivateAllConfirmation}
      />
      <AddonDetailsActivatedBots
        activatedSmd={activatedSmd}
        setSelectedSmd={setSelectedSmd}
        addonData={addonData}
        setInputs={setInputs}
        setIsBotSelect={setIsBotSelect}
        handleOpenDetailForm={handleOpenDetailForm}
        setSmdList={setSmdList}
        deactivateAddon={deactivateAddon}
        handleEdit={handleEdit}
      />
      {openDetailForm && !addonData?.editOnFrontend && (
        <AddonDetailsForm
          open={openDetailForm}
          smdList={smdList}
          isBotSelect={isBotSelect}
          selectedSmd={selectedSmd}
          onClose={handleOpenDetailForm}
          inputList={inputList}
          onSelectSmd={commonOnSelectSmd}
          inputs={inputs}
          setInputs={setInputs}
          isValid={isValid}
          setIsValid={setIsValid}
          validate={validate}
          errorMessage={errorMessage}
        >
          <Typography component={"div"} sx={{ width: "100%", mb: "15px" }}>
            <BasicSelect
              width={"100%"}
              size="small"
              label={"Bots"}
              title="Bots"
              items={smdList}
              selected={selectedSmd}
              onSelect={commonOnSelectSmd}
              isDisabled={isBotSelect}
            />
          </Typography>
          {!!selectedBotNodes.length && (
            <NodeMapper
              nodes={selectedBotNodes}
              objectKeys={mailchimpObject.map((obj) => obj.name)}
              mappings={mappings}
              setMappings={setMappings}
            />
          )}
        </AddonDetailsForm>
      )}
    </div>
  );
};

export default MailchimpAddonDetails;
