// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Bot_botContainer__h0dcH {\n  background-color: var(--main-bg-color);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/Flow/Bot.module.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AACJ","sourcesContent":[".botContainer {\n    background-color: var(--main-bg-color);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n   // height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"botContainer": "Bot_botContainer__h0dcH"
};
export default ___CSS_LOADER_EXPORT___;
