import { handleActions } from "redux-actions";
import { getBotUsers } from "../routines";

const defaultState = [];

export const botUsersReducer = handleActions(
  {
    [getBotUsers.SUCCESS]: (state, { payload }) => {
      return [...payload];
    },
  },
  defaultState
);
