const GeneralSectionForm = ({ form }) => {
  return (
    <div className="flex items-center h-full">
      <div className="w-full">
        <label
          for="email"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Email
        </label>
        <input
          id="email"
          type="email"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="Type here"
          required
          value={form.values.email}
          name="email"
          onChange={form.handleChange}
        />
      </div>
    </div>
  );
};

export default GeneralSectionForm;
