import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/constant";
import "./NotFound.scss";

const NotFound = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate(ROUTES.LOG_IN);
    }
  });
  return (
    <div className={"NotFound"}>
      <p>The page you were looking for does not exist.</p>
      <p>
        You may have mistyped the address or the page may have moved.{" "}
        <Link to={ROUTES.DASHBOARD} replace>
          Go To Dashboard
        </Link>
      </p>
    </div>
  );
};

export default React.memo(NotFound);
