import { toast } from "react-toastify";
import { API_URLS } from "./utils/constant";
import { sendRequest } from "./utils/helper";

export async function getBots() {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: API_URLS.GET_SMD,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function initiateInstance(data) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: API_URLS.INITIATE_INSTANCE,
    method: "POST",
    headers: {
      authorization: `Bearer ${authToken}`,
    },
    body: data,
  });
}

// export async function updateBot(body) {
//   const authToken = localStorage.getItem("authToken");
//   return sendRequest({
//     url: API_URLS.UPDATE_SMD,
//     method: "PUT",
//     headers: {
//       authorization: `Bearer ${authToken}`,
//     },
//     body,
//   });
// }
// export default api;
export async function initiateInstanceBySmd(data) {
  return sendRequest({
    url: API_URLS.INITIATE_INSTANCE_BY_SMD,
    method: "POST",
    body: data,
  });
}

export async function getUserInteractions({ id, offset, isCompleted }) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.USER_INTERACTIONS}/${id}?offset=${offset}&isCompleted=${isCompleted}`,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function getChatById(botId) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `/analytics/chats/${botId}`,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function getAllChats(
  id,
  uid,
  isCompleted = false,
  offset = 0,
  searchText = ""
) {
  const authToken = localStorage.getItem("authToken");
  let url = `${API_URLS.USER_INTERACTIONS}/${id}?isCompleted=${isCompleted}&offset=${offset}`;

  if (uid !== undefined) {
    url += `&uid=${uid}`;
  }
  if (!!searchText) {
    url += `&searchText=${searchText}`;
  }
  return sendRequest({
    // url: `${API_URLS.USER_INTERACTIONS}/${id}?uid=${uid}&isCompleted=${isCompleted}`,
    url: url,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function getUserInteractionsCount({ id, isCompleted }) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.USER_INTERACTIONS_COUNT}/${id}?isCompleted=${isCompleted}`,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function getLiveBots(id) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.LIVE_BOTS}/${id}`,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function botClosingApi(id) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.DELETE_POPUP_SMD}/${id}`,
    method: "DELETE",
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function getBotAddonsList() {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: API_URLS.BOT_ADD_ON_LIST,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function getBotAddonDetails({ name }) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.BOT_ADD_ON_LIST}?addon=${name}`,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function addBotAddon({ url, body }) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url,
    method: "POST",
    headers: {
      authorization: `Bearer ${authToken}`,
    },
    body,
  });
}

export async function removeAllBotAddon({ addon }) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.REMOVE_ALL_ADDON}?addon=${addon}`,
    method: "DELETE",
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function removeBotAddon({ addon, smdId }) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.REMOVE_ADDON}?addon=${addon}&smdId=${smdId}`,
    method: "DELETE",
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function checkSmdButton(name) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.CHECK_SMD}?smdName=${name}`,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function getUsers(id, offset) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.USER_PANAL_USERS}/${id}?offset=${offset}`,
    method: "GET",
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function getAllUsers(id) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.USER_PANAL_ALL_USERS}/${id}`,
    method: "GET",
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function activateAddon({ name }) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.BOT_ADD_ON_LIST}?addon=${name}`,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
    method: "PUT",
  });
}

export async function setApiDetails(body) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.EXTERNAL_API}`,
    method: "POST",
    body,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function getActivatedApis() {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.EXTERNAL_API}`,
    method: "GET",
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export async function deleteApi(_id) {
  const authToken = localStorage.getItem("authToken");
  return sendRequest({
    url: `${API_URLS.EXTERNAL_API}?_id=${_id}`,
    method: "DELETE",
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
}

export const Apis = {
  createBot(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.CREATE_SMD,
      method: "POST",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },
  updateBot(body) {
    console.log("body", body);
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.UPDATE_SMD,
      method: "PUT",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body,
    });
  },
  getBots() {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.GET_SMD,
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },
  getBotVersions(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: `${API_URLS.GET_SMD_VERSIONS}?smd_id=${payload.smdId}&smd_version=${payload.version}`,
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },
  deleteBot(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.DELETE_SMD,
      method: "DELETE",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },
  getBotUses(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.GET_BOT_USES,
      method: "POST",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },
  getBotNewUsers(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.GET_BOT_NEW_USERS,
      method: "POST",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },
  getBotUsers(payload) {
    const authToken = localStorage.getItem("authToken");
    const isValidDate = (dateString) => !isNaN(Date.parse(dateString));
    const dateParams =
      isValidDate(payload.from) && isValidDate(payload.to)
        ? `&from=${payload.from}&to=${payload.to}`
        : "";
    const queryParams = `?offset=${payload.offset}${dateParams}`;
    const apiURL = `${API_URLS.USER_PANAL_USERS}/${payload.id}${queryParams}`;
    return sendRequest({
      url: apiURL,
      method: "GET",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },
  getBotAddonsList() {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.BOT_ADD_ON_LIST,
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },

  getActivatedApis() {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: `${API_URLS.EXTERNAL_API}`,
      method: "GET",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },
  setApiKey(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.CREATE_API_KEY,
      method: "POST",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },
  getApiKeys() {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.GET_API_KEYS,
      method: "GET",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },
  deleteApiKey(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.GET_API_KEYS,
      method: "DELETE",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },

  getCSVReport(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: `${API_URLS.GET_CSV_REPORT}/${payload.smdId}?isCompleted=${payload.isCompleted}&from=${payload.from}&to=${payload.to}`,
      method: "GET",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },
  getBotTemplates() {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.GET_BOT_TEMPLATES,
      method: "GET",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },
  updateUserDetails(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.USER,
      method: "PUT",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },

  login(payload) {
    return sendRequest({
      url: API_URLS.LOGIN,
      method: "POST",
      body: payload,
    });
  },

  getUser() {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.USER,
      method: "GET",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },

  updatePassword(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.UPDATE_PASSWORD,
      method: "POST",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },
  getSalesforceAuth() {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: `${API_URLS.SALESFORCE_AUTH}`,
      method: "GET",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },
  getSalesforceCreds() {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: `${API_URLS.SALESFORCE_CREDS}/${authToken}`,
      method: "GET",
    });
  },
  async isSMDAlreadyExist(name) {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await sendRequest({
        url: `${API_URLS.CHECK_SMD}?smdName=${name}`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) return false;
    } catch (error) {
      if (error.status === 403) {
        toast.error("Bot already exist, Please change your bot name");
        return true;
      } else {
        console.error(error);
      }
    }
  },

  getSmdfromPrompt(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.GET_SMD_FROM_PROMPT,
      method: "POST",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },

  getManagers() {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.MANAGERS,
      method: "GET",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },

  updateManger(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: `${API_URLS.MANAGERS}/${payload.id}`,
      method: "PATCH",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },

  verifyManagerEmail(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.MANAGERS_CHECK_EMAIL,
      method: "POST",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },

  registerManager(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: API_URLS.MANAGERS_REGISTER,
      method: "POST",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      body: payload,
    });
  },

  deleteManager(payload) {
    const authToken = localStorage.getItem("authToken");
    return sendRequest({
      url: `${API_URLS.MANAGERS}/${payload.id}`,
      method: "DELETE",
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  },

  setManagerDetails(payload) {
    return sendRequest({
      url: API_URLS.SET_MANAGER_DETAILS,
      method: "POST",
      body: payload,
    });
  },
};
