import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
//import styles from "./DataTable.module.scss";
export default function DataTable({
  loading,
  rows,
  onPageChange,
  onPageSizeChange,
  pageSize,
  page,
  rowCount,
  columns,
  hideFooterPagination,
  hideFooter,
  height,
}) {
  const [tableRows, setTableRows] = useState([]);
  useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  return (
    <Typography
      style={{ height: height, width: "100%", background: "white" }}
      component={"div"}
      sx={{
        borderRadius: "4px",
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      }}
    >
      <DataGrid
        rows={tableRows}
        columns={columns}
        pagination
        pageSize={pageSize}
        page={page}
        rowsPerPageOptions={[]}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        rowCount={rowCount}
        loading={loading}
        hidePageSizeOptions={true}
        paginationMode="server"
        disableSelectionOnClick
        disableColumnSelector
        hideFooter={hideFooter}
        hideFooterPagination={hideFooterPagination}
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
        }}
      />
    </Typography>
  );
}
