import React, { useContext, useEffect, useState } from "react";
import { EdgeLabelRenderer, getBezierPath, BaseEdge } from "reactflow";
import { FlowContext } from "../../store/flow-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { BootstrapTooltip } from "../UI/Tooltip";

import styles from "./ButtonEdge.module.scss";
import { guards } from "../../utils/constant";
import { toast } from "react-toastify";
import { characterLength } from "../../utils/constant";

const foreignObjectSize = 200;

export default function ButtonEdge(buttonEdgeData) {
  const {
    source,
    target,
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    id,
    style,
    markerEnd,
    data,
  } = buttonEdgeData;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [buttonClicked, setButtonClicked] = useState(false);
  const flowContext = useContext(FlowContext);
  const [transitionText, setTransitionText] = useState(data?.transition || "");
  const [isShowEdgeButton, setIsShowEdgeButton] = useState(true);
  const [validEvent, setValidEvent] = useState(true);
  const [transitionEvent, setTransitionEvent] = useState(new Set([]));

  const onEdgeClick = (evt, id) => {
    setButtonClicked(true);
  };

  const handleFocus = () => {
    // let arr = [];
    // flowContext.flowData.edges.forEach((edge) => {
    //   if (source === edge.source && !!edge.data.transition && edge.id !== id)
    //     arr.push(edge.data.transition);
    // });
    // setTransitionEvent(new Set([...arr]));
  };

  useEffect(() => {
    const filteredNode = flowContext.flowData.nodes.find(
      (node) => node.id === source
    );
    if (!!filteredNode && filteredNode.data.type !== guards.isText) {
      setIsShowEdgeButton(false);
    } else {
      setIsShowEdgeButton(true);
    }
    let eventSet = new Set([]);
    flowContext.flowData.edges.forEach((edge) => {
      if (source === edge.source && !!edge.data.transition && edge.id !== id) {
        eventSet.add(edge.data.transition);
      }
    });
    if (eventSet.has(transitionText)) {
      setValidEvent(false);
    } else {
      setValidEvent(true);
    }
  }, [flowContext]);

  useEffect(() => {
    if (!isShowEdgeButton) {
      handleDeleteTransition(id);
    }
  }, [isShowEdgeButton]);

  useEffect(() => {
    flowContext.addOrUpdateEdge({
      ...buttonEdgeData,
      transition: transitionText.length ? transitionText : undefined,
      isDuplicate: !validEvent,
    });
  }, [validEvent]);

  // const checkDuplicateEvent = (source,value) => {
  //     setValidEvent(true);
  //     flowContext.setHasDuplicateEvents(false);
  //     flowContext.flowData.edges.forEach((edgeData) => {
  //       if (edgeData.data.transition === value && edgeData.source === source) {
  //         setValidEvent(false);
  //         flowContext.setHasDuplicateEvents(true);
  //       }
  //     })
  //   }
  const transitionTextChangedHandler = (event) => {
    //checkDuplicateEvent(buttonEdgeData.source, event.target.value);
    const text = event.target.value;
    if (text.length <= characterLength.maxEventNameLimit) {
      setTransitionText(text);
      flowContext.addOrUpdateEdge({
        ...buttonEdgeData,
        transition: text.trim(),
        isDuplicate: transitionEvent.has(event.target.value),
      });
    } else {
      toast.warning(
        `Maximum ${characterLength.maxEventNameLimit} characters are allowed for the event name`
      );
    }
  };

  const handleDeleteTransition = () => {
    flowContext.addOrUpdateEdge({
      ...buttonEdgeData,
      transition: undefined,
      isDuplicate: false,
    });
    setTransitionText("");
    setButtonClicked(false);
  };

  const handleBlur = () => {
    if (transitionText.trim() === "") {
      setButtonClicked(false);
      flowContext.addOrUpdateEdge({
        ...buttonEdgeData,
        transition: undefined,
        isDuplicate: false,
      });
    } else {
      flowContext.addOrUpdateEdge({
        ...buttonEdgeData,
        transition: transitionText.trim(),
        isDuplicate: transitionEvent.has(transitionText.trim()),
      });
      setTransitionText(transitionText.trim());
    }
  };
  const xCoordinate = data?.reverseEdgeExists
    ? labelX - foreignObjectSize / 5
    : labelX - foreignObjectSize / 1.5;

  const yCoordinate = data?.reverseEdgeExists
    ? labelY - foreignObjectSize / 5
    : labelY - foreignObjectSize / 1.5;

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          //className={styles.edgeButtonForeignObject}
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            borderRadius: 5,
            fontSize: 20,
            fontWeight: 700,
            pointerEvents: "all",
            maxWidth: "170px",
          }}
          foreignObject
          className="nodrag nopan"
        >
          {buttonClicked || transitionText ? (
            <div
              className={`${
                validEvent ? styles.inputEvent : styles.eventContainerInvalid
              }`}
            >
              <TextField
                error={!validEvent}
                autoFocus
                hiddenLabel
                size="small"
                placeholder="Add Event"
                value={transitionText}
                onChange={transitionTextChangedHandler}
                onBlur={handleBlur}
                onFocus={handleFocus}
                InputProps={{
                  className: styles.inputEvent,
                  endAdornment: (
                    <BootstrapTooltip
                      title="Remove Event"
                      arrow
                      placement="right"
                    >
                      <InputAdornment
                        position="end"
                        sx={{ marginRight: "-12px", marginLeft: "0px" }}
                      >
                        <DeleteOutlinedIcon
                          sx={{
                            color: "#151575",
                            fontSize: 21,
                            cursor: "pointer",
                          }}
                          onClick={handleDeleteTransition}
                          edge="end"
                        />
                      </InputAdornment>
                    </BootstrapTooltip>
                  ),
                }}
              />
              {!validEvent && (
                <div className={styles.eventError}>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  <span>Event already exist !!</span>
                </div>
              )}
            </div>
          ) : isShowEdgeButton ? (
            <BootstrapTooltip title="Add Event" arrow>
              <FontAwesomeIcon
                icon={faCirclePlus}
                onClick={(event) => onEdgeClick(event, id)}
              />
            </BootstrapTooltip>
          ) : (
            <></>
          )}
        </div>
        {/* </foreignObject> */}
      </EdgeLabelRenderer>
    </>
  );
}
