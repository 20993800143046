// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChatSideWindow_container__tbw4z::-webkit-scrollbar {\n  background-color: transparent;\n  width: 5px;\n  max-height: 5px;\n}\n\n.ChatSideWindow_container__tbw4z::-webkit-scrollbar-thumb {\n  background: rgba(62, 62, 62, 0.5019607843);\n  border-radius: 10px;\n}\n\n.ChatSideWindow_container__tbw4z:hover {\n  overflow-y: auto;\n}", "",{"version":3,"sources":["webpack://./src/components/ChatSideWindow/ChatSideWindow.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,UAAA;EACA,eAAA;AACF;;AAEA;EACE,0CAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".container::-webkit-scrollbar {\n  background-color: transparent;\n  width: 5px;\n  max-height: 5px;\n}\n\n.container::-webkit-scrollbar-thumb {\n  background: #3E3E3E80;\n  border-radius: 10px;\n}\n\n.container:hover {\n  overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ChatSideWindow_container__tbw4z"
};
export default ___CSS_LOADER_EXPORT___;
