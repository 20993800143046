import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

function AuthRoutes() {
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, width: "fit-content", height: "fit-content" }}
    >
      <Outlet />
    </Box>
  );
}

export default AuthRoutes;
