// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardBanner_container__YC2tx {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px;\n  background: #FFFFFF 0% 0% no-repeat padding-box;\n  box-shadow: 5px 8px 20px rgba(8, 155, 174, 0.2);\n  border: 0.5px solid rgba(8, 155, 174, 0.5019607843);\n  border-radius: 5px;\n  opacity: 1;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/Banner/DashboardBanner.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,+CAAA;EACA,+CAAA;EACA,mDAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px;\n  background: #FFFFFF 0% 0% no-repeat padding-box;\n  box-shadow: 5px 8px 20px #089BAE33;\n  border: 0.5px solid #089BAE80;\n  border-radius: 5px;\n  opacity: 1;\n  width: 100%;\n}\n\n// .header {\n//   margin-bottom: 19px;\n//   font: normal normal normal 20px/24px 'Graphie, Regular';\n//   letter-spacing: 0px;\n//   color: #3E3E3E;\n//   opacity: 1;\n// }\n\n// .description {\n//  // margin-bottom: 39px;\n//   font: normal normal normal 14px/19px 'Segoe UI';\n//   letter-spacing: 0px;\n//   color: #3E3E3E;\n//   opacity: 1;\n// }\n\n// // .bannerImage {\n// //    width: 100%;\n// //   background-size: contain;\n// //   background-repeat: no-repeat;\n// //   background-position: center right;\n// // }\n\n// .buttonContainer {\n//   width: 25%;\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DashboardBanner_container__YC2tx"
};
export default ___CSS_LOADER_EXPORT___;
