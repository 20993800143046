import React, { Children, useState } from "react";
import CommonButton from "../Common/Button/CommonButton";
import styles from "./DashboardBanner.module.scss";
import BannerImage from "../../pages/Images/banner_image.png";
import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { display } from "@mui/system";

const DashboardBanner = ({
  image,
  imageWidth,
  imageHegiht,
  isRounded,
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Typography
        component={"div"}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: isMobile ? "start" : "center",
          flexDirection: isMobile ? "column" : "row",
          padding: 2,
          boxShadow: "5px 8px 20px #089BAE33",
          border: "0.5px solid #089BAE80",
          borderRadius: 2,
          opacity: 1,
        }}
      >
        {image && isMobile && (
          <Typography
            component={"div"}
            className={styles.imgContainer}
            sx={{
              width: "100%",
              height: imageHegiht ? imageHegiht : "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              width={"100%"}
              height={"100%"}
              style={{
                borderRadius: isRounded ? "50%" : "0%",
                aspectRatio: "1/1",
                objectFit: "cover",
              }}
              alt={"image"}
            />
          </Typography>
        )}
        <Typography
          component={"div"}
          sx={{
            width:
              !!image && !!imageWidth && !isMobile
                ? `${100 - imageWidth}%`
                : "100%",
            minHeight: imageHegiht ? imageHegiht : "100%",
          }}
        >
          {children}
        </Typography>
        {image && !isMobile && (
          <Typography
            component={"div"}
            sx={{
              width: imageWidth ? `${imageWidth}%` : "100%",
              height: imageHegiht ? imageHegiht : "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              width={"100%"}
              height={"100%"}
              style={{
                borderRadius: isRounded ? "50%" : "0%",
                aspectRatio: "1/1",
                objectFit: "cover",
              }}
              alt={"image"}
            />
          </Typography>
        )}
      </Typography>
    </>
  );
};

export default DashboardBanner;
