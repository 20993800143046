import { createAction, handleActions } from "redux-actions";
export const setUserChatSelectedSmd = createAction(
  "SET_USER_CHAT_SELECTED_SMD"
);

const initialState = null;

export const userChatSelectedSmdReducer = handleActions(
  {
    [setUserChatSelectedSmd]: (state, action) => action.payload,
    DEFAULT: (state) => state,
  },
  initialState
);
