import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import ReactFlow, {
    addEdge,
    useEdgesState,
    useNodesState,
    useReactFlow,
    Background,
    Panel,
    MiniMap,
    Controls,
} from "reactflow";
import "reactflow/dist/style.css";

import "./style.scss";
import { toast } from "react-toastify";

import {
    nodeTypes,
    edgeOptions,
    edgeTypes,
} from "../../components/CustomNodes/customProperties";
import {
    createJsonFromNodeData,
    NO_TRANSITION_EVENT_NAME,
} from "../../utils/jsonUtils";
import { FlowContext } from "../../store/flow-context";
import axios from "../../api/axios";
import { endpoints } from "../../api/endpoints";
import Slide from "@mui/material/Slide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, } from "@fortawesome/free-solid-svg-icons";
import { botClosingApi } from "../../api";
import { Button, CircularProgress, Dialog, Typography } from "@mui/material";
import { TestFlow } from "./TestFlow";
import { validateFlowData } from "../../utils/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" {...props} />;
});
const connectionLineStyle = { stroke: "black" };

function getRandomNodeId() {
    return Math.floor(Math.random() * 100000);
}

const Flow = ({ currentVersion, avatar, smdName, onEditBotInfo, versions, onVersionChange, isEditPanel, onUpdate, isCreatePanel }) => {
    const reactFlowInstance = useReactFlow();
    const flowContext = useContext(FlowContext);
    const [variant, setVariant] = useState("plane");
    const [botInitInfo, setBotInitInfo] = useState({});
    const [isTestClicked, setIsTestClicked] = useState(false);
    const [botFirstMessage, setBotFirstMessage] = useState({});
    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);

    const [nodes, setNodes, onNodesChange] = useNodesState(
        flowContext.flowData.nodes
    );

    const [edges, setEdges, onEdgesChange] = useEdgesState(
        flowContext.flowData.edges
    );

    const handleCloseTestFlow = async (id) => {
        setIsTestClicked(false);
        await botClosingApi(id);
    };

    const clearFlowDataHandler = () => {
        reactFlowInstance.setNodes([]);
        reactFlowInstance.setEdges([]);
        flowContext.clearEditFlowData();
    };

    const getBotJson = () => {
        const botJson = createJsonFromNodeData({
            nodes: flowContext.flowData.nodes,
            edges: flowContext.flowData.edges,
            initInfo: botInitInfo,
            variantType: variant,
        });
        return botJson;
    };



    const testClickHandler = (event) => {
        if (!validateFlowData({ nodes: flowContext.flowData.nodes, edges: flowContext.flowData.edges })) {
            return;
        }
        const authToken = localStorage.getItem("authToken");
        axios.post(endpoints.bot.test, getBotJson(), {
            headers: {
                authorization: `Bearer ${authToken}`,
            },
        }).then((res) => {
            const events = res.data.events.find(
                (evt) => evt === NO_TRANSITION_EVENT_NAME
            );
            setBotFirstMessage({
                message: res.data.message,
                events: events ? [] : res.data.events,
                // type: socketEvents.BOT_REPLY,
                botId: res.data.bot_id,
            });
            setIsTestClicked(true);
        })
            .catch((err) => console.error(err));
    };

    const onConnect = useCallback(
        (params) => {
            if (params.source === params.target) {
                toast.error("Self pointing edges are not allowed");
                return;
            }
            params.id = params.source + params.target;
            const reverseEdgeExists = flowContext.flowData.edges.find(
                (edge) => edge.source === params.target && edge.target === params.source
            );
            params.data = { reverseEdgeExists: !!reverseEdgeExists };
            setEdges((eds) => addEdge(params, eds));
            const edgeExists = flowContext.flowData.edges.find(
                (edge) => edge.source === params.source && edge.target === params.target
            );
            !edgeExists && flowContext.addOrUpdateEdge(params);
        },
        [flowContext, setEdges]
    );

    const onConnectStart = useCallback((_, { nodeId }) => {
        connectingNodeId.current = nodeId;
    }, []);

    const onConnectEnd = useCallback(
        (event) => {
            const targetIsPane = event.target.classList.contains("react-flow__pane");
            if (targetIsPane) {
                const id = `${getRandomNodeId()}`;
                // Remove the wrapper bounds, in order to get the correct position
                const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
                const newNode = {
                    id,
                    type: "custom",
                    data: {
                        id,
                    },
                    position: reactFlowInstance.project({
                        x: event.clientX - left - 75,
                        y: event.clientY - top,
                    }),
                };

                const newEdge = {
                    style: {
                        stroke: "white",
                    },
                    markerEnd: {
                        type: "arrowclosed",
                        width: "25px",
                        height: "25px",
                    },
                    type: "buttonedge",
                    id: connectingNodeId.current + id,
                    source: connectingNodeId.current,
                    target: id,
                };
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat(newEdge));
                flowContext.addOrUpdateEdge(newEdge);
                flowContext.addOrUpdateNode(newNode);
            }
        },
        [reactFlowInstance, setNodes, setEdges, flowContext]
    );

    const onNodeDrag = (_, node) => {
        flowContext.updateNodePosition(node.id, node.position.x, node.position.y);
    };

    const onNodeDelete = (nodes) => {
        flowContext.deleteNodeById(nodes[0]);
    };

    const onEdgesDelete = (edges) => {
        flowContext.deleteEdgeById(edges[0]);
    };
    useEffect(() => {
        reactFlowInstance.setNodes(flowContext.flowData.nodes);
        reactFlowInstance.setEdges(flowContext.flowData.edges);
    }, [
        reactFlowInstance,
        flowContext.flowData,
    ]);

    return (
        <Typography
            ref={reactFlowWrapper}
            component={"div"}
            sx={{
                height: `calc( 100% + 15px)`,
                width: `calc( 100% + 32px)`,
                m: -2.1,
                border: "2px solid #3E3E3E80",
                // '& .react-flow__attribution':{
                //   display:'none'
                // }
            }}
        >
            <Dialog
                classes={{
                    root: 'testContainer',
                }}
                PaperProps={{
                    style: {
                        background: "transparent",
                        margin: "0px",
                        border: "0.5px solid #C8C8C8",
                        borderRadius: "15px 15px 10px 10px",
                    },
                }}
                keepMounted
                open={isTestClicked}
                TransitionComponent={Transition}
                sx={{
                    height: 800,
                }}
                aria-describedby="alert-dialog-slide-description"
            >
                {isTestClicked && (
                    <TestFlow
                        avatar={avatar}
                        showTestFlow={setIsTestClicked}
                        botFirstMessage={botFirstMessage}
                        runBotTitle="Test Your Bot"
                        handleCloseTestFlow={handleCloseTestFlow}
                        isTest
                    />
                )}
            </Dialog>
            <ReactFlow
                edges={edges}
                nodes={nodes}
                defaultEdgeOptions={edgeOptions}
                style={{
                    backgroundColor: "#C8C8C880",
                }}
                ButtonEdge
                nodeTypes={nodeTypes}
                connectionLineStyle={connectionLineStyle}
                edgeTypes={edgeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onConnectStart={onConnectStart}
                onConnectEnd={onConnectEnd}
                onNodeDrag={onNodeDrag}
                onNodesDelete={onNodeDelete}
                onEdgesDelete={onEdgesDelete}
                preventScrolling={true}
                disableKeyboardA11y={flowContext.isKeyboardDisabled}
                fitViewOptions={{ duration: 1500 }}
                fitView
                proOptions={{ hideAttribution: true }}
                deleteKeyCode=""
            >
                {variant !== "plane" && (
                    <Background color="#6c6d70" variant={variant} />
                )}
                <MiniMap
                    className={"MiniMap"}
                    zoomable
                    pannable
                    position="bottom-right"
                />
                <Controls className={"controls"} position="bottom-left" />
                {isEditPanel && <Panel position="top-left" className={"buttonContainer"}>
                    <div className={"botNameContainer"}>
                        <span>
                            <img className={"editAvatar"} src={avatar} />
                            {smdName}
                        </span>
                        <button
                            className={"editIcon"}
                            onClick={onEditBotInfo}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                className={"editIcon"}
                            />
                        </button>
                    </div>
                    <Typography
                        component={"div"}
                        sx={{
                            display: "flex",
                            gap: 1,
                            height: "100%",
                            width: "fit-content",
                        }}
                    >
                        <select
                            className={"backgroundSelect"}
                            value={variant}
                            name="type"
                            onChange={(event) => {
                                setVariant(event.target.value);
                            }}
                        >
                            <option value={"plane"}>Plane</option>
                            <option value={"dots"}>Dotted</option>
                            <option value={"lines"}>Lines</option>
                            <option value={"cross"}>Cross</option>
                        </select>
                        <Button
                            sx={{
                                textTransform: "none",
                                fontWeight: 600,
                                background: "#089BAE",
                                color: "#FCFCFC",
                                "&:hover": {
                                    backgroundColor: "#0a5e69",
                                },
                            }}
                            onClick={testClickHandler}
                        >
                            Test
                        </Button>
                        <Button
                            sx={{
                                background: "#089BAE",
                                color: "#FCFCFC",
                                textTransform: "none",
                                fontWeight: 600,
                                "&:hover": {
                                    backgroundColor: "#0a5e69",
                                },
                            }}
                            onClick={onUpdate}
                        >
                            Update
                        </Button>
                        <select
                            className={"backgroundSelect"}
                            value={currentVersion}
                            name="type"
                            onChange={(event) => {
                                onVersionChange(event.target.value);
                            }}
                        >
                            {versions.map((version) => {
                                return <option value={version.value}>{version.label}</option>;
                            })}
                        </select>
                    </Typography>
                </Panel>}
            </ReactFlow>
        </Typography>

    );
}


export default Flow;