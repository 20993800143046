import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button } from "@mui/material";
import { DialogModal } from "../Modal/DialogModal";
import "./style.scss";
import GeneralSectionForm from "./GeneralSectionForm";
import Permissions from "./Permissions";
import { toast } from "react-toastify";
import { Apis } from "../../api";
import { useDispatch } from "react-redux";
import { registerManager } from "../../routines";
const initialData = {
  available_smds: {},
  access: {
    dashboard: {
      read: true,
      edit: false,
      add: true,
      delete: false,
    },
    analytics: {
      read: true,
      edit: false,
      add: true,
      delete: false,
    },
    addon: {
      read: true,
      edit: false,
      add: true,
      delete: false,
    },
    users: {
      read: true,
      edit: false,
      add: true,
      delete: false,
    },
    chat: {
      read: true,
      edit: false,
      add: true,
      delete: false,
    },
  },
};

const AddManager = ({ onClose, open }) => {
  const [activeSection, setActiveSection] = useState("general");
  const [validatingEmail, setValidatingEmail] = useState(false);
  const [creatingManager, setCreatingManager] = useState(false);
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      ...initialData.access,
      available_smds: initialData.available_smds,
      email: "",
    },
    onSubmit: (values) => {
      if (activeSection === "general") {
        validateEmail(values.email);
      } else if (activeSection === "permissions") {
        handleRegisterManager(values);
      }
    },
  });

  const handleRegisterManager = async ({
    available_smds,
    email,
    ...values
  }) => {
    if (creatingManager) return;
    setCreatingManager(true);

    const payload = {
      available_smds,
      email,
      access: values,
    };

    dispatch(registerManager.trigger(payload));
    form.resetForm();
    handleClose();
    setCreatingManager(false);
  };

  const validateEmail = async () => {
    if (validatingEmail) return null;
    setValidatingEmail(true);

    const data = await Apis.verifyManagerEmail({
      email: form.values.email,
    });

    if (data.isEmailExist) {
      toast.error(data.message);
    } else {
      setActiveSection("permissions");
    }
    setValidatingEmail(false);
  };

  const handleClose = () => {
    setActiveSection("general");
    form.resetForm();
    onClose();
  };

  return (
    <DialogModal visibleBackdrop onClose={handleClose} open={open}>
      <form
        className="AddManagerModalContainer p-4"
        onSubmit={form.handleSubmit}
      >
        <p className="p-0 text-xl font-semibold text-[#089BAE] text-center">
          Add new manager
        </p>
        <div style={{ height: "512px" }} className="overflow-y-auto">
          {activeSection === "general" && <GeneralSectionForm form={form} />}
          {activeSection === "permissions" && <Permissions form={form} />}
        </div>
        <div className="mt-4 flex justify-between w-full">
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "fit-content",
              pl: 4,
              pr: 4,
              borderRadius: 1,
              background: "#e9ecef",
              color: "#000",
              "&:hover": {
                background: "#b4b5b5",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "fit-content",
              pl: 4,
              pr: 4,
              borderRadius: 1,
              background: "#089BAE",
              "&:hover": {
                background: "#148695",
              },
            }}
            type="submit"
          >
            Next
          </Button>
        </div>
      </form>
    </DialogModal>
  );
};

export default AddManager;
