import { combineReducers } from "redux";
// import { connectRouter } from 'connected-react-router';
// import { reducer as formReducer } from 'redux-form';
// import { reducer as notificationsReducer } from 'react-notification-system-redux';
import { addonsReducer } from "./reducers/addons";
import { activatedApisReducer } from "./reducers/activatedApis";
import { apiKeysReducer } from "./reducers/apiKeys";
import { botsReducer } from "./reducers/bots";
import { botUsesReducer } from "./reducers/botUses";
import { botNewUsersReducer } from "./reducers/botNewUsers";
import { botUsersReducer } from "./reducers/botUsers";
import { userChatSelectedSmdReducer } from "./reducers/userChatSelectedSmd";
import { userDetailsReducer } from "./reducers/userDetails";
import { botTemplatesReducer } from "./reducers/botTemplates";
import { botPromptSmdReducer } from "./reducers/botPromptSmd";
import { managersReducer } from "./reducers/managers";

const rootReducer = () =>
  combineReducers({
    addonsList: addonsReducer,
    activatedApis: activatedApisReducer,
    apiKeys: apiKeysReducer,
    bots: botsReducer,
    botUses: botUsesReducer,
    botNewUsers: botNewUsersReducer,
    botUsers: botUsersReducer,
    userChatSelectedSmd: userChatSelectedSmdReducer,
    user: userDetailsReducer,
    botTemplates: botTemplatesReducer,
    botPromptSmd: botPromptSmdReducer,
    managers: managersReducer,
  });

export default rootReducer;
