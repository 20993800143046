import { useState, useRef, useEffect } from "react";
import Card from "../../components/UI/Card";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import DashboardBanner from "../../components/Banner/DashboardBanner";
import styles from "./ProfilePage.module.scss";
import EditProfile from "../../components/EditProfile/EditProfile";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/constant";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { profileImgPlaceholder } from "../../utils/constant";

const ProfilePage = ({ user }) => {
  const [profileImg, setProfileImg] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef({});

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg"];
      if (!allowedTypes.includes(file.type)) {
        toast.error("Only PNG and JPEG files are allowed.");
        return;
      }
      if (file.size > 1048576) {
        toast.error("File size should be less than or equal to 1 MB.");
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setProfileImg(reader.result);
      };
    }
  };

  useEffect(() => {
    if (!!user && Object.keys(user).length > 0) {
      setProfileImg(
        !!user.profileImg ? user.profileImg : profileImgPlaceholder
      );
    }
  }, [user]);

  return (
    <>
      <Typography component={"div"}>
        <DashboardBanner image={profileImg} imageWidth={12} isRounded={true}>
          <h5>Edit profile image</h5>
          <Typography
            varient="p"
            component={"div"}
            sx={{
              font: "normal normal normal 14px/19px 'Segoe UI'",
              letterSpacing: "0px",
              color: "#3E3E3E",
            }}
          >
            You can create, copy & paste or renew API keys. Save it somewhere
            secure, or use it to connect your application right away.
          </Typography>
          <div>
            <input
              type="file"
              onChange={(e) => handleFileUpload(e)}
              style={{ display: "none" }}
              ref={(fileInput) => (fileInputRef.current = fileInput)}
            />
          </div>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              height: "40px",
              background: "#089BAE 0% 0% no-repeat padding-box",
              borderRadius: "5px",
              mt: "16px",
              py: "8px",
              "&:hover": {
                background: "#148695",
              },
            }}
            onClick={() => fileInputRef.current.click()}
          >
            Upload
          </Button>
        </DashboardBanner>
        <div className={styles.Container}>
          <Typography
            variant="p"
            component={"div"}
            sx={{
              mb: 2,
              font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
              letterSpacing: "0px",
              color: "#3E3E3E",
              ml: "12px",
            }}
          >
            Edit Details
          </Typography>
          <Card>
            <EditProfile profileImg={profileImg} />
          </Card>
          <Typography
            varient="p"
            component={"div"}
            sx={{
              letterSpacing: "0px",
              color: "#3E3E3E",
              my: "32px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                font: "normal normal normal 15px/19px 'Segoe UI'",
                textTransform: "none",
                height: "40px",
                maxWidth: "240px",
                background: "#089BAE 0% 0% no-repeat padding-box",
                borderRadius: "5px",
                // py: "8px",
                px: "18px",
                "&:hover": {
                  background: "#148695",
                },
              }}
              onClick={() => {
                navigate(ROUTES.GENERATE_API_KEYS);
              }}
            >
              Generate Api Key
            </Button>
          </Typography>
        </div>
      </Typography>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(ProfilePage);
