import React, { useState, useContext } from "react";
import { DialogModal } from "../Modal/DialogModal";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import "./style.scss";
import { Apis } from "./../../api";
import { ROUTES } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import { createJsonSmdtoNodeEdges } from "../../utils/jsonUtils";
import { FlowContext } from "../../store/flow-context";
import axios from "../../api/axios";
import { toast } from "react-toastify";

const AiCard = ({ isModalOpen, onClose, onClick }) => {
  const [prompt, setPrompt] = useState(localStorage.getItem("aiPrompt") || "");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const flowContext = useContext(FlowContext);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const res = await Apis.getSmdfromPrompt({ query: prompt });
      navigate(ROUTES.CREATE_BOT_FLOW, {
        state: {
          isPreview: true,
          isAiGenerated: true,
          name: res.id,
          description: res.description,
          avatar: res.avatar,
          isEdit: true,
        },
      });
      localStorage.setItem("flowData", JSON.stringify({ smd: res }));
      const { nodes, edges } = createJsonSmdtoNodeEdges(res);
      flowContext.setFlowData({
        nodes: nodes,
        edges: edges,
      });
      localStorage.setItem("aiPrompt", prompt);
      onClose();
      onClick();
    } catch (error) {
      toast.error(error.data.messsage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          background: "#FFFFFF",
          boxShadow: "5px 5px 10px #089BAE33",
          opacity: 1,
          borderColor: "#C8C8C8",
          minWidth: 200,
          maxWidth: 220,
          height: 200,
          transition: "box-shadow 0.2s ease",
          "&:hover": {
            boxShadow: "5px 5px 10px #089BAE33",
            border: "0.5px solid #089BAE80",
            cursor: "pointer",
          },
        }}
        className="botTemplatesCard"
      >
        <CardMedia
          component="img"
          image={
            "https://botifier-bfw.s3.ap-south-1.amazonaws.com/profileImg/87b8cd95dc01b4df45b60e354c870703.png"
          }
          alt="Bot Image"
          sx={{
            height: "80px",
            width: "100%",
            aspectRatio: "3/4",
            objectFit: "contain",
          }}
        />
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            sx={{
              textTransform: "capitalize",
              color: "#089BAE",
            }}
          >
            Chat GPT
          </Typography>
          <Typography
            variant="body2"
            component="div"
            sx={{
              color: "#3E3E3E",
              opacity: 1,
              wordWrap: "normal",
              overflowY: "auto",
              scrollbarWidth: "thin",
            }}
          >
            Create a chatbot by using chat GPT
          </Typography>
          <DialogModal open={isModalOpen} onClose={onClose} visibleBackdrop>
            <Typography component={"div"} className="container">
              <Typography
                component={"div"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  mb: "15px",
                  gap: 0.6,
                }}
              >
                <InputLabel
                  sx={{
                    textTransform: "capitalize",
                    font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                    letterSpacing: "0px",
                    color: "#3E3E3E",
                  }}
                >
                  Prompt
                </InputLabel>
                <textarea
                  className="textArea"
                  placeholder="Type here"
                  disabled={isLoading}
                  value={prompt}
                  name="prompt"
                  maxLength={300}
                  onChange={(event) => setPrompt(event.target.value)}
                //   onBlur={() => { setPrompt(prompt.trim()) }}
                />
                {isLoading ? (
                  <Typography
                    component={"div"}
                    className="CircularProgressContainer"
                  >
                    <CircularProgress className="loader" />
                  </Typography>
                ) : (
                  <Button
                    sx={{
                      textTransform: "none",
                      fontWeight: 600,
                      background: "#089BAE",
                      color: "#FCFCFC",
                      "&:hover": {
                        backgroundColor: "#0a5e69",
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                )}
              </Typography>
            </Typography>
          </DialogModal>
        </CardContent>
      </Card>
    </>
  );
};

export default AiCard;
