import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import "reactflow/dist/style.css";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { connect, useDispatch, } from "react-redux";
import { ReactFlowProvider } from "reactflow";
import Flow from "../../components/Flow";
import { createJsonFromNodeData, createJsonSmdtoNodeEdges } from "../../utils/jsonUtils";
import { FlowContext } from "../../store/flow-context";
import { Apis } from "../../api";
import { BotInfoModal } from "../../components/BotInfoModal";
import { DialogModal } from "../../components/Modal/DialogModal";
import { validateFlowData } from "../../utils/helper";
import { updateBot } from "../../routines";
import { USER_ROLE } from "../../utils/constant";
import { CircularProgress } from "@mui/material";
import './style.scss'
const BotFlowNew = ({ bots, user }) => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsloading] = useState(true);
    const flowContext = useContext(FlowContext);
    const [smdId, setSmdId] = useState(null);
    const [avatar, setAvatar] = useState(null)
    const [smdName, setSmdName] = useState('')
    const [description, setDescription] = useState(null)
    const [currentVersion, setCurrentVersion] = useState(null)
    const [versions, setVersions] = useState([])
    const [isEditBotInfo, setIsEditBotInfo] = useState(false);
    const [platform, setPlatform] = useState(null);

    const handleIsEditBotInfo = () => {
        setIsEditBotInfo(!isEditBotInfo)
    }

    const onSaveBotInfo = async (data) => {
        if (data?.smdName !== smdName) {
            if (await Apis.isSMDAlreadyExist(data.smdName));
            return toast.warning('Bot name already exist in your account!')
        }
        setSmdName(data.smdName);
        setDescription(data.description);
        setAvatar(data.avatar);
        setIsEditBotInfo(false)
    }

    const onVersionChange = async (version) => {
        const { smd, currentSmdVersion, _id } = await Apis.getBotVersions({ smdId, version, });
        const { nodes, edges } = createJsonSmdtoNodeEdges(smd.smd);
        flowContext.setFlowData({ nodes, edges, smdId: _id });
        setCurrentVersion(currentSmdVersion);
    };

    const updateHandler = () => {
        if (!validateFlowData({ nodes: flowContext.flowData.nodes, edges: flowContext.flowData.edges })) {
            return;
        } else {
            const smd = createJsonFromNodeData({
                nodes: flowContext.flowData.nodes,
                edges: flowContext.flowData.edges,
                initInfo: { name: smdName, description, avatar, variantType: 'plane' },
            })
            const payload = {
                smd_id: smdId,
                smd,
                smd_name: smdName,
                description,
                avatar,
                smdVersions: currentVersion,
                platform,
            };
            dispatch(updateBot.trigger(payload));
        }
    }

    useEffect(() => {
        if (!!params?.smdId && user?.role !== USER_ROLE.ADMIN && !user?.available_smds[params?.smdId].edit) {
            navigate(-1)
            toast.error("You don't have edit permission for this bot")
        }
    }, [user])

    useEffect(() => {
        if (bots) {
            const bot = bots.find(bot => bot._id === params.smdId)
            if (!bot) {
                toast.error("Bot doesn't exist");
            } else {
                const { smd, avatar, smdName, platform, isActive, description, currentSmdVersion, smdVersions, _id } = bot
                setSmdId(_id);
                setAvatar(avatar);
                setDescription(description);
                setCurrentVersion(currentSmdVersion);
                setSmdName(smdName)
                setPlatform(platform)
                const versionObj = smdVersions.map((version, index) => {
                    return { value: version, label: `${index + 1}` }
                })
                setVersions(versionObj)
                const { nodes, edges } = createJsonSmdtoNodeEdges(smd);
                flowContext.setFlowData({ nodes, edges, smdId: _id })
                setIsloading(false);
            }
        }
    }, [bots])

    return (<ReactFlowProvider>
        {isLoading ? <> <div
            className="loaderContainer"
        ><CircularProgress className="loader" /></div></> : <>
            <Flow
                onEditBotInfo={handleIsEditBotInfo}
                smdId={smdId}
                avatar={avatar}
                currentVersion={currentVersion}
                smdName={smdName}
                versions={versions}
                onVersionChange={onVersionChange}
                isEditPanel
                onUpdate={updateHandler} />
            <DialogModal
                open={isEditBotInfo}
                onClose={() => setIsEditBotInfo(false)}
                visibleBackdrop={false}
            >
                <BotInfoModal smdName={smdName} avatar={avatar} smdDescription={description} onSave={onSaveBotInfo} onCancel={() => setIsEditBotInfo(false)} />
            </DialogModal>
        </>}
    </ReactFlowProvider>)
};

const mapStateToProps = (state) => {
    return {
        bots: state.bots,
        user: state.user
    }
}


export default connect(mapStateToProps)(BotFlowNew);
