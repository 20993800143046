import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button } from "@mui/material";
import { DialogModal } from "../Modal/DialogModal";
import "../AddManager/style.scss";
import Permissions from "../AddManager/Permissions";
import { useDispatch } from "react-redux";
import { updateManager } from "../../routines";

const EditManager = ({ onClose, open, manager }) => {
  const [updatingManager, setUpdatingManager] = useState(false);
  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      ...manager.access,
      available_smds: manager.available_smds,
    },
    onSubmit: (values) => {
      handleUpdateManager(values);
    },
  });

  const handleUpdateManager = async ({ available_smds, ...values }) => {
    if (updatingManager) return;
    setUpdatingManager(true);

    const payload = {
      id: manager._id,
      available_smds,
      access: values,
      isActive: manager?.isActive,
    };

    dispatch(updateManager.trigger(payload));
    form.resetForm();
    onCancel();
    setUpdatingManager(false);
  };

  const onCancel = () => {
    form.resetForm();
    onClose();
  };

  useEffect(() => {
    if (open) {
      form.setValues({
        ...manager.access,
        available_smds: manager.available_smds,
      });
    }
  }, [manager, open]);

  return (
    <DialogModal visibleBackdrop onClose={onCancel} open={open}>
      {!!Object.keys(manager).length && (
        <form
          className="AddManagerModalContainer p-4"
          onSubmit={form.handleSubmit}
        >
          <p className="p-0 text-xl font-semibold text-[#089BAE] text-center">
            Edit manager
          </p>
          <div style={{ height: "512px" }} className="overflow-y-auto">
            <Permissions form={form} />
          </div>
          <div className="mt-4 flex justify-between w-full">
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                width: "fit-content",
                pl: 4,
                pr: 4,
                borderRadius: 1,
                background: "#e9ecef",
                color: "#000",
                "&:hover": {
                  background: "#b4b5b5",
                },
              }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                width: "fit-content",
                pl: 4,
                pr: 4,
                borderRadius: 1,
                background: "#089BAE",
                "&:hover": {
                  background: "#148695",
                },
              }}
              type="submit"
            >
              Update
            </Button>
          </div>
        </form>
      )}
    </DialogModal>
  );
};

export default EditManager;
