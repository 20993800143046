import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { guards } from "../../utils/constant";
import Card from "../UI/Card";
import "./AddNodeInfo.scss";
import { FlowContext } from "../../store/flow-context";
import { ListItem, ListItemText, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  FormControl,
  InputLabel,
  Select,
  Menu,
  Button,
  MenuItem,
} from "@mui/material";
import { TextField } from "@mui/material";
import { faL } from "@fortawesome/free-solid-svg-icons";

import { Mention, MentionsInput } from "react-mentions";
import { border } from "@mui/system";
import { toast } from "react-toastify";
import { maxHeight } from "@mui/system";

const Backdrop = (props) => {
  return <div className={`backdrop`} onClick={props.onBackdropClick} />;
};

const style = {
  control: {
    backgroundColor: "#fff",
    font: "normal normal normal 12px/16px 'Segoe UI'",
    color: "#3E3E3E",
    lineHeight: 2,
  },

  "&multiLine": {
    control: {
      //fontFamily: "monospace",
      maxWidth: 650,
      height: 105,
    },
    highlighter: {
      marginTop: 12,
      height: 105,
      overflowY: "hidden",
      width: "100%",
      padding: 8,
    },
    input: {
      border: "1px solid silver",
      borderRadius: 5,
      height: 105,
      width: "100%",
      overflowY: "scroll",
      padding: 8,
    },
  },
  suggestions: {
    div: {
      zIndex: 3,
    },
    list: {
      backgroundColor: "#ffff",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
      maxHeight: 200,
      overflowY: "scroll",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#089BAE80",
      },
    },
  },
};
const ModalOverlay = (props) => {
  const apis = useSelector((state) => state.activatedApis);
  const { activatedAddon } = useSelector((state) => state.addonsList);
  const [isExternalApiInputs, setIsExternalApiInputs] = useState(
    props.nodeInfo.defaultMessage === undefined ? false : true
  );
  const flowContext = useContext(FlowContext);
  const [nodeInfo, setNodeInfo] = useState(props.nodeInfo);
  const [gurdsList, setGurdList] = useState([]);
  const [isVisibleExternalApi, setIsVisibleExternalApi] = useState(false);
  const [isDisableExternalApi, setIsDisableExternalApi] = useState(false);
  const [selectedApis, setSelectedApis] = useState(
    apis.length ? apis[0] : null
  );
  const { nodeId } = props;
  const [data, setData] = useState(
    apis.length
      ? apis[0].responseKeys.map((key) => {
          return { id: apis[0]._id, display: key, name: apis[0].apiName };
        })
      : []
  );

  const formatMentionValue = (id, display) => {
    const selected = apis.find((item) => item._id === id);
    return `${selected?.apiName}.${display}`;
  };

  const handleCheckboxChange = () => {
    if (isDisableExternalApi) {
      toast.warning("External Apis are not allowed for start and end node");
    } else {
      if (isExternalApiInputs) {
        setNodeInfo((prev) => {
          return {
            ...prev,
            message: "",
            defaultMessage: undefined,
          };
        });
      } else {
        setNodeInfo((prev) => {
          return {
            ...prev,
            defaultMessage: "",
          };
        });
      }

      setIsExternalApiInputs(!isExternalApiInputs);
    }
  };
  const onNodeInfoChangeHandler = (event) => {
    if (nodeInfo.type === guards.isText && event.target.name === "type") {
      setNodeInfo((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.value,
          message: "",
          defaultMessage: undefined,
        };
      });
      setIsExternalApiInputs(false);
    } else {
      setNodeInfo((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.value,
        };
      });
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.nodeInfo.defaultMessage !== undefined)
      setIsExternalApiInputs(activatedAddon.includes("Api"));
  }, [activatedAddon, props.nodeInfo]);

  useEffect(() => {
    const filterEdges = flowContext.flowData.edges.filter(
      (element) => element.source === nodeId
    );
    if (filterEdges.length > 1) {
      setGurdList([guards.isText]);
    } else {
      let list = [];
      for (let key in guards) {
        list.push(guards[key]);
      }
      setGurdList(list);
    }
    if (
      filterEdges.length <= 1 &&
      !!activatedAddon?.find((addon) => addon === "Api") &&
      !nodeInfo.isInitialNode &&
      filterEdges.length !== 0
    ) {
      setIsVisibleExternalApi(true);
    }
    if (filterEdges.length === 0 || nodeInfo.isInitialNode) {
      setIsDisableExternalApi(true);
    }
  }, [nodeId]);

  const handleApiChange = (event) => {
    setSelectedApis(event.target.value);
    setData(
      event.target.value.responseKeys.map((key) => {
        return {
          id: event.target.value._id,
          display: key,
          name: event.target.value.apiName,
        };
      })
    );
  };
  return (
    <Card className={"addNodeInfo"}>
      <header className={"header"}>
        <Typography
          component={"div"}
          sx={{
            font: "normal normal 600 24px/28px 'Graphie, SemiBold'",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: 1,
          }}
        >
          Add State Info
        </Typography>
      </header>
      <div className={"nodeOptionsContainer"}>
        <div className={"nodeOptions"}>
          <div className={"nodeOption"}>
            <InputLabel
              sx={{
                textTransform: "capitalize",
                // color: theme.palette.primary.dark,
                font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              }}
            >
              Type:
            </InputLabel>
            <select
              className={"focus"}
              name="type"
              value={nodeInfo.type}
              onChange={onNodeInfoChangeHandler}
            >
              {gurdsList.map((item) => (
                <option>{item}</option>
              ))}
            </select>
          </div>
          {nodeInfo.type === guards.isText && isVisibleExternalApi && (
            <div className={"nodeOption"}>
              {/* <label>
                <input
                  type="checkbox"
                  checked={isExternalApiInputs}
                  onChange={handleCheckboxChange}
                  //  disabled={isDisableExternalApi}
                />
                {"Add external apis"}
              </label> */}
              <InputLabel
                sx={{
                  textTransform: "capitalize",
                  font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
                  letterSpacing: "0px",
                  color: "#3E3E3E",
                }}
              >
                <input
                  type="checkbox"
                  checked={isExternalApiInputs}
                  onChange={handleCheckboxChange}
                  //  disabled={isDisableExternalApi}
                />
                {"Add external apis"}
              </InputLabel>
            </div>
          )}
        </div>
        {isExternalApiInputs && (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
            }}
          >
            <FormControl
              size={"medium"}
              sx={{
                background: "white",
                borderRadius: 1,
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {"Activated Apis"}
              </InputLabel>
              <div onKeyDown={(e) => e.stopPropagation()}>
                <Select
                  labelId="demo-simple-select-label"
                  value={selectedApis}
                  sx={{ height: 40, width: 200 }}
                  placeholder="eeee"
                  label={"Activated Apis"}
                  onChange={handleApiChange}
                >
                  {apis.map((api) => (
                    <MenuItem value={api}>{api.apiName}</MenuItem>
                  ))}
                </Select>
              </div>
            </FormControl>
            {data.length > 0 && (
              <Typography component="div">
                <Button
                  onClick={handleMenuOpen}
                  variant="contained"
                  endIcon={<ArrowDropDownIcon />}
                  fullWidth
                  sx={{
                    minWidth: 200,
                    textTransform: "none",
                    background: "#089BAE",
                    "&:hover": {
                      background: "#148695",
                    },
                  }}
                >
                  Add Response Keys
                </Button>
                <Menu
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  PaperProps={{
                    style: {
                      minWidth: 200,
                      maxWidth: "fit-content",
                      overflowY: "scroll",
                      maxHeight: 200,
                    },
                  }}
                >
                  {data.map((responseKey) => (
                    <MenuItem onClick={handleMenuClose}>
                      <Typography
                        component={"div"}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 2,
                          width: "100%",
                        }}
                      >
                        {responseKey.display}
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            textTransform: "none",
                            borderRadius: 5,
                            background: "#089BAE",
                            "&:hover": {
                              background: "#148695",
                            },
                          }}
                          onClick={() => {
                            setNodeInfo((prev) => {
                              return {
                                ...prev,
                                message:
                                  prev.message +
                                  ` {{apiId:${responseKey.id},value:${responseKey.display}}} `,
                              };
                            });
                          }}
                        >
                          Add+
                        </Button>
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Typography>
            )}
          </Typography>
        )}

        {nodeInfo.type === guards.isText && isExternalApiInputs ? (
          <Typography>
            <MentionsInput
              placeholder="Message"
              value={nodeInfo.message}
              name="message"
              style={style}
              onChange={(e) => {
                setNodeInfo((prev) => {
                  return {
                    ...prev,
                    message: e.target.value,
                  };
                });
              }}
            >
              <Mention
                trigger={"@"}
                data={data}
                markup={"{{apiId:__id__,value:__display__}}"}
                style={{
                  display: "inline-block",
                  borderBottom: "2px solid white",
                  backgroundColor: "#089BAE80",
                }}
                displayTransform={formatMentionValue}
                appendSpaceOnAdd
              />
            </MentionsInput>
            <Typography
              varient={"body"}
              sx={{
                font: "normal normal normal 12px/16px 'Segoe UI'",
                letterSpacing: "0px",
                color: "#3E3E3E",
                mt: "5px",
                opacity: 1,
              }}
            >
              {" "}
              Press '@' to add Apis response key in message
            </Typography>
          </Typography>
        ) : (
          // <TextField
          //   name="message"
          //   id="filled-textarea"
          //   label="Message"
          //   placeholder="Message"
          //   maxRows={4}
          //   required
          //   multiline
          //   variant="outlined"
          //   value={nodeInfo.message}
          //   onChange={onNodeInfoChangeHandler}
          //   sx={{ mt: 3, color: "#151e52", minHeight: 30 }}
          //   onBlur={() => {
          //     setNodeInfo((prev) => {
          //       return {
          //         ...prev,
          //         message: nodeInfo.message.trim(),
          //       };
          //     });
          //   }}
          // />
          <TextField
            name="message"
            id="filled-textarea"
            label="Message"
            placeholder="Message"
            maxRows={4}
            required
            className="defaultMessage"
            multiline
            variant="outlined"
            value={nodeInfo.message}
            onChange={onNodeInfoChangeHandler}
            onBlur={() => {
              setNodeInfo((prev) => {
                return {
                  ...prev,
                  message: nodeInfo.message.trim(),
                };
              });
            }}
            style={{ background: "white", borderRadius: "5px" }}
            sx={{
              mt: 3,
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#089BAE",
                },
              "& .MuiInputLabel-outlined.Mui-focused": {
                color: "#089BAE",
              },
            }}
            InputProps={{
              sx: {
                font: "normal normal normal 14px/19px 'Segoe UI'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              },
            }}
          />
        )}

        {nodeInfo.type === guards.isText && isExternalApiInputs && (
          // <TextField
          //   name="defaultMessage"
          //   id="filled-textarea"
          //   label="Default Message"
          //   placeholder="Please enter default message"
          //   maxRows={2}
          //   multiline
          //   required
          //   helperText="This message will be shown to the user in case of API failure!"
          //   variant="outlined"
          //   value={nodeInfo.defaultMessage}
          //   onChange={onNodeInfoChangeHandler}
          //   sx={{ mt: 3, zIndex: 0 }}
          // />
          <TextField
            name="defaultMessage"
            id="filled-textarea"
            label="Default Message"
            placeholder="Please enter default message"
            maxRows={2}
            multiline
            required
            helperText="This message will be shown to the user in case of API failure!"
            variant="outlined"
            value={nodeInfo.defaultMessage}
            onChange={onNodeInfoChangeHandler}
            style={{ background: "white", borderRadius: "5px" }}
            sx={{
              mt: 3,
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#089BAE",
                },
              "& .MuiInputLabel-outlined.Mui-focused": {
                color: "#089BAE",
              },
              "& .MuiFormHelperText-root": {
                font: "normal normal normal 12px/16px 'Segoe UI'",
                letterSpacing: "0px",
                color: "#3E3E3E",
                ml: "0px",
                mt: "5px",
                opacity: 1,
              },
            }}
            InputProps={{
              sx: {
                font: "normal normal normal 14px/19px 'Segoe UI'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              },
            }}
          />
        )}
      </div>
      <footer className={"actions"}>
        {!!nodeInfo.type && nodeInfo.type != guards.isText && (
          <Typography
            component={"div"}
            variant="p"
            className={"message"}
            sx={{
              font: "normal normal normal 14px/19px 'Segoe UI'",
              letterSpacing: "0px",
              opacity: 1,
            }}
          >
            Only one destination node can be possible for this node !
          </Typography>
        )}
        {gurdsList.length === 1 && gurdsList[0] === guards.isText && (
          <Typography
            component={"div"}
            variant="p"
            className={"message"}
            sx={{
              font: "normal normal normal 14px/19px 'Segoe UI'",
              letterSpacing: "0px",
              opacity: 1,
            }}
          >
            In case of multiple destination, type must be "Text" only!
          </Typography>
        )}
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            width: "fit-content",
            pl: 4,
            pr: 4,
            mr: 4,
            borderRadius: 1,
            background: "#ECECEC",
            color: "#3E3E3E",
            "&:hover": {
              background: "#b4b5b5",
            },
          }}
          onClick={props.cancelNodeInfo}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            width: "fit-content",
            pl: 4,
            pr: 4,
            borderRadius: 1,
            background: "#089BAE",
            "&:hover": {
              background: "#148695",
            },
          }}
          onClick={() => {
            if (isExternalApiInputs && nodeInfo.defaultMessage === "") {
              toast.error("Please add default message");
            } else {
              props.saveNodeInfo.bind(this, nodeInfo)();
            }
          }}
        >
          Done
        </Button>
      </footer>
    </Card>
  );
};

const AddNodeInfo = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onBackdropClick={props.onBackdropClick} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          activatedAddon={props.activatedAddon}
          cancelNodeInfo={props.cancelNodeInfo}
          nodeInfo={props.nodeInfo}
          saveNodeInfo={props.saveNodeInfo}
          nodeId={props.nodeId}
        />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default AddNodeInfo;
