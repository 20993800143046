import { createRoutine } from "redux-saga-routines";
export const logOut = createRoutine("LOGOUT");
export const getAddons = createRoutine("GET_ADDONS");
export const getActivatedApis = createRoutine("GET_ACTIVATED_APIS");
export const createAPIKey = createRoutine("CREATE_API_KEY");
export const getAPIKeys = createRoutine("GET_API_KEYS");
export const deleteAPIKeys = createRoutine("DELETE_API_KEYS");
export const getBots = createRoutine("GET_BOTS");
export const getBotVersions = createRoutine("GET_BOT_VERSIONS");
export const updateBot = createRoutine("UPDATE_BOT");
export const deleteBot = createRoutine("DELETE_BOT");
export const createBot = createRoutine("CREATE_BOT");
export const getBotUses = createRoutine("GET_BOT_USES");
export const getBotNewUsers = createRoutine("GET_BOT_NEW_USERS");
export const getBotUsers = createRoutine("GET_BOT_USERS");
export const getCSVReport = createRoutine("GET_CSV_REPORT");
export const updateUserDetails = createRoutine("UPDATE_USER_DETAILS");
export const loginUsers = createRoutine("LOGIN_USERS");
export const getUser = createRoutine("GET_USER");
export const updatePassword = createRoutine("UPDATE_PASSWORD");
export const getBotTemplates = createRoutine("GET_BOT_TEMPLATES");
export const getSmdfromPrompt = createRoutine("GET_SMD_FROM_PROMPT");
export const getManagers = createRoutine("GET_MANAGERS");
export const updateManager = createRoutine("UPDATE_MANAGER");
export const deleteManager = createRoutine("DELETE_MANAGER");
export const registerManager = createRoutine("REGISTER_MANAGER");
export const setManagerDetails = createRoutine("SET_MANAGER_DETAILS");
