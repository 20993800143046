import React, { useCallback, useEffect, useMemo, useState } from "react";
import { initialNodes } from "../components/CustomNodes/customProperties";
export const FlowContext = React.createContext({
  flowData: [],
  hasDuplicateEvents: false,
  addOrUpdateEdge: (edgeData) => {},
  addOrUpdateNode: (nodeData) => {},
  clearFlowData: () => {},
  clearEditFlowData: () => {},
  deleteNodeById: (nodes) => {},
  deleteEdgeById: (edges) => {},
  updateNodePosition: (id, x, y) => {},
  setFlowData: (nodes, edges, smdId) => {},
  setHasDuplicateEvents: (value) => {},
});

export const FlowContextProvider = (props) => {
  const [flowData, setFlowData] = useState({
    nodes: [...initialNodes],
    edges: [],
  });
  const [hasDuplicateEvents, setHasDuplicateEvents] = useState(false);
  const [isKeyboardDisabled, setIsKeyboardDisabled] = useState(false);

  // const [clearFlow, setClearFlow] = useState(false);

  // useEffect(() => {
  //   if (clearFlow) {
  //     clearFlowDataInLocalStorage();
  //     setClearFlow(false);
  //   } else {
  //     updateFlowDataInLocalStorage(flowData);
  //   }
  // }, [flowData, clearFlow]);

  const addOrUpdateEdgeHandler = useCallback((edgeData) => {
    const {
      source,
      target,
      transition = null,
      isDuplicate = null,
      id,
    } = edgeData;

    setFlowData((prevData) => {
      const flowDataEdgeIndex = prevData.edges.findIndex(
        (edge) => edge.source === source && edge.target === target
      );
      if (flowDataEdgeIndex === -1) {
        prevData.edges.push({
          ...edgeData,
          data: {
            transition: edgeData.transition,
            reverseEdgeExists: edgeData.data?.reverseEdgeExists,
            isDuplicate,
          },
          id: id,
        });
      } else {
        prevData.edges[flowDataEdgeIndex].data = {
          transition,
          reverseEdgeExists: edgeData.data?.reverseEdgeExists,
          isDuplicate,
        };
        // prevData.edges[flowDataEdgeIndex].id = id
      }
      return { ...prevData };
    });
  }, []);

  const addOrUpdateNodeHandler = useCallback((nodeData) => {
    const { id, stateName, message, type } = nodeData.data;
    nodeData.position = nodeData.positionAbsolute = {
      x: nodeData.xPos || nodeData.position?.x,
      y: nodeData.yPos || nodeData.position?.y,
    };

    nodeData.selected = false;
    delete nodeData.isConnectable;
    delete nodeData.sourcePosition;
    delete nodeData.targetPosition;
    delete nodeData.zIndex;
    delete nodeData.xPos;
    delete nodeData.yPos;

    setFlowData((prevData) => {
      const flowDataNodeIndex = prevData.nodes.findIndex(
        (node) => node.data.id === id
      );
      if (flowDataNodeIndex === -1) {
        prevData.nodes.push(nodeData);
      } else {
        prevData.nodes[flowDataNodeIndex] = { ...nodeData };
        if (stateName)
          prevData.nodes[flowDataNodeIndex].data.stateName = stateName;
        if (message) prevData.nodes[flowDataNodeIndex].data.message = message;
        if (type) prevData.nodes[flowDataNodeIndex].data.type = type;
      }
      return { ...prevData };
    });
  }, []);

  const clearFlowDataHandler = useCallback(() => {
    setFlowData((prevData) => {
      return {
        nodes: [...initialNodes],
        edges: [],
      };
    });
  }, []);

  const clearEditFlowDataHandler = useCallback(() => {
    setFlowData((prevData) => {
      return {
        nodes: [...initialNodes],
        edges: [],
        smdId: prevData.smdId,
      };
    });
  }, []);

  const setFlowDataHandler = useCallback(
    ({ nodes, edges, smdId, platform }) => {
      setFlowData((prevData) => {
        return {
          nodes,
          edges,
          smdId,
          platform,
        };
      });
    },
    []
  );

  const setHasDuplicateEventsHandler = useCallback(
    ({ source, isDuplicate }) => {
      //  setFlowData(prevData=>{
      //   const flowDataNodeIndex = prevData.nodes.findIndex(
      //     (node) => node.data.id === source
      //   );
      //   prevData.nodes[findIndex].isDuplicate = isDuplicate
      //  })
      // console.log('isDuplicate',isDuplicate)
    },
    []
  );

  const updateNodePositionHandler = useCallback((id, x, y) => {
    setFlowData((prevData) => {
      const flowDataNodeIndex = prevData.nodes.findIndex(
        (node) => node.id === id
      );
      if (flowDataNodeIndex !== -1) {
        prevData.nodes[flowDataNodeIndex].position = prevData.nodes[
          flowDataNodeIndex
        ].positionAbsolute = {
          x,
          y,
        };
      }

      return { ...prevData };
    });
  }, []);

  const deleteNodeByIdHandler = useCallback(
    (node) => {
      const nodeIndex = flowData.nodes.findIndex(
        (nde) => nde.data.id === node.id
      );
      setFlowData((prevData) => {
        if (nodeIndex !== -1) {
          prevData.nodes.splice(nodeIndex, 1);
          prevData.edges = prevData.edges.filter(
            (edge) => edge.source !== node.id && edge.target !== node.id
          );
        }

        return { ...prevData };
      });
    },
    [flowData.nodes]
  );

  const deleteEdgeByIdHandler = useCallback(
    (edge) => {
      const edgeIndex = flowData.edges.findIndex((ed) => ed.id === edge.id);
      if (edgeIndex !== -1) {
        setFlowData((prevData) => {
          prevData.edges.splice(edgeIndex, 1);
          return { ...prevData };
        });
      }
    },
    [flowData.edges]
  );

  const flowContextValue = useMemo(() => {
    return {
      flowData,
      hasDuplicateEvents,
      addOrUpdateEdge: addOrUpdateEdgeHandler,
      addOrUpdateNode: addOrUpdateNodeHandler,
      clearFlowData: clearFlowDataHandler,
      deleteNodeById: deleteNodeByIdHandler,
      deleteEdgeById: deleteEdgeByIdHandler,
      updateNodePosition: updateNodePositionHandler,
      setFlowData: setFlowDataHandler,
      setHasDuplicateEvents: setHasDuplicateEventsHandler,
      isKeyboardDisabled: isKeyboardDisabled,
      setIsKeyboardDisabled: setIsKeyboardDisabled,
      clearEditFlowData: clearEditFlowDataHandler,
    };
  }, [
    flowData,
    hasDuplicateEvents,
    addOrUpdateEdgeHandler,
    addOrUpdateNodeHandler,
    clearFlowDataHandler,
    deleteNodeByIdHandler,
    deleteEdgeByIdHandler,
    updateNodePositionHandler,
    setFlowDataHandler,
    setHasDuplicateEventsHandler,
    isKeyboardDisabled,
    setIsKeyboardDisabled,
    clearEditFlowDataHandler,
  ]);
  return (
    <FlowContext.Provider value={flowContextValue}>
      {props.children}
    </FlowContext.Provider>
  );
};
