import { Grid, Typography } from "@mui/material";
import { AddonCard } from "../AddonCard/AddonCard";

const CategorizeAddOns = ({ categories }) => {
  return (
    <div>
      {categories.map((category) => (
        <Typography
          key={category.type}
          sx={{
            mb: "3rem",
          }}
        >
          <Typography
            sx={{
              font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
              letterSpacing: "0px",
              color: "#3E3E3E",
              mb: "10px",
            }}
          >
            {category.type}
          </Typography>
          <Grid
            container
            lg={12}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 3, md: 3, lg: 3 }}
            sx={{ alignContent: "center", height: "" }}
          >
            {category.addOns.map((item) => (
              <Grid key={item._id} item xs={12} sm={12} md={6} lg={4}>
                <AddonCard
                  isActivated={item.isActivated}
                  name={item.addonName}
                  description={item.shortDescription}
                  logo={item.logo}
                />
              </Grid>
            ))}
          </Grid>
        </Typography>
      ))}
    </div>
  );
};

export default CategorizeAddOns;
