import { getActionForType, getGuardForType } from "./actionUtils";
import { guards } from "./constant";

export const NO_TRANSITION_EVENT_NAME = "TRIGGER";

export function createJsonFromNodeData({
  nodes,
  edges,
  initInfo,
  variantType,
}) {
  const jsonData = {
    id: initInfo.name,
    variantType: variantType,
    description: initInfo.description,
    avatar: initInfo.avatar,
    predictableActionArguments: true,
    initial: nodes[0].data.stateName,
    context: {
      data: {},
    },
    states: {},
    events: {},
    messages: {},
    positions: {},
  };

  let emailStateExists = false;

  nodes.forEach((node) => {
    const { stateName, message, type, id, defaultMessage, isInitialNode } =
      node.data;
    jsonData.context.data[stateName] = "-";
    if (!emailStateExists && type === "Email") {
      emailStateExists = true;
    }
    jsonData.events[stateName] = [];
    jsonData.messages[stateName] = message;
    jsonData.states[stateName] = {
      isInitialNode,
      on: {},
    };
    jsonData.positions[stateName] = node.position;
    const nodeEdges = edges.filter((edge) => edge.source === id);
    nodeEdges.forEach((nodeEdge, index) => {
      const targetState = nodes.find((nde) => nde.id === nodeEdge.target);
      const eventName = !nodeEdge.data?.transition
        ? NO_TRANSITION_EVENT_NAME
        : nodeEdge.data.transition;
      jsonData.states[stateName].on[eventName] = {
        target: targetState.data.stateName,
        actions: getActionForType(type),
        cond: getGuardForType(type),
      };
      if (!!defaultMessage) {
        jsonData.states[stateName].on[eventName] = {
          ...jsonData.states[stateName].on[eventName],
          errorMessage: defaultMessage,
        };
      }
      jsonData.events[stateName].push(eventName);
    });
  });

  // add an email state if not existing
  if (!emailStateExists) {
    const emailStateName = `Email`;
    jsonData.events[emailStateName] = [NO_TRANSITION_EVENT_NAME];
    jsonData.messages[emailStateName] = "Please enter your email";
    jsonData.states[emailStateName] = {
      on: {
        [NO_TRANSITION_EVENT_NAME]: {
          actions: getActionForType("Email"),
          cond: getGuardForType("Email"),
          target: jsonData.initial,
        },
      },
    };
    jsonData.context.data[emailStateName] = "-";
    jsonData.initial = emailStateName;
  }

  // logic for final states
  Object.keys(jsonData.states).forEach((state) => {
    if (Object.keys(jsonData.states[state].on).length === 0) {
      const finalNodeData = nodes.find((node) => node.data.stateName === state);
      jsonData.states[state].on.TRIGGER = {
        cond: getGuardForType(finalNodeData.data.type),
        type: "final",
      };
      jsonData.events[state] = ["TRIGGER"];

      // jsonData.states[state].type = "final";
      // jsonData.states[state].onEntry = "storeContextToDb";
      // delete jsonData.states[state].on;
    }
  });

  return jsonData;
}

export const createJsonSmdtoNodeEdges = (smd) => {
  try {
    const smdJson = typeof smd === "string" ? JSON.parse(smd) : smd;

    const { messages, positions, states } = smdJson;

    let nodes = [];
    let edges = [];
    let nodeId = {};
    for (let key in positions) {
      nodeId[key] = Math.floor(Math.random() * 100000);
    }
    for (let key in positions) {
      const node = {
        data: {
          id: `${nodeId[key]}`,
          message: messages[key],
          stateName: key,
          type: !!states[key].type
            ? "Text"
            : guards[states[key].on[Object.keys(states[key].on)[0]].cond],
          isInitialNode: states[key].isInitialNode,
        },
        dragging: false,
        id: `${nodeId[key]}`,
        height: 40,
        width: 28,
        position: positions[key],
        positionAbsolute: positions[key],
        selected: false,
        type: "custom",
      };

      for (let eventName in states[key].on) {
        if (
          !(
            eventName === "TRIGGER" &&
            states[key].on[eventName].hasOwnProperty("type") &&
            states[key].on[eventName].type === "final"
          )
        ) {
          if (!!states[key].on[eventName].errorMessage) {
            node.data.defaultMessage = states[key].on[eventName].errorMessage;
          }

          edges.push({
            data: {
              transition: eventName === "TRIGGER" ? undefined : eventName,
            },
            id: `${nodeId[key]}${nodeId[states[key].on[eventName].target]}`,
            markerEnd: { type: "arrowclosed", width: "25px", height: "25px" },
            selected: "",
            source: `${nodeId[key]}`,
            style: { stroke: "white" },
            target: `${nodeId[states[key].on[eventName].target]}`,
            type: "buttonedge",
          });
        }
      }
      nodes.push(node);
    }
    return { nodes, edges };
  } catch (error) {
    console.log(error.message);
  }
};
