import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Typography } from "@mui/material";
import styles from "./AddOnPage.module.scss";
import { convertToTitleCase } from "../../utils/helper";
import CategorizedAddons from "../../components/CategorizedAddons";
import { connect } from "react-redux";

const AddonList = ({ addonsList }) => {
  const [addOnList, setAddOnList] = useState(null);

  const categorizeAddons = (addOns) => {
    const categories = {};
    addOns.forEach((addOn) => {
      if (addOn.category) {
        const prevAddons = categories[addOn.category] || [];
        categories[addOn.category] = [...prevAddons, addOn];
      }
    });
    return Object.keys(categories).map((category) => ({
      type: convertToTitleCase(category),
      addOns: categories[category],
    }));
  };

  useEffect(() => {
    if (!!addonsList) {
      const { activatedAddon, addons } = addonsList;
      if (activatedAddon?.length) {
        const addOnsWithBadge = addons?.map((addon) => {
          return {
            ...addon,
            isActivated: activatedAddon.includes(addon.addonName),
          };
        });
        const categoriesAddonsList = categorizeAddons(addOnsWithBadge);
        setAddOnList(categoriesAddonsList);
      } else {
        const categoriesAddonsList = categorizeAddons(addons);
        setAddOnList(categoriesAddonsList);
      }
    }
  }, [addonsList]);

  return (
    <>
      {!addOnList ? (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress className="loader" />
        </Typography>
      ) : addOnList?.length > 0 ? (
        <>
          <Typography
            sx={{
              font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
              letterSpacing: "0px",
              color: "#3E3E3E",
              mb: "2rem",
              fontSize: "1.5rem",
            }}
          >
            Integration Tools
          </Typography>
          <div className={styles.container}>
            <Typography sx={{ mb: 3 }}>
              <CategorizedAddons categories={addOnList} />
            </Typography>
          </div>
        </>
      ) : (
        <div>There is no addon available.</div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    addonsList: state.addonsList,
  };
};

export default connect(mapStateToProps)(AddonList);
