// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Thanks_thanks__ao3Xy {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  margin-top: 6rem;\n  flex-direction: column;\n  align-items: center;\n}\n.Thanks_thanks__ao3Xy p {\n  font-size: 1.2rem;\n}\n.Thanks_thanks__ao3Xy p:not(:last-child) {\n  margin: 0 0 1rem 0;\n}", "",{"version":3,"sources":["webpack://./src/components/Thanks/Thanks.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;EAEA,sBAAA;EACA,mBAAA;AAAF;AAEE;EACE,iBAAA;AAAJ;AAEI;EACE,kBAAA;AAAN","sourcesContent":[".thanks {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  margin-top: 6rem;\n  // justify-content: center;\n  flex-direction: column;\n  align-items: center;\n\n  p {\n    font-size: 1.2rem;\n\n    &:not(:last-child) {\n      margin: 0 0 1rem 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thanks": "Thanks_thanks__ao3Xy"
};
export default ___CSS_LOADER_EXPORT___;
