import {
  Typography,
  Grid,
  Button,
  Menu,
  MenuList,
  MenuItem,
  Box,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { BasicSelect } from "../../components/Select/Select";
import { AnalyticsCard } from "./analyticsCard";
import {  getLiveBots } from "../../api";
import totalChat from "../../Assets/106068.json";
import completedChat from "../../Assets/70295.json";
import liveBotUser from "../../Assets/127755.json";
import AreaChart from "../../components/AreaChart/AreaChart";
import BarChart from "../../components/BarChart/BarChart";
import { getBotUses, getBotUsers, getBotNewUsers } from "../../routines";
import { analyticsPeriod, chatPlatforms, ROUTES } from "../../utils/constant";
import { getFromDate } from "../../utils/helper";
import DataTable from "../../components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import DatesRangePicker from "../../components/DateRangePicker/DateRangePicker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material";

const UserAnalytics = ({ bots, botUses, newUsers, botUsers }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [activeIndex, setActiveIndex] = useState(0);
  const [isCustom, setIsCustom] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(analyticsPeriod.daily);
  const [startDate, setStartDate] = useState(null); //useState(moment().subtract(1, "months"));
  const [endDate, setEndDate] = useState(null); //useState(moment());
  const [smds, setSmds] = useState([]);
  const [selectedSmd, setSelectedSmd] = useState("");
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [gettingNewUsersData, setGettingNewUsersData] = useState(true);
  const [newUsersData, setNewUsersData] = useState({
    data: [],
    categories: [],
    seriseName: "New Users",
  });
  const [botUsesData, setBotUsesData] = useState({
    data: [],
    seriseName: "Users",
    categories: [],
  });
  const [liveCounts, setLiveCounts] = useState({});
  const [latestUsers, setLatestUsers] = useState([]);
  const [gettingLatestUsers, setGettingLatestUsers] = useState(false)
  const [gettingBotUses, setGettingBotUses] = useState(false)
  const columns = [
    { field: "uid", headerName: "User ID", flex: 1 },
    { field: "chatPlatform", headerName: "Platform", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
    { field: "updatedAt", headerName: "Updated At", flex: 1 },
    {
      field: "archiveChats",
      headerName: "",
      flex: 1,
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "fit-content",
              pl: 4,
              pr: 4,
              borderRadius: 1,
              background: "#089BAE",
              "&:hover": {
                background: "#148695",
              },
            }}
            onClick={() => handleButtonClick(params.row)}
          >
            Open
          </Button>
        );
      },
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const resetCustomDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleButtonClick = (param) => {
    navigate(`${ROUTES.CHATS}/${param.uid}`, {
      state: {
        botId: selectedSmd,
      },
    });
  };

  const handleDateChange = ({ startDate, endDate }) => {
    if (!!startDate && !!endDate) {
      setAnchorElUser(null);
      setSelectedPeriod(analyticsPeriod.custom);
      setGettingLatestUsers(true)
      fetchBotAndNewUsers({
        selectedPeriod: analyticsPeriod.custom,
        startDate,
        endDate,
      });
      const fromDate = moment(startDate).format("YYYY-MM-DD");
      const toDate = moment(endDate).format("YYYY-MM-DD");
      setGettingNewUsersData(true)
      dispatch(
        getBotUsers.trigger({
          id: selectedSmd,
          offset: 0,
          to: toDate,
          from: fromDate,
        })
      );
    }
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const fetchLiveBots = async (id) => {
    const response = await getLiveBots(id);
    setLiveCounts(response);
  };

  const onSelectSmd = (value) => {
    setGettingNewUsersData(true)
    setGettingLatestUsers(true)
    setSelectedSmd(value);
    fetchLiveBots(value);
    dispatch(getBotUsers.trigger({ id: value, offset: 0 }));
    const payload = {};
    payload.smdId = value;
    if (selectedPeriod !== analyticsPeriod.custom) {
      payload.duration = selectedPeriod;
      payload.numberOfResults = 10;
    } else {
      payload.from = moment(startDate).format("DD-MM-YYYY");
      payload.to = moment(endDate).format("DD-MM-YYYY");
      payload.duration = selectedPeriod;
    }
    dispatch(getBotNewUsers.trigger(payload));
  };

  const handleOpenPeriodMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    if (selectedPeriod !== analyticsPeriod.custom && !isCustom) {
      setAnchorElUser(null);
    } else {
      if (!!startDate && !!endDate) {
        setAnchorElUser(null);
      } else {
        setAnchorElUser(null);
        setIsCustom(false);
      }
    }
  };

  const fetchBotAndNewUsers = ({ selectedPeriod, startDate, endDate }) => {
    const payload = {};
    if (selectedPeriod !== analyticsPeriod.custom) {
      payload.duration = selectedPeriod;
      payload.numberOfResults = 10;
    } else {
      payload.from = moment(startDate).format("MM-DD-YYYY");
      payload.to = moment(endDate).format("MM-DD-YYYY");
      payload.duration = analyticsPeriod.custom;
    }
    setGettingBotUses(true)
    dispatch(getBotUses.trigger(payload));
    dispatch(
      getBotNewUsers.trigger({
        ...payload,
        smdId: selectedSmd,
      })
    );
  };

  useEffect(() => {
    if (botUsers.length > 0) {
      const users = botUsers.map((user) => {
        return {
          uid: user.uid,
          createdAt: getFromDate(new Date(user.createdAt)),
          chatPlatform: chatPlatforms[user.chatPlatform]
            ? chatPlatforms[user.chatPlatform]
            : user.chatPlatform,
          updatedAt: getFromDate(new Date(user.updatedAt)),
          id: user._id,
        };
      });
      setLatestUsers([...users.slice(0, 7)]);
    } else {
      setLatestUsers([]);
    }
  }, [botUsers]);

  useEffect(() => {
    if (bots?.length > 0) {
      const smdList = bots.map((bot) => {
        return {
          isActive: bot.isActive,
          name: bot.smdName,
          id: bot._id,
          smd: bot.smd,
        };
      });
      setSmds(smdList);
      setSelectedSmd(smdList[0]?.id);
      fetchLiveBots(smdList[0]?.id);
      dispatch(getBotUsers.trigger({ id: smdList[0]?.id, offset: 0 }));
    }
    const payload = {};
    payload.duration = analyticsPeriod.daily;
    payload.numberOfResults = 10;
    dispatch(getBotNewUsers.trigger(payload));
    dispatch(getBotUses.trigger(payload));
  }, [bots]);

  useEffect(() => {
    if (newUsers.length > 0) {
      const xaxis = [];
      const yaxis = [];
      let yaxisTicks = [];
      if (
        selectedPeriod === analyticsPeriod.daily ||
        selectedPeriod === analyticsPeriod.custom
      ) {
        newUsers.forEach((entry) => {
          xaxis.push(moment(entry.startDate).format("DD-MM-YYYY"));
          yaxis.push(entry.usersCount);
          if (entry.userCount > 0) {
          }
        });
        const maxDataValue = Math.max(...yaxis);
        const yAxisInterval = 20;
        const maxTick = Math.ceil(maxDataValue / yAxisInterval) * yAxisInterval;
        yaxisTicks = Array.from(
          { length: maxTick / yAxisInterval },
          (_, index) => (index + 1) * yAxisInterval
        );
      } else if (selectedPeriod === analyticsPeriod.weekliy) {
        newUsers.forEach((entry) => {
          xaxis.push(
            `${getFromDate(new Date(entry.startDate), "DDMMM")} - ${getFromDate(
              new Date(entry.endDate),
              "DDMMM"
            )}`
          );
          yaxis.push(entry.usersCount);
        });
        const maxDataValue = Math.max(...yaxis);
        const yAxisInterval = 10;
        const maxTick = Math.ceil(maxDataValue / yAxisInterval) * yAxisInterval;
        yaxisTicks = Array.from(
          { length: maxTick / yAxisInterval },
          (_, index) => (index + 1) * yAxisInterval
        );
      } else if (selectedPeriod === analyticsPeriod.monthly) {
        newUsers.forEach((entry) => {
          xaxis.push(getFromDate(new Date(entry.startDate), "month"));
          yaxis.push(entry.usersCount);
        });
        const maxDataValue = Math.max(...yaxis);
        const yAxisInterval = 10;
        const maxTick = Math.ceil(maxDataValue / yAxisInterval) * yAxisInterval;
        yaxisTicks = Array.from(
          { length: maxTick / yAxisInterval },
          (_, index) => (index + 1) * yAxisInterval
        );
      } else if (selectedPeriod === analyticsPeriod.yearly) {
        newUsers.forEach((entry) => {
          xaxis.push(getFromDate(new Date(entry.startDate), "year"));
          yaxis.push(entry.usersCount);
        });
        const maxDataValue = Math.max(...yaxis);
        const yAxisInterval = 10;
        const maxTick = Math.ceil(maxDataValue / yAxisInterval) * yAxisInterval;
        yaxisTicks = Array.from(
          { length: maxTick / yAxisInterval },
          (_, index) => (index + 1) * yAxisInterval
        );
      }
      setGettingNewUsersData(false)
      setGettingLatestUsers(false)
      setNewUsersData({
        ...newUsersData,
        yaxisTicks,
        data: [...yaxis.reverse()],
        categories: [...xaxis.reverse()],
      });
    }
  }, [newUsers]);

  useEffect(() => {
    if (botUses.length > 0) {
      const selectedIndexData = botUses[activeIndex];
      const xaxis = [];
      const yaxis = [];
      selectedIndexData?.output.forEach((entry) => {
        yaxis.push(entry.userCount);
        xaxis.push(entry.smdName);
      });
      setBotUsesData({
        ...botUsesData,
        data: yaxis,
        categories: xaxis,
      });
    }
    setGettingBotUses(false)
  }, [botUses, activeIndex]);

  const handleLatestUser = (timeFrame) => {
    let to;
    let from;

    if (timeFrame === "Daily") {
      to = moment().format("YYYY-MM-DD");
      from = moment().format("YYYY-MM-DD");
    } else if (timeFrame === "Monthly") {
      to = moment().format("YYYY-MM-DD");
      from = moment().startOf("month").format("YYYY-MM-DD");
    } else if (timeFrame === "Weekly") {
      to = moment().format("YYYY-MM-DD");
      const sevenDaysEarlier = moment(to).subtract(7, "days");
      from = sevenDaysEarlier.format("YYYY-MM-DD");
    }

    dispatch(
      getBotUsers.trigger({
        id: selectedSmd,
        offset: 0,
        to: to,
        from: from,
      })
    );
  };

  return (
    <Typography
      component={"div"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: 2,
        pb: 4,
      }}
    >
      {isMobile && <span className="analyticsText">Bot Analytics</span>}
      <Typography
        component={"div"}
        className="analyticsTextContainer"
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!isMobile && <span className="analyticsText">Bot Analytics</span>}
        <Typography
          sx={{
            display: "flex",
            gap: 1,
            width: "fit-content",
          }}
        >
          <Box>
            <Button
              variant="outlined"
              onClick={handleOpenPeriodMenu}
              endIcon={
                !!anchorElUser ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )
              }
              sx={{
                textTransform: "none",
                minWidth: 110,
                height: 40,
                color: "black",
                border: "1px solid #C8C8C8",
                outline: "10px",
                "&:hover": {
                  border: "1px solid #C8C8C8",
                },
              }}
            >
              {selectedPeriod ? selectedPeriod : "Select Period"}
            </Button>
            <Menu
              PaperProps={{
                sx: {
                  mt: 5,
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    p: 0,
                  },
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuList
                sx={{
                  minWidth: 110,
                  maxWidth: "fit-content",
                  p: 0,
                }}
              >
                <MenuItem
                  onClick={() => {
                    setSelectedPeriod(analyticsPeriod.daily);
                    resetCustomDates();
                    fetchBotAndNewUsers({
                      selectedPeriod: analyticsPeriod.daily,
                    });
                    setIsCustom(false);
                    setAnchorElUser(null);
                    handleLatestUser(analyticsPeriod.daily);
                  }}
                >
                  {analyticsPeriod.daily}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedPeriod(analyticsPeriod.weekliy);
                    resetCustomDates();
                    fetchBotAndNewUsers({
                      selectedPeriod: analyticsPeriod.weekliy,
                    });
                    handleLatestUser(analyticsPeriod.weekliy);
                    setIsCustom(false);
                    setAnchorElUser(null);
                  }}
                >
                  {analyticsPeriod.weekliy}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedPeriod(analyticsPeriod.monthly);
                    resetCustomDates();
                    fetchBotAndNewUsers({
                      selectedPeriod: analyticsPeriod.monthly,
                    });
                    setIsCustom(false);
                    setAnchorElUser(null);
                    handleLatestUser(analyticsPeriod.monthly);
                  }}
                >
                  {analyticsPeriod.monthly}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsCustom(true);
                  }}
                >
                  {analyticsPeriod.custom}
                </MenuItem>
                {isCustom && (
                  <MenuItem>
                    <DatesRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onDateChange={handleDateChange}
                      maxDate={moment()}
                      readOnly
                      isOutsideRange={(date) => date.isAfter(moment())}
                    />
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Box>
          <BasicSelect
            items={smds}
            onSelect={onSelectSmd}
            selected={selectedSmd}
            width={150}
            title={"Bots"}
            label={"Bots"}
          />
        </Typography>
      </Typography>

      <Typography
        component={"div"}
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          item
          lg={12}
          rowSpacing={3}
          columnSpacing={{ xs: 2, sm: 3, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <AnalyticsCard
              countValue={liveCounts.liveBotUser}
              animationData={liveBotUser}
              title={"Live bot users"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <AnalyticsCard
              countValue={liveCounts.completedChat}
              animationData={completedChat}
              title={"Completed chats"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <AnalyticsCard
              countValue={liveCounts.totalChats}
              animationData={totalChat}
              title={"Total chats"}
            />
          </Grid>
        </Grid>
      </Typography>
      <Typography
        component={"div"}
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography sx={{ height: 440, width: "100%" }}>
              <strong> {"New Users"}</strong>
              <AreaChart loading={gettingNewUsersData} chartData={newUsersData} height={405} />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography component={"div"} sx={{ height: 440, width: "100%" }}>
              <strong>{"Latest Users"}</strong>
              <DataTable
                loading={gettingLatestUsers}
                columns={columns}
                rows={latestUsers}
                hideFooterPagination
                hideFooter
                getRowId
                height={422}
              />
            </Typography>
          </Grid>
        </Grid>
      </Typography>
      <Typography component={"div"}>
        <strong>{"Bot Uses"}</strong>
        <BarChart loading={gettingBotUses} chartData={botUsesData} height={400} />
      </Typography>
    </Typography>
  );
};

const mapStateToProps = (state) => {
  return {
    bots: state.bots,
    botUses: state.botUses,
    newUsers: state.botNewUsers,
    botUsers: state.botUsers,
  };
};
export default connect(mapStateToProps)(UserAnalytics);
