import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import moment from "moment";
import { Typography } from "@mui/material";

import "./style.scss";
const DatePicker = ({
  onClose,
  date,
  onDateChange,
  readOnly,
  noBorder,
  placeholder,
  anchorDirection,
  horizontalMargin,
  maxDate, // moment object
}) => {
  const [focused, setFocused] = useState(null);
  const renderCustomMonthText = (month) => {
    return (
      <span style={{ color: "#089BAE" }}>
        {moment(month).format("MMMM YYYY")}
      </span>
    );
  };
  return (
    <SingleDatePicker
      date={date}
      onDateChange={onDateChange}
      onFocusChange={({ focused }) => setFocused(focused)}
      focused={focused}
      numberOfMonths={1}
      hideKeyboardShortcutsPanel
      placeholder={placeholder}
      readOnly={readOnly}
      noBorder={noBorder}
      displayFormat="DD-MMM-YYYY"
      renderMonthText={renderCustomMonthText}
      inputIconPosition="after"
      onClose={onClose}
      anchorDirection={anchorDirection} //'right' or left
      orientation="horizontal"
      horizontalMargin={horizontalMargin} // number
      isOutsideRange={!!maxDate ? (day) => day.isAfter(maxDate) : () => null}
      showDefaultInputIcon
    />
  );
};

export default DatePicker;
