import { useState, useEffect } from "react";
import { Typography, IconButton, useMediaQuery } from "@mui/material";
import { NavigationList } from "../../../components/AppNavigation/NavigationLIst";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import { ROUTES, USER_ROLE } from "../../../utils/constant";
import largeLogoIcon from "./../../../Assets/largeLogoIcon.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import PeopleIcon from "@mui/icons-material/People";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuList from "@mui/material/MenuList";
import { connect } from "react-redux";
import { handleLogOut } from "../../../utils/helper";

const drawerWidth = 245;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const Drawer = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  borderRight: "1px solid #b3b7b7",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  boxShadow: "0px 0px 5px #b3b7b7",
  transform: "unset",
  padding: 10,
  zIndex: 1,
  ...(open && {
    ...openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
  }),
}));

const DrawerHeader = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  display: "flex",
  alignItems: 'center',
  background: "#089BAE",
  flexDirection: 'row',
  borderRadius: 10,
  padding: 8,
  marginBottom: 8,
  height: 60,
  width: '100%',
  ...(open && {
    gap: 20,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
  }),
  ...(!open && {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginBottom: 8,
  }),
  // [theme.breakpoints.up("sm")]: {
  //   padding: 8,
  //   marginBottom: 8,


  // },
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${drawerWidth}px)`,
  ...(open && {
    marginLeft: drawerWidth + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: theme.spacing(10),
    width: `calc(100% - ${theme.spacing(10)})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: 0,
  },
}));

const Sidebar = ({ user }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleDrawer = () => {
    setOpen(!open);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const onLogOut = () => {
    handleLogOut();
    handleCloseUserMenu();
  };

  const [navigationList, setNavigationList] = useState([]);
  useEffect(() => {
    const navigationOption = [];
    // if (user?.role === USER_ROLE.ADMIN || user?.access?.dashboard?.read) {
    navigationOption.push({
      name: "Dashboard",
      icon: <DashboardIcon sx={{ fontSize: "1.8rem" }} />,
      path: ROUTES.DASHBOARD,
      title: "Dashboard",
      iconColor: "white",
    });
    // }
    if (user?.role === USER_ROLE.ADMIN || user?.access?.analytics?.read) {
      navigationOption.push({
        name: "Analytics",
        icon: <AnalyticsIcon sx={{ fontSize: "1.8rem" }} />,
        path: ROUTES.USER_ANALYTICS,
        title: "Analytics",
        iconColor: "white",
      });
    }
    if (user?.role === USER_ROLE.ADMIN || user?.access?.users?.read) {
      navigationOption.push({
        name: "Users",
        icon: <PeopleIcon sx={{ fontSize: "1.8rem" }} />,
        path: ROUTES.USERS,
        title: "Users",
        iconColor: "white",
      });
    }
    if (user?.role === USER_ROLE.ADMIN || user?.access?.addon?.read) {
      navigationOption.push({
        name: "Add-On",
        icon: <LibraryAddIcon sx={{ fontSize: "1.8rem" }} />,
        path: ROUTES.ADDON,
        title: "Add On",
        iconColor: "white",
      });
    }
    if (user?.role === USER_ROLE.ADMIN || user?.access?.chat?.read) {
      navigationOption.push({
        name: "Chat",
        icon: <ChatBubbleIcon sx={{ fontSize: "1.8rem" }} />,
        path: ROUTES.CHATS,
        title: "Chats",
        iconColor: "white",
      });
    }
    if (user?.role === USER_ROLE.ADMIN) {
      navigationOption.push({
        name: "Managers",
        icon: <ManageAccountsIcon sx={{ fontSize: "1.8rem" }} />,
        path: ROUTES.MANAGERS,
        title: "Managers",
        iconColor: "white",
      });
    }
    setNavigationList(navigationOption);
  }, [user]);

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: "#fcfcfc00",
          opacity: 1,
          boxShadow: "0px 3px 6px #00000021",
          backdropFilter: "blur(20px)",
          height: 77,
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        {user && (
          <Toolbar>
            {isMobile && (
              <>
                <IconButton>
                  <Typography component={"div"} onClick={handleDrawer}>
                    {/* <Typography> */}
                    <img src={largeLogoIcon} alt="logo" height={45} />
                    {/* </Typography> */}
                  </Typography>
                  <MuiDrawer
                    className="tempSidebar"
                    variant={"temporary"}
                    open={open}
                    anchor={"left"}
                    onClose={handleDrawer}
                    PaperProps={{
                      sx: {
                        color: "white",
                      },
                    }}
                  >
                    <IconButton>
                      <Typography
                        component={"div"}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 2,
                          width: drawerWidth,
                          height: 45,
                          background: "#089BAE",
                          alignItems: "center",
                          borderRadius: 2,
                          padding: 1,
                        }}
                        onClick={handleDrawer}
                      >
                        <img src={largeLogoIcon} alt="logo" height={45} />
                        {open && (
                          <Typography
                            component={"h2"}
                            sx={{
                              fontSize: 20,
                              fontWeight: 600,
                              color: "white",
                              height: 45
                            }}
                          >
                            Botifier
                          </Typography>
                        )}
                      </Typography>
                    </IconButton>
                    <NavigationList
                      items={navigationList}
                      //isDivider
                      isLink
                      isOpen={open}
                    />
                  </MuiDrawer>
                </IconButton>
              </>
            )}
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!isMobile && (
                <Typography
                  variant="div"
                  noWrap
                  href="/"
                  sx={{
                    // mt: 4,
                    font: "normal normal 600 24px/28px 'Graphie, SemiBold'",
                    letterSpacing: "0.8px",
                    color: "#3E3E3E",
                    opacity: 1,
                  }}
                >
                  Botifier
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!!user && (
                <Typography
                  sx={{
                    border: "1px solid #089BAE",
                    borderRadius: 1,
                    background: "#FFFFFF",
                    width: 210,
                    p: 0.5,
                    height: "fit-content",
                    color: "#6E6E6E",
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: 1,
                  }}
                  onClick={handleOpenUserMenu}
                >
                  <Avatar
                    alt="User"
                    src={
                      !!user.profileImg
                        ? user.profileImg
                        : "/static/images/avatar/2.jpg"
                    }
                  />
                  <Typography sx={{ width: "100%" }}>
                    {user.firstName + " " + user.lastName}
                  </Typography>
                  {!!anchorElUser ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </Typography>
              )}
              <Menu
                sx={{ mt: "50px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuList
                  sx={{ width: 210, maxWidth: "100%", outline: "none" }}
                >
                  {/* <MenuItem>
                    <ListItemIcon>
                      <ContentPaste fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Paste</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                      ⌘V
                    </Typography>
                  </MenuItem>
                  <Divider color='black'/>
                  <MenuItem>
                    <ListItemIcon>
                      <Cloud fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Web Clipboard</ListItemText>
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      navigate(ROUTES.PROFILE_PAGE);
                      handleCloseUserMenu();
                    }}
                  >
                    <ListItemText>Profile</ListItemText>
                    <Typography variant="body2">
                      <AccountCircleIcon
                        sx={{ color: "#6E6E6E" }}
                        fontSize="small"
                      />
                    </Typography>
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      navigate(ROUTES.GENERATE_API_KEYS);
                      handleCloseUserMenu()
                    }}
                  >
                    <ListItemText>Settings</ListItemText>
                    <Typography variant="body2">
                      <ApiIcon
                        sx={{  color: "#6E6E6E" }}
                        fontSize="small"
                      />
                    </Typography>
                  </MenuItem> */}
                  <MenuItem onClick={onLogOut}>
                    <ListItemText>
                      Log Out
                    </ListItemText>
                    <Typography variant="body2" color="text.secondary">
                      <LogoutIcon
                        sx={{ transform: `rotate(180deg)`, color: "#6E6E6E" }}
                        fontSize="small"
                      />
                    </Typography>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Toolbar>
        )}
      </AppBar>
      {!isMobile && (
        <Drawer
          variant="permanent"
          open={open}
        >
          <DrawerHeader onClick={handleDrawer} open={open} >
            <img src={largeLogoIcon} alt="logo" height={45} />
            {open && (
              <Typography
                component={"h2"}
                sx={{ fontSize: 20, fontWeight: 600, color: "white" }}
              >
                Botifier
              </Typography>
            )}
          </DrawerHeader>
          {!!user && (
            <NavigationList
              items={navigationList}
              //isDivider
              isLink
              isOpen={open}
            />
          )}
        </Drawer>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Sidebar);
