import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import HeaderIcon from "../../Assets/images/headerIcon.png";
import { regularCharValidation } from "../../utils/helper";
import { useDispatch } from "react-redux";
import { setManagerDetails } from "../../routines";
import {
  PASSWORD_MAXIMUM_LENGTH,
  PASSWORD_MINIMUM_LENGTH,
  ROUTES,
} from "../../utils/constant";
import "./style.scss";
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    textTransform: "none",
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "red",
    color: "white",
  },
  signUpTypography: {
    color: theme.palette.secondary.main,
  },
}));

const SetDetailsForm = ({ email, code, logos }) => {
  const plusIcon = "/icons-plus.svg";
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const classes = useStyles();
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [selectedLogo, setSelectedLogo] = useState();
  const [uploadedLogo, setUplodedLogo] = useState(null);

  useEffect(() => {
    setSelectedLogo(logos[0].image);
    const initialAvatar = logos[0];
    setAvatars([{ ...initialAvatar, selected: true }, ...logos.slice(1)]);
  }, []);

  const dispatch = useDispatch();
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value.trim());
    setIsValidFirstName(regularCharValidation(event.target.value));
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value.trim());
    setIsValidLastName(regularCharValidation(event.target.value));
  };

  const validateData = () => {
    if (!isValidFirstName || !isValidLastName) {
      toast.error("Name fields should contain regular characters only");
      return false;
    }
    if (firstName.length < 1) {
      toast.error("Please enter your first name");
      return false;
    }
    if (lastName.length < 1) {
      toast.error("Please enter your first name");
      return false;
    }
    if (password1 !== password2) {
      toast.error("Passwords do not match.Please try again");
      return false;
    }
    if (
      password1.length < PASSWORD_MINIMUM_LENGTH ||
      password2.length < PASSWORD_MINIMUM_LENGTH
    ) {
      toast.warning("Password should be 8 to 15 character long");
      return false;
    }

    return true;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateData()) {
      return;
    }
    const payload = {
      firstName,
      lastName,
      email,
      password: password1,
      code: code,
      profileImg: selectedLogo,
    };
    dispatch(setManagerDetails.trigger(payload));
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSelectAvatar = (index) => {
    setSelectedLogo(avatars[index].image);
    setAvatars([
      ...logos.slice(0, index),
      { ...logos[index], selected: true },
      ...logos.slice(index + 1),
    ]);
    if (uploadedLogo?.selected) {
      setUplodedLogo((prev) => ({ ...prev, selected: false }));
    }
  };

  const handleClickOnUploadedImage = () => {
    setSelectedLogo(uploadedLogo.image);
    setUplodedLogo((prev) => ({
      ...prev,
      selected: true,
    }));
    setAvatars(logos);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg"];
      if (!allowedTypes.includes(file.type)) {
        toast.error("Only PNG and JPEG files are allowed.");
        return;
      }
      if (file.size > 1048576) {
        toast.error("File size should be less than or equal to 1 MB.");
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setUplodedLogo({
          image: reader.result,
          selected: true,
        });
        setSelectedLogo(reader.result);
        setAvatars((prevState) => {
          return prevState.map((avatar) => ({
            ...avatar,
            selected: false,
          }));
        });
      };
    }
  };

  return (
    <div className={"detailsFormContainer"}>
      <div className={"upperSection"}>
        <div className={"botifier"}>
          <img src={HeaderIcon} className={"headerIcon"} alt="header_icon" />
          <span>Botifier</span>
        </div>
        <div className="description"> Please enter your details</div>
      </div>
      <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className={"avatarContainer"}>
          <div className="selectedAvatar">
            <img
              className={"SelectedAvatarLogo"}
              src={selectedLogo}
              alt="avatar"
            />
          </div>
          <div className="avatarOptions">
            {avatars.map((avatar, index) => {
              return (
                <img
                  key={index}
                  className={`${"avatarOption"} ${avatar.selected ? "selected" : ""
                    }`}
                  src={avatar.image}
                  alt="Avatar"
                  onClick={() => handleSelectAvatar(index)}
                />
              );
            })}
            {!!uploadedLogo && (
              <img
                key={avatars.length}
                className={`${"avatarButton"} ${uploadedLogo.selected ? "selected" : ""
                  }`}
                src={uploadedLogo.image}
                alt="Avatar"
                onClick={handleClickOnUploadedImage}
              />
            )}
            <>
              <input
                type="file"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <img
                className={"avatarButton"}
                onClick={() => fileInputRef.current.click()}
                src={plusIcon}
                alt="Upload"
              />
            </>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              error={!isValidFirstName}
              autoFocus
              className={"inputElement"}
              value={firstName}
              onChange={handleFirstNameChange}
              style={{ background: "white", borderRadius: "5px" }}
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#089BAE", // Apply your custom border color here
                },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "#089BAE", // Apply your custom label color here
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              type="text"
              autoComplete="off"
              className={"inputElement"}
              value={lastName}
              error={!isValidLastName}
              onChange={handleLastNameChange}
              style={{ background: "white", borderRadius: "5px" }}
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#089BAE", // Apply your custom border color here
                },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "#089BAE", // Apply your custom label color here
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              sx={{ width: "100%", backgroundColor: "white" }}
              variant="outlined"
              className={"inputElement"}
              autoComplete="off"
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{
                  "&.Mui-focused": {
                    color: "#089BAE",
                  },
                }}
              >
                Email Address
              </InputLabel>
              <OutlinedInput
                required
                fullWidth
                id="email"
                type="email"
                label="Email Address"
                disabled
                className={"userNameInput"}
                value={email}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#089BAE",
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              sx={{ width: "100%", backgroundColor: "white" }}
              className={"inputElement"}
            >
              <InputLabel
                sx={{
                  "&.Mui-focused": {
                    color: "#089BAE",
                  },
                }}
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword1 ? "text" : "password"}
                className={"userNameInput"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword1(!showPassword1)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                maxLength={12}
                autoComplete="off"
                value={password1}
                onChange={(e) => {
                  if (e.target.value.trim().length <= PASSWORD_MAXIMUM_LENGTH) {
                    setPassword1(e.target.value.trim());
                  }
                }}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#089BAE",
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              sx={{ width: "100%", backgroundColor: "white" }}
              className={"inputElement"}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{
                  "&.Mui-focused": {
                    color: "#089BAE",
                  },
                }}
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword2 ? "text" : "password"}
                className={"userNameInput"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword2(!showPassword2)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
                autoComplete="off"
                value={password2}
                onChange={(e) => {
                  if (e.target.value.trim().length <= PASSWORD_MAXIMUM_LENGTH) {
                    setPassword2(e.target.value.trim());
                  }
                }}
                error={!password2.length > 8 && !password1 === password2}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#089BAE",
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          style={{ backgroundColor: "#089BAE", color: "#ffff" }}
        >
          Submit
        </Button>
        <Grid item>
          <span
            className={"click_here"}
            onClick={() => {
              navigate(ROUTES.LOG_IN);
            }}
          >
            Go to the sign-in page if already registered.
          </span>
        </Grid>
      </form>
    </div>
  );
};

export default SetDetailsForm;
