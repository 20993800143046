import { useSelector } from "react-redux";
import Select from "react-select";

const fieldsObj = [
  {
    label: "Read",
    valueFrom: "read",
  },
  {
    label: "Edit",
    valueFrom: "edit",
  },
  {
    label: "Add",
    valueFrom: "add",
  },
  {
    label: "Delete",
    valueFrom: "delete",
  },
];

const fieldTypes = {
  checkbox: "checkbox",
  multiselect: "multiselect",
};

const fields = [
  {
    type: fieldTypes.checkbox,
    title: "Dashboard",
    value: "dashboard",
    fields: fieldsObj,
  },
  {
    type: fieldTypes.multiselect,
    title: "Available Bots",
    value: "available_smds",
    fields: fieldsObj,
  },
  {
    type: fieldTypes.checkbox,
    title: "Analytics",
    value: "analytics",
    fields: fieldsObj,
  },
  {
    type: fieldTypes.checkbox,
    title: "Addon",
    value: "addon",
    fields: fieldsObj,
  },
  {
    type: fieldTypes.checkbox,
    title: "Users",
    value: "users",
    fields: fieldsObj,
  },
  {
    type: fieldTypes.checkbox,
    title: "Chat",
    value: "chat",
    fields: fieldsObj,
  },
];

const CheckBoxFieldCard = ({ field, form }) => {
  return (
    <div>
      <p className="font-semibold rounded-t-lg text-gray-700 text-lg border border-bottom-0 px-2 py-2 mb-0">
        {field.title}
      </p>
      <div className="bg-gray-100 rounded-b-lg p-2 shadow-sm border border-top-0 mb-4">
        <div className="flex flex-wrap gap-4">
          {field.fields.map((checkbox) => {
            return (
              <div key={checkbox.valueFrom}>
                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded"
                    name={`${field.value}.${checkbox.valueFrom}`}
                    onChange={form.handleChange}
                    checked={form.values[field.value]?.[checkbox.valueFrom]}
                  />
                  <span className="text-xs font-semibold text-gray-700">
                    {checkbox.label}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const AvailableSmds = ({ form, field, bots }) => {
  const options = bots.map((bot) => ({
    label: bot.smdName,
    value: bot._id,
  }));

  const selectedBots = Object.keys(form.values.available_smds).map((key) => ({
    label: bots.find((bot) => bot._id === key).smdName,
    value: key,
  }));

  const onSelectChange = (selectedOptions) => {
    const selectedBots = {}
    selectedOptions.forEach(bot => {
      if (!!form?.values?.available_smds[bot.value]) {
        selectedBots[bot.value] = form.values.available_smds[bot.value]
      } else {
        selectedBots[bot.value] = { read: true, edit: true, add: true, delete: true }
      }
    });
    form.setFieldValue("available_smds", selectedBots);
  };

  return (
    <div>
      <p className="font-semibold rounded-t-lg text-gray-700 text-lg border border-bottom-0 px-2 py-2 mb-0">
        {field.title}
      </p>
      <div className="bg-gray-100 rounded-b-lg p-2 shadow-sm border border-top-0 mb-4">
        <Select
          closeMenuOnSelect={false}
          value={selectedBots}
          isMulti
          options={options}
          onChange={onSelectChange}
          required
        />
        {Object.keys(form.values[field.value]).map((key) => {
          const data = form.values[field.value][key];
          const permissions = Object.keys(data).map((key) => {
            return { label: key, value: data[key] };
          });

          return (
            <div key={key} className="mt-2 bg-white rounded-lg p-2">
              <p className="font-semibold text-gray-700 mb-0">
                {bots.find((bot) => bot._id === key).smdName}
              </p>
              <div className="flex gap-4">
                {permissions.map((checkbox) => {
                  return (
                    <div key={checkbox.label}>
                      <div className="flex items-center gap-1">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                          name={`${field.value}.${key}.${checkbox.label}`}
                          onChange={form.handleChange}
                          checked={checkbox.value}
                        />
                        <span className="text-xs font-semibold text-gray-700">
                          {checkbox.label}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Permissions = ({ form }) => {
  const bots = useSelector((state) => state.bots);

  return (
    <div>
      {fields.map((field) => {
        if (field.type === fieldTypes.checkbox) {
          return (
            <CheckBoxFieldCard key={field.value} form={form} field={field} />
          );
        } else if (field.type === fieldTypes.multiselect) {
          return (
            <AvailableSmds
              bots={bots}
              key={field.value}
              form={form}
              field={field}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default Permissions;
