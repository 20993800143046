import { toast } from "react-toastify";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  updateUserDetails,
  loginUsers,
  getUser,
  updatePassword,
  logOut,
  getAPIKeys,
  getBots,
  getAddons,
  getActivatedApis,
  getManagers,
  getBotTemplates,
} from "../routines";
import { Apis } from "./../api"; // Replace with your actual API function
import { ROUTES, } from "../utils/constant";
import { setDataInLocalStorage } from "../utils/helper";

function* updateUserDetailsSaga({ payload }) {
  try {
    yield put(updateUserDetails.request());
    const response = yield call(Apis.updateUserDetails, payload);
    yield put(updateUserDetails.success());
    yield put(getUser.trigger());
    toast.success(response.message);
  } catch (error) {
    toast.error(error.message);
    yield put(updateUserDetails.failure(error.message));
  } finally {
    yield put(updateUserDetails.fulfill());
  }
}

function* logOutUsersSaga() {
  try {
    yield put(logOut.request());
    yield put(getUser.success());
    yield put(getAPIKeys.success());
    yield put(getBots.success());
    yield put(getAddons.success());
    yield put(getActivatedApis.success());
    yield put(getBotTemplates.success());
    yield put(getManagers.success());
    yield put(logOut.success());
  } catch (error) {
    toast.error(error.message);
    yield put(logOut.failure(error.message));
  } finally {
    yield put(logOut.fulfill());
  }
}
function* loginUsersSaga({ payload }) {
  try {
    yield put(loginUsers.request());
    const response = yield call(Apis.login, payload);
    yield put(loginUsers.success());
    setDataInLocalStorage("authToken", response.token);
    window.location.replace(ROUTES.DASHBOARD);
  } catch (error) {
    toast.error(error.message);
    yield put(loginUsers.failure(error.message));
  } finally {
    yield put(loginUsers.fulfill());
  }
}

function* userSaga() {
  try {
    yield put(getUser.request());
    const response = yield call(Apis.getUser);
    localStorage.setItem('userRole', response?.role)
    yield put(getUser.success(response));
  } catch (error) {
    toast.error(error.message);
    yield put(getUser.failure(error.message));
  } finally {
    yield put(getUser.fulfill());
  }
}

function* updatePasswordSaga({ payload }) {
  try {
    yield put(updatePassword.request());
    const response = yield call(Apis.updatePassword, payload);
    window.location.replace(`${ROUTES.RESET_PASSWORD}?token=${response}`);
    yield put(updatePassword.success());
  } catch (error) {
    toast.error(error.message);
    yield put(updatePassword.failure(error.message));
  } finally {
    yield put(updatePassword.fulfill());
  }
}

export function* watchAdminSaga() {
  yield takeLatest(updateUserDetails.TRIGGER, updateUserDetailsSaga);
  yield takeLatest(loginUsers.TRIGGER, loginUsersSaga);
  yield takeLatest(getUser.TRIGGER, userSaga);
  yield takeLatest(updatePassword.TRIGGER, updatePasswordSaga);
  yield takeLatest(logOut.TRIGGER, logOutUsersSaga);
}
