import { ROUTES } from "./utils/constant";
import AdminDashboard from "./pages/Dashboard/AdminDashboard";
import { Bot } from "./components/Flow/Bot";
import { BotFlow } from "./components/Flow/Flow";
import SignIn from "./pages/Login/SignIn";
import SignUpForm from "./pages/SignUpForm/SignUpForm";
import UserAnalytics from "./pages/Analytics/UserAnalytics";
import Users from "./pages/Users/Users";
import Chats from "./pages/Chats/Chats";
import AddonList from "./pages/AddOnList/AddOnList";
import AddonDetails from "./pages/AddonDetails/AddonDetails";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { Thanks } from "./components/Thanks/Thanks";
import GenerateApiKeys from "./pages/GenerateApiKeys";
import ProfilePage from "./pages/Profile/ProfilePage";
import Managers from "./pages/Managers/Managers";
import { Navigate } from "react-router-dom";
import SetManagerDetails from "./pages/SetManagerDetails";
import BotFlowNew from "./pages/BotFlow";

export const commonRoutesWithoutAccess = [
  {
    path: ROUTES.ADMIN,
    name: "Admin",
    element: <Navigate to={ROUTES.DASHBOARD} replace />,
  },
  {
    path: ROUTES.EDIT_BOT_FLOW,
    name: "Edit Bot Flow",
    isChild: true,
    element: <BotFlowNew />,
  },
  {
    path: ROUTES.DASHBOARD,
    name: "Dashboard",
    element: <AdminDashboard />,
  },
];
export const publicRoutes = [
  {
    path: ROUTES.BOT,
    name: "Bot",
    element: <Bot />,
  },
  {
    path: ROUTES.SET_MANAGER_DETAILS,
    name: "SetDetails",
    element: <SetManagerDetails />,
  },
];

export const authRoutes = [
  {
    path: ROUTES.LOG_IN,
    name: "Login",
    element: <SignIn />,
  },
  {
    path: ROUTES.SIGN_UP,
    name: "Signup",
    element: <SignUpForm />,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    name: "ResetPassword",
    element: <ResetPassword />,
  },

  {
    path: ROUTES.THANKS,
    name: "thanks",
    element: <Thanks />,
  },
];

export const dashboardRoutes = [
  {
    path: ROUTES.DASHBOARD,
    name: "Dashboard",
    element: <AdminDashboard />,
  },
];
export const analyticsRoutes = [
  {
    path: ROUTES.USER_ANALYTICS,
    name: "analytics",
    element: <UserAnalytics />,
  },
];

export const usersRoutes = [
  {
    path: ROUTES.USERS,
    name: "users",
    element: <Users />,
  },
];
export const addonRoutes = [
  {
    path: ROUTES.ADDON,
    name: "addon",
    element: <AddonList />,
  },
  {
    path: ROUTES.ADDON_DETAILS,
    name: "addon_details",
    element: <AddonDetails />,
  },
];
export const managerRoutes = [
  {
    path: ROUTES.MANAGERS,
    name: "managerPage",
    element: <Managers />,
  },
];

export const profileRoutes = [
  {
    path: ROUTES.PROFILE_PAGE,
    name: "profilePage",
    element: <ProfilePage />,
  },
];

export const chatsRoutes = [
  {
    path: ROUTES.CHAT,
    name: "user_chats",
    element: <Chats />,
  },
  {
    path: ROUTES.CHATS,
    name: "Chats",
    element: <Chats />,
  },
];
export const botFlowCreateRoute = [
  {
    path: ROUTES.CREATE_BOT_FLOW,
    name: "Create Bot Flow",
    element: <BotFlow />,
  },
];
export const generateApiKeysRoutes = [
  {
    path: ROUTES.GENERATE_API_KEYS,
    name: "Generate_Api_Keys",
    element: <GenerateApiKeys />,
  },
];
