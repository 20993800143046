import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./style.scss";
import Spinner from "../Spinner";

const BarChart = ({ chartData, loading }) => {
  const [seriesData, setSeriesData] = useState([]);
  const [xAxisCategories, setXAxisCategories] = useState([]);
  useEffect(() => {
    setSeriesData([
      {
        name: chartData.seriseName,
        data: chartData?.data,
      },
    ]);
    setXAxisCategories(chartData.categories);
    //setYaxisTicks(chartData.yaxisTicks);
  }, [chartData]);

  const options = {
    chart: {
      height: 350,
      type: "bar",
      zoom: false,
      toolbar: {
        show: false, // Disable toolbar (including the menu button)
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      colors: ["#089BAE", "#a9d9df00"], // Change the area background colors
    },
    xaxis: {
      // type: "datetime",
      categories: xAxisCategories,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    plotOptions: {
      bar: {
        borderRadious: 5, // Display bars horizontally
        fillOpacity: 0.7,
        columnWidth: 30, // Adjust the bar width as a percentage
      },
    },
  };

  return (
    <Typography component={"div"}>
      {loading && <div className="h-full pt-48 w-full flex items-center justify-center"><Spinner text="Loading..." /></div>}
      {!loading && <ReactApexChart
        options={options}
        series={seriesData}
        type="bar"
        height={350}
        className="chart-container"
      />}
    </Typography>
  );
};

export default BarChart;
