import { useEffect, useState } from "react";
import { Button, CardMedia, CircularProgress, InputLabel } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./AddonDetails.module.scss";
import { getAddons } from "../../routines";
import Carousel from "react-material-ui-carousel";
import {
  getBotAddonDetails,
  addBotAddon,
  removeAllBotAddon,
  removeBotAddon,
} from "../../api";
import { DialogModal } from "../../components/Modal/DialogModal";
import { ADDON_NAME_KEY, ROUTES } from "../../utils/constant";
import { theme } from "../../utils/theme";
import { copyContent } from "../../utils/helper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FacebookAddonDetails from "../../components/Addons/FacebookAddonDetails/FacebookAddonDetails";
import WhatsappAddonDetails from "../../components/Addons/WhatsappAddonDetails/WhatsappAddonDetails";
import QrCodeAddonDetails from "../../components/Addons/QrCodeAddonDetails/QrCodeAddonDetails.js";
import TelegramAddonDetails from "../../components/Addons/TelegramAddonDetails/TelegramAddonDetails.js";
import PluginAddonDetails from "../../components/Addons/PluginAddonDetails/PluginAddonDetails.js.js";
import ZappierAddonDetails from "../../components/Addons/ZappierAddonDetails/ZappierAddonDetails.js";
import FreshserviceAddonDetails from "../../components/Addons/FreshserviceAddonDetails/FreshserviceAddonDetails.js";
import MailchimpAddonDetails from "../../components/Addons/MailchimpAddonDetails/MailchimpAddonDetails.js";
import SalesforceAddonDetails from "../../components/Addons/SalesforceAddonDetails/SalesforceAddonDetails.js";
import ApiAddonDetails from "../../components/Addons/ApiAddonDetails/ApiAddonDetails.js";

const AddonDetails = ({ bots }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allSmd, setAllSmd] = useState([]);
  const [addonData, setAddonData] = useState(null);
  const { addonName } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [openDetailForm, setOpenDetailForm] = useState(false);
  const [isPreviewImage, setIsPreviewImage] = useState(false);
  const [images, setImages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedSmd, setSelectedSmd] = useState("");
  const [smdList, setSmdList] = useState([]);
  const [isBotSelect, setIsBotSelect] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [inputs, setInputs] = useState({});
  const [successData, setSuccessData] = useState(null);
  const [activatedSmd, setActivatedSmd] = useState([]);
  const [isDeactivateAllConfirmation, setIsDeactivateAllConfirmation] =
    useState(false);
  const [isConfigure, setIsConfigure] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const addonComponentNames = {
    Facebook: FacebookAddonDetails,
    Whatsapp: WhatsappAddonDetails,
    QRCode: QrCodeAddonDetails,
    Telegram: TelegramAddonDetails,
    Plugin: PluginAddonDetails,
    Zapier: ZappierAddonDetails,
    freshservice: FreshserviceAddonDetails,
    Mailchimp: MailchimpAddonDetails,
    Salesforce: SalesforceAddonDetails,
    Api: ApiAddonDetails,
  };

  const CurrentAddonDetails = addonComponentNames[addonName];

  const validate = () => {
    if (!selectedSmd) {
      setErrorMessage("Please select your bot");
      setIsValid(false);
      return false;
    }

    for (let item of inputList) {
      if (!inputs[item.fieldName] || !inputs[item.fieldName]?.value) {
        setErrorMessage(`Please enter ${item.label}`);
        setIsValid(false);
        return false;
      }
    }
    return true;
  };

  const handleDeactivateAllConfirmation = () =>
    setIsDeactivateAllConfirmation(!isDeactivateAllConfirmation);

  const handleBack = () => {
    navigate(ROUTES.ADDON);
  };

  const onSelectSmd = (value) => {
    setSelectedSmd(value);
  };

  const filterSmd = () => {
    if (!!allSmd.length && !!addonData) {
      const filterSMD = allSmd.filter(
        (item) => !addonData.smd.find((smd) => smd._id === item.id)
      );
      if (!filterSMD.length) {
        setIsConfigure(false);
      } else {
        setIsConfigure(true);
      }
      setSmdList(filterSMD);
    }
  };

  const handleOpenDetailForm = (apiIndex) => {
    if (openDetailForm) {
      setIsBotSelect(false);
      setInputs({});
      setSelectedSmd("");
    }
    filterSmd();
    setOpenDetailForm(!openDetailForm);
  };

  const fetchAddonData = async () => {
    try {
      const response = await getBotAddonDetails({ name: addonName });
      setAddonData(response);
      setActivatedSmd(response.smd);
      let temp = [];
      for (let key in response.input) {
        if (response.input[key].visible) {
          temp.push({
            ...response.input[key],
            fieldName: key,
            label: key.split("_").join(" "),
          });
        }
      }
      setInputList([...temp]);
      const img = response.gallery.map((item) => item.url);
      setImages(img);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.data.messsage);
      setIsLoading(false);
    }
  };

  const deactivateAllAddon = async () => {
    try {
      await removeAllBotAddon({ addon: addonData.addonName });
      fetchAddonData();
      dispatch(getAddons.trigger());
      toast.success("Addon Deactivated");
      handleDeactivateAllConfirmation();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deactivateAddon = async (smdId) => {
    try {
      const response = await removeBotAddon({
        addon: addonData.addonName,
        smdId,
      });
      toast.success(response.message);
      fetchAddonData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  async function fetchSmd() {
    try {
      const listofSmd = bots.map((bot) => {
        return {
          isActive: bot.isActive,
          name: bot.smdName,
          id: bot._id,
          smd: bot.smd,
          platform: bot.platform,
        };
      });
      setAllSmd(listofSmd);
    } catch (error) {
      console.log("Error on addon details while fetching smd", error);
    }
  }
  useEffect(() => {
    if (!!bots?.length) {
      fetchAddonData();
      fetchSmd();
    }
  }, [bots]);

  useEffect(() => {
    filterSmd();
  }, [addonData, allSmd]);

  useEffect(() => {
    setIsValid(true);
  }, [inputs]);

  const onSubmit = async () => {
    try {
      let url = addonData.apiPath;

      if (selectedSmd === "") {
        toast.error("Please select the bot");
      }
      let body = {};
      url = url + selectedSmd;
      for (let key in inputs) {
        if (inputs[key].in === "body") {
          body[key] = inputs[key].value;
        }
        if (inputs[key].in === "params") {
          url += inputs[key].value;
        }
      }
      const response = await addBotAddon({ url, body });
      fetchAddonData();
      toast.success("Addon added successfully");
      return response;
    } catch (error) {
      toast.error(error?.message || "Error occured during addon activation");
      console.log(error);
    }
  };

  const replaceLink = (text) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    return text.replace(regex, '<a href="$&" target="_blank">$&</a>');
  };

  const handleEdit = (smd) => {
    setSelectedSmd(smd._id);
    let temp = {};
    for (let key in addonData.input) {
      if (!!smd.platform[ADDON_NAME_KEY[addonData.addonName]][key]) {
        temp[key] = {
          value: smd.platform[ADDON_NAME_KEY[addonData.addonName]][key],
          in: addonData.input[key].in,
        };
      }
    }
    setInputs({ ...temp });
    setIsBotSelect(true);
    handleOpenDetailForm();
    setSmdList([{ id: smd._id, name: smd.smdName }]);
    return smd;
  };

  return (
    <>
      {isLoading && (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress className="loader" />
        </Typography>
      )}
      {!!addonData ? (
        <>
          <Typography
            component={"div"}
            onClick={handleBack}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              mb: "20px",
            }}
          >
            <ArrowBackIcon />
            <Typography
              sx={{
                font: "normal normal 600 24px/28px 'Graphie, SemiBold'",
                letterSpacing: "0px",
                color: "#3E3E3E",
              }}
            >
              Add-ons
            </Typography>
          </Typography>
          <Typography
            component={"div"}
            sx={{
              display: "flex",
            }}
          >
            <CardMedia
              component="img"
              image={addonData.logo}
              sx={{
                borderRadius: "5px",
                //height: "100%",
                width: "129px",
                height: "129px",
                mr: 2,
              }}
            />
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  font: "normal normal 600 20px/24px 'Graphie, SemiBold'",
                  letterSpacing: "0px",
                  color: "#089BAE",
                  mb: "10px",
                }}
              >
                {addonData.addonName}
              </Typography>
              <Typography
                sx={{
                  font: "normal normal normal 14px/18px 'Segoe UI'",
                  letterSpacing: "0px",
                  color: "#3E3E3E",
                  mb: "13px",
                  height: "48px",
                }}
              >
                {addonData.shortDescription}
                Add-ons
              </Typography>
            </Typography>
          </Typography>
          <Typography
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              pl: "20px",
              pr: "20px",
              pt: 5,
              pb: 5,
            }}
          >
            <CurrentAddonDetails
              deactivateAddon={deactivateAddon}
              setSmdList={setSmdList}
              activatedSmd={activatedSmd}
              isConfigure={isConfigure}
              handleDeactivateAllConfirmation={handleDeactivateAllConfirmation}
              addonData={addonData}
              smdList={smdList}
              isBotSelect={isBotSelect}
              setIsBotSelect={setIsBotSelect}
              selectedSmd={selectedSmd}
              openDetailForm={openDetailForm}
              setOpenDetailForm={setOpenDetailForm}
              setSelectedSmd={setSelectedSmd}
              commonHandleOpenDetailForm={handleOpenDetailForm}
              inputList={inputList}
              commonOnSelectSmd={onSelectSmd}
              inputs={inputs}
              setInputs={setInputs}
              commonOnSubmit={onSubmit}
              addBotAddon={addBotAddon}
              fetchAddonData={fetchAddonData}
              setSuccessData={setSuccessData}
              isValid={isValid}
              setIsValid={setIsValid}
              commonValidate={validate}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              commonDeactivateAllAddon={deactivateAllAddon}
              isDeactivateAllConfirmation={isDeactivateAllConfirmation}
              allSmd={allSmd}
              commonHandleEdit={handleEdit}
            />
            <Typography
              component={"div"}
              variant="h4"
              sx={{
                font: "normal normal 600 20px/24px 'Graphie, SemiBold'",
                letterSpacing: "0px",
                color: "#3E3E3E",
                mb: "10px",
              }}
            >
              Gallery
            </Typography>
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Grid
                container
                sx={{
                  alignContent: "flex-start",
                  direction: "row",
                  justifyContent: "center",
                  gap: 4,
                  alignItems: "center",
                }}
              >
                {addonData.gallery.map((item, index) => {
                  return (
                    <Typography
                      component={"div"}
                      sx={{
                        background: "transparent",
                        height: 300,
                        width: 400,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      onClick={() => {
                        setIsPreviewImage(true);
                        setStartIndex(index);
                      }}
                      key={index}
                    >
                      <Typography
                        sx={{
                          height: 230,
                        }}
                      >
                        <img
                          src={item.url}
                          className={styles.demoimages}
                          alt="images"
                        />
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "center",
                          p: 1,
                          height: 70,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Typography>
                  );
                })}
              </Grid>
            </Typography>
            <Typography
              component="div"
              sx={{
                pt: 3,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                component="div"
                sx={{
                  pt: 3,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  component={"div"}
                  variant="h4"
                  sx={{
                    font: "normal normal 600 20px/24px 'Graphie, SemiBold'",
                    letterSpacing: "0px",
                    color: "#3E3E3E",
                    mb: "10px",
                  }}
                >
                  Description
                </Typography>
                <Typography
                  id="desc"
                  variant="p"
                  sx={{
                    whiteSpace: "pre-line",
                    // lineHeight: 2,
                    font: "normal normal normal 14px/18px 'Segoe UI'",
                    letterSpacing: "0px",
                    color: "#3E3E3E",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: replaceLink(addonData.longDescription),
                  }}
                />
              </Typography>
            </Typography>
          </Typography>
          {!!images.length && (
            <DialogModal
              open={isPreviewImage}
              onClose={() => {
                setIsPreviewImage(false);
              }}
            >
              <Carousel
                navButtonsAlwaysVisible={true}
                index={startIndex}
                autoPlay={false}
                sx={{
                  width: "60vw",
                }}
              >
                {images.map((item, i) => (
                  <Item key={i} item={item} />
                ))}
              </Carousel>
            </DialogModal>
          )}
          <DialogModal
            open={!!successData}
            onClose={() => setSuccessData(null)}
          >
            <Typography
              component={"div"}
              sx={{
                p: 3,
                maxwidth: "800px",
                backgroundColor: "white",
                border: "1px solid",
                borderRadius: "5px",
              }}
            >
              {!!successData &&
                Object.keys(successData).map((key) => {
                  return (
                    <Typography
                      component={"div"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        mb: "20px",
                        gap: 0.5,
                      }}
                    >
                      <InputLabel
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: 600,
                          color: theme.palette.primary.dark,
                        }}
                      >
                        {key}
                      </InputLabel>

                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "1px solid",
                          borderRadius: "5px",
                          padding: "4px",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {successData[key].length > 35
                          ? `${successData[key].slice(
                            0,
                            10
                          )}***************${successData[key].slice(
                            successData[key].length - 10,
                            successData[key].length
                          )}`
                          : successData[key]}
                        <Button
                          sx={{ color: theme.palette.primary.main }}
                          size="small"
                          variant="text"
                          endIcon={<ContentCopyIcon fontSize="" />}
                          onClick={() => {
                            copyContent(successData[key]);
                          }}
                        />
                      </Typography>
                    </Typography>
                  );
                })}
              <Typography
                component={"div"}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: 600,
                    textTransform: "capitalize",
                  }}
                  onClick={() => setSuccessData(null)}
                >
                  Close
                </Button>
              </Typography>
            </Typography>
          </DialogModal>
        </>
      ) : (
        <>
          <Typography
            component="div"
            sx={{
              pl: "20px",
              pr: "20px",
              pt: 3,
              pb: 3,
              boxShadow: "inset 0px -11px 30px -17px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: "15px",
                background: theme.palette.primary.contrastText,
                textTransform: "none",
                outline: "2px red",
                mb: 2,
              }}
              size="small"
              onClick={handleBack}
            >
              {"< Back"}
            </Button>
          </Typography>
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
              flexDirection: "column",
            }}
          >
            Details not found, Try again after sometime.
          </Typography>
        </>
      )}
    </>
  );
};

function Item(props) {
  return (
    <div style={{ height: "500px" }}>
      <img
        src={props.item}
        style={{ width: "100%", height: "100%", objectFit: "fill" }}
        alt="item_img"
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    bots: state.bots,
  };
};

export default connect(mapStateToProps)(AddonDetails);
