import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import bannerImage from "../Images/banner_image.png";
import { DialogModal } from "../../components/Modal/DialogModal";
import { createAPIKey, deleteAPIKeys } from "../../routines";
import { Tables } from "../../components/Table/Table";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { copyContent } from "../../utils/helper";
import { characterLength } from "../../utils/constant";
import { toast } from "react-toastify";
import DashboardBanner from "../../components/Banner/DashboardBanner";
import moment from "moment";

const GenerateApiKeys = ({ apiKeys }) => {
  const dispatch = useDispatch();
  const [opneCreateDialog, setOpneCreateDialog] = useState(false);
  const [apiName, setApiName] = useState("");

  const handleChangeName = (event) => {
    const str = event.target.value;
    if (str.length <= characterLength.ApiNameLimit) {
      setApiName(str);
    } else {
      return toast.error(
        `api name should not exceed ${characterLength.ApiNameLimit} characters`
      );
    }
  };

  const handleOpenCreateDialog = () => {
    setOpneCreateDialog(!opneCreateDialog);
    setApiName("");
  };
  const [rows, setRows] = useState([]);
  const handleSubmit = () => {
    dispatch(createAPIKey.trigger({ apiKey: apiName }));
    handleOpenCreateDialog();
  };

  const columns = [
    { field: "name", headerName: "Name" },
    {
      field: "apiKey",
      headerName: "API Key",
    },
    {
      field: "createdAt",
      headerName: "Created At",
    },
  ];

  useEffect(() => {
    const modifiedRows = apiKeys.map((keys, index) => {
      return {
        ...keys,
        apiKey: (
          <Typography
            sx={{ display: "flex", justifyContent: "space-between", gap: 5 }}
          >
            {`${keys.apiKey.slice(0, 3)}***************${keys.apiKey.slice(
              keys.apiKey.length - 3,
              keys.apiKey.length
            )}`}{" "}
            <Typography
              sx={{
                display: "flex",
                gap: 5,
              }}
            >
              <ContentCopyIcon
                sx={{
                  color: "#089BAE80",
                  "&:hover": {
                    cursor: "pointer",
                    color: "#089BAE",
                  },
                }}
                onClick={() => {
                  copyContent(keys.apiKey);
                }}
              />
              <DeleteForeverIcon
                sx={{
                  color: "#089BAE80",
                  "&:hover": {
                    cursor: "pointer",
                    color: "#089BAE",
                  },
                }}
                onClick={() => {
                  dispatch(deleteAPIKeys.trigger(keys.name));
                }}
              />
            </Typography>
          </Typography>
        ),
        createdAt: moment(keys.createdAt).format("DD-MM-YYYY"),
      };
    });
    setRows(modifiedRows);
  }, [apiKeys]);
  return (
    <>
      <DashboardBanner image={bannerImage} imageWidth={40} imageHegiht={160}>
        <Typography
          sx={{
            height: 160,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Typography
            component={"div"}
            sx={{
              font: "normal normal normal 20px/24px 'Graphie, Regular'",
              letterSpacing: "0px",
              color: "#3E3E3E",
            }}
          >
            Create New API Key
          </Typography>
          <Typography
            varient="p"
            component={"div"}
            sx={{
              font: "normal normal normal 14px/19px 'Segoe UI'",
              letterSpacing: "0px",
              color: "#3E3E3E",
            }}
          >
            You can create, copy & paste or renew API keys. Save it somewhere
            secure, or use it to connect your application right away.
          </Typography>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "fit-content",
              pl: 4,
              pr: 4,
              borderRadius: 1,
              background: "#089BAE",
              "&:hover": {
                background: "#148695",
              },
            }}
            onClick={handleOpenCreateDialog}
          >
            Create API Key
          </Button>
        </Typography>
      </DashboardBanner>
      <DialogModal
        open={opneCreateDialog}
        onClose={handleOpenCreateDialog}
        visibleBackdrop
      >
        <Typography
          sx={{
            height: 200,
            width: 560,
            background: "#fff",
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            p: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body"
            component={"div"}
            sx={{
              width: "100%",
              textAlign: "center",
              color: "#1D71D4",
              fontWeight: 700,
            }}
          >
            Create New API Key
          </Typography>
          <Typography
            component={"div"}
            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: 0.5,
            }}
          >
            <Typography variant="body" sx={{ fontWeight: 600, mb: 1 }}>
              API name
            </Typography>

            <TextField
              placeholder="Please enter API name"
              fullWidth
              value={apiName}
              sx={{
                "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#089BAE",
                    ///background:'#ffff'
                  },
                "& .MuiInputBase-root": {
                  height: 35,
                  p: 0,
                },
              }}
              onChange={(e) => {
                // setApiName(e.target.value);
                handleChangeName(e);
              }}
              required
            />
          </Typography>

          <Typography
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                height: "fit-content",
                pl: 5,
                pr: 5,
                borderRadius: 1,
                color: "black",
                background: "#3E3E3E4D",
                "&:hover": {
                  background: "#C8C8C8",
                },
              }}
              size="small"
              onClick={handleOpenCreateDialog}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                width: "fit-content",
                pl: 5,
                pr: 5,
                borderRadius: 1,
                background: "#089BAE",
                "&:hover": {
                  background: "#148695",
                },
              }}
              size="small"
              onClick={handleSubmit}
              disabled={apiName.trim().length === 0}
            >
              Submit
            </Button>
          </Typography>
        </Typography>
      </DialogModal>
      <Typography component={"div"} sx={{ mt: "2rem", height: "fit-content" }}>
        {rows.length > 0 ? (
          <Tables rows={rows} columns={columns} />
        ) : (
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 30,
              mt: 20,
            }}
          >
            No keys are available, Please create new one!
          </Typography>
        )}
      </Typography>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    apiKeys: state.apiKeys,
  };
};

export default connect(mapStateToProps)(GenerateApiKeys);
