import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./TestFlow.module.scss";
import { io } from "socket.io-client";
import { NO_TRANSITION_EVENT_NAME } from "../../utils/jsonUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AttachmentIcon from "@mui/icons-material/Attachment";
import {
  faWindowMinimize,
  faPaperPlane,
  faXmark,
  faWindowMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { theme } from "../../utils/theme";
import DocViewer from "@cyntler/react-doc-viewer";
import * as docx from "docx-preview";
import { docxType } from "../../utils/constant";

let socket;

export const socketEvents = {
  BOT_REPLY: "bot_reply",
  USER_REPLY: "user_reply",
};

const RenderPreview = (props) => {
  const { file } = props;
  const uri = URL.createObjectURL(file);

  return (
    <div className={styles.previewContainer}>
      {uri && (
        <DocViewer
          documents={[{ uri, fileName: file.name }]}
          config={{
            noRenderer: {
              overrideComponent: ({ document, fileName }) => {
                const fileText = fileName || document?.fileType || "";
                console.log(document);
                if (fileText) {
                  return <div>no renderer for {fileText}</div>;
                }
                return <div>no renderer</div>;
              },
            },
            loadingRenderer: {
              overrideComponent: ({ document, fileName }) => {
                const fileText = fileName || document?.fileType || "";
                if (fileText) {
                  return <div>loading ({fileText})</div>;
                }
                return <div>loading</div>;
              },
            },
            csvDelimiter: ",",
            pdfZoom: {
              defaultZoom: 1.1,
              zoomJump: 0.2,
            },
            pdfVerticalScrollByDefault: true,
          }}
          language="pl"
        />
      )}
    </div>
  );
};

export const RunBot = (props) => {
  const [messages, setMessages] = useState([props.botFirstMessage]);
  const [userMessage, setUserMessage] = useState("");
  const chatBottomRef = useRef(null);
  const [eventSelected, setEventSelected] = useState(false);
  const [hideChat, setHideChat] = useState(false);
  const [isAttachment, setIsAttachment] = useState(false);
  const [file, setFile] = useState(null);
  const [imgsrc, setImageSrc] = useState(null);
  const [botId, setBotId] = useState(props.botFirstMessage.botId);
  const [isLargeFile, setIsLargeFile] = useState(false);
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const docxPreviewContainerRef = useRef(null);

  const handleCancleAttachemnt = () => {
    setIsAttachment(!isAttachment);
    setFile(null);
    setImageSrc(null);
  };

  useEffect(() => {
    socket = io(process.env.REACT_APP_BACKEND_URL);
    socket.on("connect", () => {
      console.log(socket.id);
    });

    socket.on("disconnect", () => {
      console.log(`Disconnected from server`);
    });

    socket.on(socketEvents.BOT_REPLY, (data) => {
      setMessages((prevMessages) => {
        return [
          ...prevMessages,
          {
            message: data.message,
            events:
              data.events.length === 1 &&
              data.events[0] === NO_TRANSITION_EVENT_NAME
                ? []
                : data.events,
            type: socketEvents.BOT_REPLY,
            isAttachment: data.isAttachment,
            botId: data.botId,
          },
        ];
      });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const userMessageInputHandler = (event) => {
    if (event.target.key !== "Enter") {
      setUserMessage(event.target.value);
    } else {
      setUserMessage(event.target.value.trim());
    }
  };

  const sendMessageHandler = useCallback(() => {
    if (userMessage.trim() === "") {
      return;
    }
    socket.emit(socketEvents.USER_REPLY, {
      msg: userMessage.trim(),
      botId: messages[messages.length ? messages.length - 1 : 0].botId,
      smdId: props.botFirstMessage.smdId,
    });
    setMessages((prevMessages) => {
      return [
        ...prevMessages,
        {
          message: userMessage.trim(),
          type: socketEvents.USER_REPLY,
          events: [],
          isAttachment: false,
          botId: prevMessages[0].botId,
        },
      ];
    });
    setUserMessage("");
    inputRef.current.focus();
  }, [messages, userMessage, inputRef]);

  const handleFileSelect = async (e) => {
    if (!e.target.files.length) return;
    setImageSrc(null);
    const fileZise = e.target.files[0].size / 1024 <= 1020 ? false : true; //converting the size in to kb by deviding 1024
    if (fileZise) {
      setIsLargeFile(fileZise);
      return;
    } else {
      setIsLargeFile(fileZise);
    }
    setFile(e.target.files[0]);
    if (fileInputRef.current) {
      fileInputRef.current.blur();
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(e.target.files[0]);
    fileReader.onload = () => {
      if (e.target.files[0].type === docxType) {
        docx.renderAsync(
          e.target.files[0],
          docxPreviewContainerRef.current,
          null,
          {
            ignoreWidth: true,
            ignoreHeight: true,
          }
        );
      }
      setImageSrc(fileReader.result);
    };
  };

  const sendSeectedFile = () => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = () => {
      setMessages((prevMessages) => {
        return [
          ...prevMessages,
          {
            message: imgsrc,
            type: socketEvents.USER_REPLY,
            events: [],
            isAttachment: true,
            name: file.name,
            type: file.type,
            botId: prevMessages[0].botId,
          },
        ];
      });
      socket.emit(socketEvents.USER_REPLY, {
        msg: fileReader.result,
        name: file.name,
        botId: messages[messages.length ? messages.length - 1 : 0].botId,
        type: file.type,
        isAttachment: true,
        smdId: props.botFirstMessage.smdId,
      });

      handleCancleAttachemnt();
    };
  };
  useEffect(() => {
    chatBottomRef.current.addEventListener("DOMNodeInserted", (event) => {
      const { currentTarget: target } = event;
      target.scroll({
        top: target.scrollHeight,
        inline: "start",
        block: "nearest",
        behavior: "smooth",
      });
    });
  }, []);

  const optionClickHandler = (option) => {
    setUserMessage(option);
    setEventSelected(true);
  };

  useEffect(() => {
    if (eventSelected === true) {
      sendMessageHandler();
      setEventSelected(false);
    }
  }, [eventSelected, sendMessageHandler]);

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     if (userMessage.trim() !== "") {
  //       console.log("messageg");
  //       sendMessageHandler();
  //     }
  //     if (!!file) {
  //       console.log("file");
  //       sendSeectedFile();
  //     }
  //   }
  // };

  const toggleChatDisplay = () => {
    if (!props.plugin) {
      return;
    }
    setHideChat(!hideChat);
  };

  const getMainContainerClasses = () => {
    const classes = [styles.testFlow];
    if (!props.isTest) {
      classes.push(styles.publishedBot);
    }
    if (props.plugin) {
      classes.push(styles.pluginBot);
      if (hideChat) {
        classes.push(styles.headerOnly);
      }
    }

    return classes.join(" ");
  };

  useEffect(() => {
    let chatConainer = document.getElementById("chatContainer");
    chatConainer.scrollTop = chatConainer.scrollHeight;
  }, [messages]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        if (isAttachment && !!file) {
          sendSeectedFile();
        } else if (userMessage.trim() !== "") {
          sendMessageHandler();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isAttachment, file, userMessage, sendMessageHandler, sendSeectedFile]);

  return (
    <div className={getMainContainerClasses()}>
      <header className={styles.header} onClick={toggleChatDisplay}>
        <div className={styles.headerAvatar}>
          <img
            src={props.avatar}
            alt=""
            width={30}
            height={30}
            className={styles.headerMessageIcon}
          />
          <div className={styles.botName}>{props.runBotTitle}</div>
        </div>
        {props.isTest && (
          <FontAwesomeIcon
            icon={faXmark}
            size="lg"
            className={styles.closeTestFlow}
            onClick={() => {
              props.showTestFlow.bind(this, false);
              props.handleCloseTestFlow(botId);
            }}
          />
        )}
        {props.plugin && (
          <FontAwesomeIcon
            icon={hideChat ? faWindowMaximize : faWindowMinimize}
          />
        )}
      </header>
      {!isAttachment && (
        <div
          id={"chatContainer"}
          className={`${styles.chatCtr} ${hideChat ? styles.hideChat : ""}`}
          ref={chatBottomRef}
        >
          {messages.map((message, messageIndex) => {
            if (message.type === socketEvents.BOT_REPLY) {
              return (
                <div key={messageIndex} className={styles.genericMsg}>
                  <img
                    src={props.avatar}
                    alt=""
                    width={30}
                    height={30}
                    className={styles.messageIcon}
                  />
                  <div className={styles.botFirstMessageIcon}>
                    <p className={`${styles.botChatMsg}`}>{message.message}</p>
                    <div className={styles.eventContainer}>
                      {message.events && (
                        <div className={styles.chatMsgEvents}>
                          {message.events.map((option, optionIndex) => {
                            return (
                              <button
                                key={optionIndex}
                                onClick={optionClickHandler.bind(this, option)}
                                disabled={messageIndex !== messages.length - 1}
                              >
                                {option}
                              </button>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              if (message.isAttachment) {
                return (
                  <div key={messageIndex} className={styles.userMsg}>
                    <div className={styles.userChatAttachment}>
                      {message.type.toLowerCase() === "image/jpeg" ||
                      message.type.toLowerCase() === "image/png" ? (
                        <img src={message.message} />
                      ) : (
                        <p className={` ${styles.userChatMsg}`}>
                          {" "}
                          <UploadFileIcon /> <span>{message.name}</span>
                        </p>
                      )}
                    </div>
                    <img
                      src="/user-img.png"
                      alt=""
                      width={30}
                      height={30}
                      className={styles.messageIcon}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={messageIndex} className={styles.userMsg}>
                    <p className={` ${styles.userChatMsg}`}>
                      {message.message}
                    </p>
                    <img
                      src="/user-img.png"
                      alt=""
                      width={30}
                      height={30}
                      className={styles.messageIcon}
                    />
                  </div>
                );
              }
            }
          })}
          <div />
        </div>
      )}
      {isAttachment && (
        <div className={styles.inputFile}>
          <CloseIcon
            className={styles.cancel}
            variant="text"
            onClick={handleCancleAttachemnt}
            sx={{ color: theme.palette.primary.main }}
          />
          <div
            style={{
              height: "70%",
              width: "75%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!isLargeFile && !file && (
              <div
                className={styles.attachmentMsg}
                style={{ textAlign: "center" }}
              >
                The selected file size should be less than 1 MB
              </div>
            )}
            {isLargeFile && (
              <div
                className={styles.attachmentMsg}
                style={{ textAlign: "center" }}
              >
                The selected file size is greater than 1 MB
              </div>
            )}
            {!!file &&
              (file.type !== docxType ? (
                <RenderPreview file={file} />
              ) : (
                <div
                  className={
                    props.isTest
                      ? styles.docxTestPreviewContainer
                      : styles.docxPreviewContainer
                  }
                  ref={docxPreviewContainerRef}
                ></div>
              ))}
          </div>
        </div>
      )}
      <div className={styles.line}></div>
      <div className={`${styles.footers} ${hideChat ? styles.hideChat : ""}`}>
        {!isAttachment && (
          <>
            {userMessage.length === 0 &&
              messages[messages.length - 1].events.length === 0 && (
                <div className={styles.attachmentIcon}>
                  <AttachmentIcon
                    onClick={handleCancleAttachemnt}
                    fontSize="medium"
                  />
                </div>
              )}
            <input
              ref={inputRef}
              id="inputtext"
              className={styles.inputs}
              placeholder="Type a message"
              value={userMessage}
              // onKeyDown={handleKeyDown}
              onChange={userMessageInputHandler}
              disabled={
                messages.length !== 0 &&
                messages[messages.length - 1].events.length !== 0
              }
            />
            <div className={styles.sendButton}>
              <FontAwesomeIcon
                icon={faPaperPlane}
                size="lg"
                onClick={sendMessageHandler}
              />{" "}
            </div>
          </>
        )}
        {isAttachment && (
          <div className={styles.attachmentButton}>
            <input
              ref={fileInputRef}
              className={styles.inputSelector}
              type="file"
              onChange={handleFileSelect}
              // onKeyDown={handleKeyDown}
            />
            <Button
              variant="contained"
              sx={{
                // background: theme.palette.primary.lightGreen,
                // ":hover": {
                //   background: theme.palette.secondary.lightGreen,
                // },
                textTransform: "capitalize",
                font: "normal normal 600 14px/19px 'Segoe UI'",
                color: "#FFFFFF",
                background: "#089BAE",
                borderRadius: "5px",
                px: "30px",
                py: "6px",
                "&:hover": {
                  background: "#148695",
                },
              }}
              size="small"
              disabled={!file}
              onClick={sendSeectedFile}
            >
              Send
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export const TestFlow = (props) => {
  return (
    <RunBot
      avatar={props.avatar}
      showTestFlow={props.showTestFlow}
      botFirstMessage={props.botFirstMessage}
      runBotTitle={props.runBotTitle}
      isTest={props.isTest}
      handleCloseTestFlow={props.handleCloseTestFlow}
    />
  );
};
