import { handleActions } from "redux-actions";
import { getBotTemplates } from "../routines";

const defaultState = [];

export const botTemplatesReducer = handleActions(
  {
    [getBotTemplates.SUCCESS]: (state, { payload }) => {
      return !payload ? defaultState : [...payload];
    },
  },
  defaultState
);
