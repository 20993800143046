// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/SEGOEUI.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Graphie-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Graphie-SemiBold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Segoe UI\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Graphie, Regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\");\n}\n@font-face {\n  font-family: \"Graphie, SemiBold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"opentype\");\n}", "",{"version":3,"sources":["webpack://./src/fonts.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,+DAAA;AACF;AAEA;EACE,+BAAA;EACA,+DAAA;AAAF;AAGA;EACE,gCAAA;EACA,+DAAA;AADF","sourcesContent":["@font-face {\n  font-family: 'Segoe UI';\n  src: url('./fonts/SEGOEUI.TTF') format('truetype');\n}\n\n@font-face {\n  font-family: 'Graphie, Regular';\n  src: url('./fonts/Graphie-Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Graphie, SemiBold';\n  src: url('./fonts/Graphie-SemiBold.otf') format('opentype');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
