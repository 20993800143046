// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackToTopButton_backToTopBtn__J4oIf {\n  position: \"fixed\";\n  bottom: \"50px\";\n  right: \"50px\";\n  height: \"50px\";\n  width: \"50px\";\n  font-Size: \"50px\";\n}\n\n.BackToTopButton_btnContainer__caOeh {\n  position: absolute;\n  right: 6%;\n  top: 12%;\n}", "",{"version":3,"sources":["webpack://./src/components/BackToTop/BackToTopButton.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;EACA,aAAA;EACA,cAAA;EACA,aAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;AACF","sourcesContent":[".backToTopBtn {\n  position: \"fixed\";\n  bottom: \"50px\";\n  right: \"50px\";\n  height: \"50px\";\n  width: \"50px\";\n  font-Size: \"50px\"\n}\n\n.btnContainer {\n  position: absolute;\n  right: 6%;\n  top: 12%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backToTopBtn": "BackToTopButton_backToTopBtn__J4oIf",
	"btnContainer": "BackToTopButton_btnContainer__caOeh"
};
export default ___CSS_LOADER_EXPORT___;
