import React, { useState } from "react";
import "./styles.scss";
import {
  Switch,
  styled,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  ListItemIcon,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch } from "react-redux";
import { deleteManager, updateManager } from "../../routines";
import { DeleteDialogBox } from "../DeleteDialogBox/DeleteDialogBox";
import EditManager from "../EditManager/EditManager";

const ManagerTableBodyRow = ({ manager }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleEditModalOpen();
  };

  const handleDeleteManager = () => {
    const payload = {
      id: manager._id,
    };
    dispatch(deleteManager.trigger(payload));
    handleDeleteDialogClose();
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteDialogOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const handleEditModalOpen = () => {
    setOpenEditModal(true);
  };

  const handleActivation = () => {
    const payload = {
      id: manager._id,
      access: manager.access,
      available_smds: manager.available_smds,
      isActive: !manager?.isActive,
    };
    dispatch(updateManager.trigger(payload));
  };

  const options = [
    {
      name: "Edit",
      icon: <EditIcon fontSize="small" />,
      onClick: handleEdit,
    },
    {
      name: "Delete",
      icon: <DeleteForeverIcon fontSize="small" />,
      onClick: handleDeleteDialogOpen,
    },
  ];

  const AntSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#089bae",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#089bae",
    },
  }));

  return (
    <div component="div" className="tableBodyRow">
      <div component="div" className="email">
        {manager.email}
      </div>
      <div component="div" className="firstName">
        {manager.firstName}
      </div>
      <div component="div" className="lastName">
        {manager.lastName}
      </div>
      {/* <div component="div" className={styles.roles}>
        <TextField
          select
          size="small"
          sx={{
            width: "80%",
            color: "#ECECEC",
            paddingX: 0
          }}
        />
      </div> */}
      <div component="div" className="status">
        <AntSwitch
          onChange={handleActivation}
          checked={manager?.isActive}
          inputProps={{ "aria-label": "ant design" }}
        />
        {manager?.isActive ? (
          <p className="statusText active">Active</p>
        ) : (
          <p className="statusText inActive">Inactive</p>
        )}
      </div>
      <div component="div" className="settings">
        <IconButton
          aria-label="settings"
          onClick={handleClick}
          sx={{ padding: "0px", width: 0 }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseAnchor}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          sx={{
            mr: -1.5,
            ml: -1,
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.name}
              onClick={() => {
                handleCloseAnchor();
                option.onClick();
              }}
              sx={{ pt: 0.3, pb: 0.3 }}
              dense={false}
            >
              <ListItemIcon>{option.icon} </ListItemIcon>
              <Typography>{option.name}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </div>
      <DeleteDialogBox
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        onDelete={handleDeleteManager}
        message={"Are you sure you want to delete this manager?"}
      />
      <EditManager
        open={openEditModal}
        onClose={handleEditModalClose}
        manager={manager}
      />
    </div>
  );
};

export default ManagerTableBodyRow;
