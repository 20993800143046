// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dialog_dialog_header__qHVJk {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-top-right-radius: 5px;\n  border-top-left-radius: 5px;\n  padding: 8px;\n}", "",{"version":3,"sources":["webpack://./src/components/Modal/Dialog.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,4BAAA;EACA,2BAAA;EACA,YAAA;AACJ","sourcesContent":[".dialog_header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-top-right-radius: 5px;\n    border-top-left-radius: 5px;\n    padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog_header": "Dialog_dialog_header__qHVJk"
};
export default ___CSS_LOADER_EXPORT___;
