import { handleActions } from "redux-actions";
import { getAddons } from "../routines";

const defaultState = null;

export const addonsReducer = handleActions(
  {
    [getAddons.SUCCESS]: (state, { payload }) => {
      return !payload ? defaultState : { ...state, ...payload };
    },
  },
  defaultState
);
