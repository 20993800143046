import { Button, CircularProgress, Typography } from "@mui/material";
import DashboardBanner from "../../components/Banner/DashboardBanner";
import bannerImage from "./../../pages/Images/banner_image.png";
import AddManager from "../../components/AddManager";
import { useState } from "react";
import ManagerTable from "../../components/ManagerTable/ManagerTable";
import { connect } from "react-redux";

const Managers = ({ managers }) => {
  const [isOpenAddManagerModal, setIsOpenAddManagerModal] = useState(false);

  const addManager = () => {
    setIsOpenAddManagerModal(true);
  };
  const onCloseAddManagerModal = () => {
    setIsOpenAddManagerModal(false);
  };

  return (
    <Typography component={"div"}>
      <DashboardBanner image={bannerImage} imageWidth={40} imageHegiht={160}>
        <Typography
          sx={{
            height: 160,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Typography
            component={"div"}
            sx={{
              font: "normal normal normal 20px/24px 'Graphie, Regular'",
              letterSpacing: "0px",
              color: "#3E3E3E",
            }}
          >
            Managers
          </Typography>
          <Typography
            varient="p"
            component={"div"}
            sx={{
              font: "normal normal normal 14px/19px 'Segoe UI'",
              letterSpacing: "0px",
              color: "#3E3E3E",
            }}
          >
            This is the place where you can manage your team
          </Typography>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "fit-content",
              pl: 4,
              pr: 4,
              borderRadius: 1,
              background: "#089BAE",
              "&:hover": {
                background: "#148695",
              },
            }}
            onClick={addManager}
          >
            Add manager
          </Button>
        </Typography>
      </DashboardBanner>
      <AddManager
        open={isOpenAddManagerModal}
        onClose={onCloseAddManagerModal}
      />
      {!managers ? (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "8rem",
          }}
        >
          <CircularProgress className="loader" />
        </Typography>
      ) : managers?.length > 0 ? (
        <ManagerTable />
      ) : (
        <Typography
          component={"div"}
          sx={{
            mt: "5rem",
            font: "normal normal 600 16px/19px 'Graphie, SemiBold'",
            color: "#3E3E3E",
            letterSpacing: "0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          No managers available
        </Typography>
      )}
    </Typography>
  );
};

const mapStateToProps = (state) => {
  return {
    managers: state.managers,
  };
};
export default connect(mapStateToProps)(Managers);
