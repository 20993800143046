// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddonDetails_demoimages__lvaI5 {\n  height: 100%;\n  width: 100%;\n  border-radius: 5px;\n}\n.AddonDetails_demoimages__lvaI5:hover {\n  cursor: pointer;\n}\n\n.AddonDetails_apiList__0cloc::-webkit-scrollbar {\n  background-color: transparent;\n  width: 5px;\n  max-height: 5px;\n}\n\n.AddonDetails_apiList__0cloc::-webkit-scrollbar-thumb {\n  background: rgba(62, 62, 62, 0.5019607843);\n  border-radius: 10px;\n}", "",{"version":3,"sources":["webpack://./src/pages/AddonDetails/AddonDetails.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;AACJ;;AAIA;EACE,6BAAA;EACA,UAAA;EACA,eAAA;AADF;;AAIA;EACE,0CAAA;EACA,mBAAA;AADF","sourcesContent":[".demoimages {\n  height: 100%;\n  width: 100%;\n  border-radius: 5px;\n\n  &:hover {\n    cursor: pointer;\n  }\n\n}\n\n.apiList::-webkit-scrollbar {\n  background-color: transparent;\n  width: 5px;\n  max-height: 5px;\n}\n\n.apiList::-webkit-scrollbar-thumb {\n  background: #3E3E3E80;\n  border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"demoimages": "AddonDetails_demoimages__lvaI5",
	"apiList": "AddonDetails_apiList__0cloc"
};
export default ___CSS_LOADER_EXPORT___;
