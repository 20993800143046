import { handleActions } from "redux-actions";
import { getManagers } from "../routines";

const defaultState = null;

export const managersReducer = handleActions(
  {
    [getManagers.SUCCESS]: (state, { payload }) => {
      return !payload ? defaultState : [...payload];
    },
  },
  defaultState
);
