import React from "react";
import { Button, Divider } from "@mui/material";
import { Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { theme } from "../../utils/theme";
import { deleteApi } from "../../api";
import { toast } from "react-toastify";
import styles from "./ActivatedApis.module.scss";

export const ActivatedApis = ({ activatedApis, onClose, getApis }) => {
  const handleDeleteApi = async (_id) => {
    try {
      const response = await deleteApi(_id);
      toast.success(response.message);
      getApis();
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      {activatedApis.length !== 0 && (
        <>
          <Typography
            component={"div"}
            variant="h4"
            sx={{
              font: "normal normal 600 20px/24px 'Graphie, SemiBold'",
              letterSpacing: "0px",
              color: "#3E3E3E",
              mb: "10px",
            }}
          >
            Activated Apis
          </Typography>
          <Typography
            className={styles.apiList}
            component={"div"}
            sx={{
              display: "flex",
              //alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "column",

              background: "white",
              maxHeight: 300,
              overflowX: "hidden",
              borderRadius: 2,
              p: 5,
              gap: 1,
              mb: 2,
            }}
          >
            {activatedApis.map((api, index) => (
              <>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      font: "normal normal 600 20px/24px 'Graphie, SemiBold'",
                      letterSpacing: "0px",
                      color: "#089BAE",
                      mb: "10px",
                    }}
                  >
                    {api.apiName}
                  </Typography>
                  <Typography sx={{ display: "flex", gap: 3.5 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      endIcon={<EditIcon sx={{ height: 15 }} />}
                      sx={{
                        borderRadius: "5px",
                        textTransform: "capitalize",
                        height: 30,
                        fontWeight: 600,
                        px: "28px",
                      }}
                      onClick={() => {
                        onClose(index);
                      }}
                    >
                      Edit
                    </Button>

                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "5px",
                        textTransform: "capitalize",
                        backgroundColor: "#CC0000",
                        outline: "2px",
                        color: "white",
                        height: 30,
                        fontWeight: 600,
                        px: "28px",
                        ":hover": {
                          backgroundColor: theme.palette.secondary.red,
                        },
                      }}
                      size="small"
                      onClick={() => {
                        handleDeleteApi(api?._id);
                      }}
                    >
                      Remove
                    </Button>
                  </Typography>
                </Typography>
                <Divider sx={{ background: theme.palette.primary.dark }} />
              </>
            ))}
          </Typography>
        </>
      )}
    </>
  );
};
