import React from "react";
import Button from "@material-ui/core/Button";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)((props) => ({
  backgroundColor: props.backgroundColor
    ? `${props.backgroundColor}`
    : "#089BAE",
  textAlign: "center",
  font: "normal normal normal 16px/19px 'Graphie, Regular'",
  textTransform: "none",
  color: props.color ? `${props.color}` : "#FFFFFF",
  // width: "100%",
  width: props.width ? "none" : "100%",
  minWidth: "118px",
  padding: props.padding ? `${props.padding}px` : "5.5px",

  "&:hover": {
    backgroundColor: props.onHover ? `${props.onHover}` : "#0a879a",
  },
}));

const CommonButton = (props) => {
  console.log("width===========", props.width);

  return (
    <StyledButton
      onHover={props.onHover}
      color={props.color}
      backgroundColor={props.backgroundColor}
      padding={props.padding}
      width={props.width}
      type="submit"
      onClick={props.onClick}
    >
      {props.title}
    </StyledButton>
  );
};

export default CommonButton;
